import { StoreClass } from '../../store/Store';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useClippy from 'use-clippy';
import { t } from '../../i18n';
import { globalStore } from '../../store/global_store';
import { removeSuccessStatus } from "../../services/ContactsService";
import { SendMeetingInvite } from '../../services/RoomsService';

interface formError {
    message: string;
}

function InviteToMeeting(props: any) {
    const [clipboard, setClipboard] = useClippy();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({} as formError);
    const [email, setEmail] = useState('');
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

    const copyToClipboard = () => {
        setClipboard(props.meetingLink);
    }

    useEffect(() => {
        
        if (user.success && user.success === 'SentMeetingLink') {
            globalStore.showToast({
                type: 'Message',
                title: "Invitation",
                message: "Invitation sent successfully"
            });
            dispatch(removeSuccessStatus());
            setEmail('');
        }
    }, [user.success]);

    const sendInvitation = () => {
        
        if (email.length > 0) {
            if (validateEmail(email)) {
                console.log('selected email', email);
                let form = new FormData();
                form.append('toEmail', email);
                form.append("meetingLink", props.meetingLink);
                dispatch(SendMeetingInvite(form));
            }
            else {
                setErrors({ message: 'Enter valid email to send invitation' });
            }
        }
        else {
            setErrors({ message: 'Enter email to send invitation' });
        }
    }

    const validateEmail = (email: string) => {
        var validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return validEmail.test(email);
    }

    const errormsg = (<div className="col-12"><div className="alert alert-danger text-center">
        <strong>Error!</strong> {errors.message}
    </div></div>);

    return (
        <>
            <div className="row mt-4 small">
                <div className="col-12 mb-2 text-light">
                    <svg onClick={props.closeInvite} enableBackground="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                        <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" /></svg>{t('invitetomeeting.invite_others')}
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-3">
                    <p className="text-light m-0">{t('invitetomeeting.share_meeting_link')}</p>
                    <p className="fs12 text-muted">{t('invitetomeeting.copy_link')}.</p>
                </div>
            </div>
            <div className="row mt-3 small">
                <div className="col-12 mb-2 position-relative">
                    <input type="text" className="SuteInputPrimary fs12" readOnly value={props.meetingLink} />
                    <span onClick={copyToClipboard} className="inviteBtn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" fill="#30D5C8" /></svg></span>
                </div>
                <div className="col-12 mb-2">
                    <input value={email} onChange={(e) => { setEmail(e.target.value); setErrors({ message: '' }) }} type="text" className="SuteInputPrimary fs12" placeholder={t('myprofile.email')} />
                </div>
                <div className="col-12 mb-2 mt-1">
                    <button onClick={sendInvitation} className="btn-SeaGreen btn-block btn-sm btn fw600 fs12"><img className="inviteBtnShare" src="/assets/media/svg/share-alt.svg" /> {t('button.send_invite')}</button>
                </div>
                {errors.message && errormsg}
            </div>
        </>
    );
}

export default InviteToMeeting;