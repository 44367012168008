import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getEmpDetailsList, DeleteEmployeeByEmployeeGuid, ShowToast } from "../../services/UserActions";
import { removeSuccessStatus } from "../../services/ContactsService";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { PatternFormat } from "react-number-format";

var deleteEmpGuid: string;
var deleteText: string = '';
const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer,
    employee: state.EmployeeReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    getEmpDetailsList: (userTypeId: any) => {
        dispatch(getEmpDetailsList(userTypeId))
    },
    DeleteEmployeeByEmployeeGuid: (empGuid: any) => {
        dispatch(DeleteEmployeeByEmployeeGuid(empGuid))
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    },
    ShowToast: (toast: any) => {
        dispatch(ShowToast(toast));
    },
})

function Users(props: any) {
    const [empList, setempList] = useState('');
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const [apiStatus, setApiStatus] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const history = useNavigate();
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const formattedDate = process.env.REACT_APP_DATE_FORMAT

    useEffect(() => {
        props.getEmpDetailsList(empGuid);
    }, [])

    useEffect(() => {
        if (props.user.empList) {
            props.user.empList.forEach((emp: any, i: any) => {
                emp.TotalCount = props.user.empList.length, emp.RowNumber = i + 1
            });

            setempList(props.user.empList.filter((emp: any) =>
                (emp.RowNumber <= 8)));
        }
        else
            setempList(props.user.empList);
    }, [props.user.empList])


    useEffect(() => {
        //props.getEmpDetailsList(filesTableParams, empGuid);
        if (props.user.empList) {
            const emps = props.user.empList.filter((emp: any) =>
                (props.user.empData && props.user.empData.UserTypeId == 3 && emp.OrgName.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                emp.FirstName.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                emp.LastName.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                emp.OfficeEmail.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                emp.Mobile.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                emp.Designation.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                (emp.EmailConfirmed ? emp.IsDeleted ? 'inactive'.startsWith(filesTableParams.Search.toLowerCase())  
                         : 'live'.startsWith(filesTableParams.Search.toLowerCase())      // Check for "live"
                    : 'pending'.startsWith(filesTableParams.Search.toLowerCase())       // Check for "pending"
                )          
              );

            emps.forEach((emp: any, i: any) => {
                emp.TotalCount = emps.length, emp.RowNumber = i + 1
            });

            setempList(emps.filter((emp: any) =>
                emp.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && emp.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );
        }
    }, [filesTableParams])

    useEffect(() => {
        if (props.user.success) {
            props.RemoveSuccessStatus();
            //setApiStatus(props.user.success);
            if (props.user.success == 'Updated successfully.') {
                const empData = getEmployee(deleteEmpGuid);
                empData.IsDeleted = !empData.IsDeleted
            }
            //props.ShowToast('User', props.user.success, deleteText+ " User");
            props.ShowToast({
                type: 'Message',
                title: deleteText + " employee",
                message: props.user.success
            });
        }
    }, [props.user.success])

    function getEmployee(empGuid: any): any {
        const empData = props.user.empList.find((emp: any) => emp.EmpGuid === empGuid);
        return empData;
    }

    const editEmployee = (e: any, row: any) => {
        let path = `/user?id=${row.UserGuid}`;
        history(path);
    }

    function CreateUser() {
        history('/user');
    }

    const handleShowDeleteUser = (e: any, emp: any) => {
        console.log(emp.IsDeleted)
        deleteEmpGuid = emp.EmpGuid;
        deleteText = emp.IsDeleted ? "Live" : "Inactive";
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    const handleCloseDeleteUser = () => {
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }
    const deleteUser = () => {
        props.DeleteEmployeeByEmployeeGuid(deleteEmpGuid);
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    function headerRender() {
        return (
            <tr>
                {props.user.empData && props.user.empData.UserTypeId == 3 ? <th>Company</th> : ''}
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Phone number</th>
                <th>Function</th>
                <th>Created date</th>
                <th>Status</th>
                <th className="mobile-none"></th>
            </tr>);
    }

    function rowRender(row: any, index: number) {
        return (<tr key={index}>
            {props.user.empData && props.user.empData.UserTypeId == 3 ? <td>{row.OrgName}</td> : ''}
            <td>{row.FirstName}</td>
            <td>{row.LastName}</td>
            <td>{row.OfficeEmail}</td>
            <td>
                <PatternFormat
                    format="###-###-####"
                    value={row.Mobile}
                    displayType="text"
                />
            </td>
            <td>{row.Designation}</td>
            <td>{Moment(row.CreatedDateStr).format(formattedDate)}</td>
            <td>{row.EmailConfirmed ? row.IsDeleted ? "Inactive" : "Live" : "Pending"}</td>
            <td className="text-right dropdown mobile-none">
            {row.EmailConfirmed &&
                <span>
                    <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15" >
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                    </svg>

                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        {!row.IsDeleted ? <a onClick={e => editEmployee(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Edit</a> : null}
                        <a onClick={e => handleShowDeleteUser(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> {row.IsDeleted ? "Live" : "Inactive"}</a>
                    </div>
                </span>}
            </td>
        </tr >);
    }

    return (<>
        <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteUser} message={"Do you want to " + deleteText.toLowerCase() + " this employee?"} />
        <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
            <div className="container-fluid">
                {apiStatus ? <p className="alert alert-danger col-2 ml-auto mr-auto mt-3 text-center">{apiStatus}</p> : ''}
                <div className="row mt-4 justify-content-center">
                    <Table
                        id='users'
                        data={empList || []}
                        headerRender={headerRender}
                        rowRender={rowRender}
                        onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                        createLabel={props.user.empData && props.user.empData.UserTypeId == 3 ? 'Create admin employee' : 'Create employee'}
                        rootUrl={props.user.empData && props.user.empData.UserTypeId == 3 ? '/pod' : '/admin'}
                        onCreate={() => CreateUser()}
                        header={props.user.empData && props.user.empData.UserTypeId == 3 ? 'Admin employee' : 'Employee'}
                        subHeader=""
                        noDataMsg="No employee found"
                        {...props}>
                    </Table>
                </div>
            </div>
        </section>
    </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);