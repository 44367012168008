import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import { t } from '../i18n';

interface HeaderProps {
    title: string;
}
function PublicHeader(props: HeaderProps) {
    const URL = process.env.REACT_APP_URL as string;
    useEffect(() => {
        document.title = "SUTE | "+props.title;
    },[])

    return (<div className="row">
        <nav className="navbar col-sm-12">
            <Link className="navbar-brand" to="/">
                <img src="assets/media/images/logo.png" width="120" alt="SUTE" />
            </Link>
            <ul className="navbar-nav navbar-expand small">
                <li className="nav-item active">
                    <a className="nav-link text-white pr-3" >{t('myprofile.about')}</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-white pr-3 pl-3">{t('publicheader.membership')}</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-white pr-3 pl-3" >{t('publicheader.feature')}</a>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link text-white pl-3 dropdown-toggle"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('publicheader.downloads')}</a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href={URL+"/sute-web.msi"}>{t('publicheader.windows_app')}</a>
                        <a className="dropdown-item" href={URL+"/sute-web-darwin-x64-0.1.0.zip"}>{t('publicheader.mac_desktop')}</a>
                        <a className="dropdown-item" href={URL+"/sute-web_amd64.deb"}>{t('publicheader.linux')}</a>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
    )
}

export default PublicHeader;