import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../actions/MsActions';

 function Mslogin(props: any) {
    const identity = useSelector((state: any) => state.identity);
    const dispatch = useDispatch();

    const onClickHandler = () => {
        console.log(identity);
        dispatch(signIn(props.history) as any);
    };
    return ( 
            <img onClick={onClickHandler} className="ml-2 c-pointer" alt="Teams" src="/assets/media/svg/teams.svg" height="36px" width="36px"/>
    );      
};

export default Mslogin;