import { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import WindowTitle from '../../layout/WindowTitle';
import { StoreClass } from '../../store/Store';
import {t} from '../../i18n'
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";

function Admin(_props: any) {
	const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
	const history = useNavigate();
	const [showTitle] = useState(true);
	const [showComingsoon, setComingsoon] = useState(false);
	const [screenName,setScreenName] = useState("");
	const dispatch = useDispatch();
	const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')

	useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

	const closeComingsoon = () => {
		setComingsoon(false);
	  }
	
	  const WellnessComming = () =>{ 
		const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'WELLNESS');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
	  }

	return (
		<section className="bg-white overflow-hidden position-relative h-100" style={{ backgroundImage: 'url(' + user.orgData.BGURL + ')', backgroundSize: 'cover' }}>
			<WindowTitle title="Admin" />
			<div className="container">
				<div className="row">
					<div className="col-sm-3 mt-5">
						{(user.orgData.LogoURL || '').length > 0 ?
							<img src={user.orgData.LogoURL ? user.orgData.LogoURL : ''} className="PodMainLogo" />
							:
							<img src={user.orgData.BrandLogo} className="PodMainLogo" />
						}
						{showTitle ? <>
							<h1 className="font-weight-light mt-5 c-codet" style={{ color: user.orgData.TagLineColor }}>{user.orgData.TagLine}</h1>
						</> : <h1 className="font-weight-light mt-5 c-codet" style={{ color: user.orgData.TagLineColor }}>{user.orgData.TagLine}</h1>
						}
					</div>
					{(user.orgData.LogoURL || '').length > 0 ? <img className="suteFLogo" src={user.orgData.BrandLogo} width="80px" /> : <></>}

				</div>
			</div>
			<div className="col-12 row mt-5 m-0 mobile-block">
				<ul id="hexGrid">
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-style">
						<Link className="hexIn hexLink hexBgImg" to='/users'>
							<img className="hexHoverBg" src="/assets/media/images/employee-hover-bg.png" />
							<p> <span className="align-self-center">{t('admin.employee')}</span> </p>
						</Link>
					</li>
					<li className="hex" style={{ marginTop: "-8%" }}>
						<Link className="hexIn hexLink hexBgImg" to='/Induction'>
							<img className="hexHoverBg" src="/assets/media/images/onboarding-hover-img.jpg" />
							<p> <span className="align-self-center">{t('admin.onbaording')}</span> </p>
						</Link>
					</li>
					<li className="hex" style={{ marginTop: "-8%" }}>
						<Link className="hexIn hexLink hexBgImg" to='/ManageBulletinBoard'>
							<img className="hexHoverBg" src="/assets/media/images/announcements-hover-bg.jpg" />
							<p> <span className="align-self-center">{t('admin.company_announcements')}</span> </p>
						</Link>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-style">
						<div className="hexIn hexAdd">
							<p>
								<button className="align-self-center" onClick={() => history('/pod')}>
									<svg className="mb-3" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" height="40">
										<path d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8" stroke="#fff"></path>
									</svg>
								</button>
							</p>
						</div>
					</li>

					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
					<li className="hex mobile-none">
						<a className="hexIn" ></a>
					</li>
				</ul>

			</div>
			<ul id="hexGrid" className="mobile-none">
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex  hex-active mobile-none">
					<Link className="hexIn hexLink hexBgImg" to='/users'>
						<img className="hexHoverBg" src="/assets/media/images/employee-hover-bg.png" />
						<p> <span className="align-self-center">{t('admin.employee')}</span> </p>
					</Link>
				</li>
				<li className="hex mobile-none">
					<Link className="hexIn hexLink hexBgImg" to='/Induction'>
						<img className="hexHoverBg" src="/assets/media/images/onboarding-hover-img.jpg" />
						<p> <span className="align-self-center">{t('admin.onboarding')}</span> </p>
					</Link>
				</li>
				<li className="hex mobile-none">
					<Link className="hexIn hexLink hexBgImg" to='/ManageBulletinBoard'>
						<img className="hexHoverBg" src="/assets/media/images/announcements-hover-bg.jpg" />
						<p> <span className="align-self-center">{t('admin.company_announcements')}</span> </p>
					</Link>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex mobile-none">
					{/* <Link className="hexIn hexLink hexBgImg" to='/AdminChallenges'> */}
					<Link className="hexIn hexLink hexBgImg" onClick={WellnessComming}>
						<img className="hexHoverBg" src="https://img.freepik.com/free-photo/man-jump-through-gaps-hills_1150-19693.jpg?t=st=1714981218~exp=1714984818~hmac=e89b677dfe991552d67aac5a94adcb829f50a57f3ac38c6f6413f655cfd0b27b&w=740" />
						<p> <span className="align-self-center">{t('admin.wellness_initiatives')}</span> </p>
					</Link>
				</li>
				<li className="hex mobile-none">
					<Link className="hexIn hexLink hexBgImg" to='/groups'>
						<img className="hexHoverBg" src="/assets/media/images/team-hover-bg.jpg" />
						<p> <span className="align-self-center">{t('admin.teams')}</span> </p>
					</Link>
				</li>
				<li className="hex">
					<div className="hexIn hexAdd">
						<p>
							<button className="align-self-center" onClick={() => history('/pod')}>
								<svg className="mb-3" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" height="40">
									<path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59 c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815 C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029 c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562 C26.18,21.891,26.141,21.891,26.105,21.891z" stroke="#fff"></path>
								</svg>
							</button>
						</p>
					</div>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
				<li className="hex">
					<a className="hexIn" ></a>
				</li>
			</ul>
			<ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
		</section>
	);
}

export default Admin;