import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { t } from '../i18n';
import { ModalFooter } from 'reactstrap';
import { GetCommingSoon } from "../services/WellnessServices";
import { useDispatch } from "react-redux";
import * as T from "../actions/Types";



function ComingSoonPopup(props: any) {
    const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
    const modelRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedEmoji(null)
    }, [props.screenName])


    const handleEmojiClick = (emojiKey : any) => {
        GetCommingSoon(props.screenName, emojiKey, 1)
        .then((resp: any) => {
            // setFetchingCoomingSoonData([...resp.result]);
            if (resp.status == "success") {
                dispatch({ type: T.REMOVE_LOADING });
            } else if (resp == "Error") {
                dispatch({ type: T.REMOVE_LOADING });
            }
        })
        .catch((e: any) => {
            dispatch({ type: T.REMOVE_LOADING });
            console.error(e);
        });                    
        setSelectedEmoji(emojiKey);
    };



    const handleClose = () => {
        setSelectedEmoji(null); // Reset emoji when closing
        props.close(); // Call parent close function
    };


    return (
        <>
                <Modal  show={props.show} onHide={handleClose} className="text-light modal-border-radius" style={{ display: "flex !important", height: "85vh", verticalAlign: "middle", alignItems: "center" }}>
                    <Modal.Header closeButton className="border-0">
                        <Modal.Title className="modal-title h5 m-auto" id="confirmPopupLabel">Coming soon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12">
                            <p className="text-center">{props.message}</p>
                            <p className="text-center">
                                <img src="https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png" className={`emojy-img ${selectedEmoji === 'emoji1' ? 'select-emojy-img' : ''}`} onClick={() => handleEmojiClick('emoji1')} />
                                <img src="https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png" className={`emojy-img ${selectedEmoji === 'emoji2' ? 'select-emojy-img' : ''}`} onClick={() => handleEmojiClick('emoji2')} />
                                <img src="https://em-content.zobj.net/source/apple/391/grinning-face_1f600.png" className={`emojy-img ${selectedEmoji === 'emoji3' ? 'select-emojy-img' : ''}`} onClick={() => handleEmojiClick('emoji3')} />
                                <img src="https://em-content.zobj.net/source/apple/391/smiling-face-with-heart-eyes_1f60d.png" className={`emojy-img ${selectedEmoji === 'emoji4' ? 'select-emojy-img' : ''}`} onClick={() => handleEmojiClick('emoji4')} />
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
        </>
    )
}

export default React.memo(ComingSoonPopup);