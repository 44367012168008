import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import CheckinsRightMenu from './CheckinsRightMenu';
import { saveCheckin } from '../../services/CheckinsServices';
import { removeSuccessStatus } from "../../services/ContactsService";
import { ShowToast } from "../../services/UserActions";
import { StoreClass } from "../../store/Store";

import { Modal } from 'react-bootstrap';
import { ReactComponent as Flag } from './flag.svg';

interface formError {
	message: string;
}

interface Checkin {
	CheckinId: number;
	EmployeeGuid: string;
	Problems: string;
	Emoji: string;
}

interface data {
	txt: string | null;
	File: any;
	Flag: string;
	FileName: string;
}

function Checkins(props: any) {

	const [openEmoji, setOpenEmoji] = useState('');
	const [openFlag, setOpenFlag] = useState('');
	const handleEmojiClose = () => setOpenEmoji('');
	const handleFlagClose = () => setOpenFlag('');
    const [isSaveInprogress, setSaveInprogress] = useState(false);


	const dispatch = useDispatch();
	const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

	const [errors, setErrors] = useState({} as formError);
	const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
		{errors.message}
	</div></div></div>);

	const [values, setValues] = useState({} as Checkin);
	const [plans, setPlans] = useState<any[]>([]);
	const [progresses, setProgresses] = useState<data[]>([]);
	const [reportingList, setReportingList] = useState<any>([] as any);
	const [parentReportingList, setParentReportingList] = useState([] as any);
	//const [optionsEmpListReportingTo,setOptionsEmpListReportingTo] = useState<any>([]);


	let empId = localStorage.getItem('EmpGuid') as string;
	let emojis = [
		{ image: "https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png", title: "Annoyed" },
		{ image: "https://em-content.zobj.net/source/apple/391/sleepy-face_1f62a.png", title: "Sleepy" },
		{ image: "https://em-content.zobj.net/source/apple/391/smirking-face_1f60f.png", title: "Worried " },
		{ image: "https://em-content.zobj.net/source/apple/391/smiling-face_263a-fe0f.png", title: "Excited" },
		{ image: "https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png", title: "Funny" },
		{ image: "https://em-content.zobj.net/source/apple/391/grinning-face_1f600.png", title: "Happy"},
		{ image: "https://em-content.zobj.net/source/apple/391/smiling-face-with-heart-eyes_1f60d.png", title: "Cool" }
	];
	let flags = ["red", "orange", "yellow", "green"];
	const MAXFILESIZE = process.env.REACT_APP_MAXFILESIZE as string;





	const handleSelectEmoji = (emoji: string) => {
		setValues({ ...values, Emoji: emoji });
		setOpenEmoji('');
	};

	const handleSelectFlag = (flag: string) => {
		if (openFlag.match(/planflag*/)) {
			plans[Number(openFlag.replace('planflag', ''))].Flag = flag;
		}
		if (openFlag.match(/progressflag*/)) {
			progresses[Number(openFlag.replace('progressflag', ''))].Flag = flag;
		}
		setOpenFlag('');
	};

	const handleChange = (e: any) => {
		e.persist();
		if (e.target.value)
			setValues(values => ({
				...values,
				[e.target.name]: e.target.value.replace(/<(.|\n)*?>/g, '')
			}));
		else
			setValues(values => ({
				...values,
				[e.target.name]: null
			}));

		setErrors({ message: '' });
	};

	const handleChangeData = (data: data) => (e: any) => {
		e.target.value=e.target.value.trimStart();
		e.persist();
		if (e.target.value)
			data.txt = e.target.value.replace(/<(.|\n)*?>/g, '');
		else
			data.txt = null;
		setErrors({ message: '' });
	};

	useEffect(() => {
		dispatch(removeSuccessStatus() as any);
		console.log("removeSuccessStatus")
		setValues({ ...values, EmployeeGuid: empId, Emoji: "https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png" });
		setPlans([{ Flag: "white" } as data]);
		setProgresses([{ Flag: "white" } as data]);
	}, []);

	function fileSelectHandler(e: any, index: number, isplans: boolean) {

		setErrors({ message: '' });
		if (e.currentTarget.files.length > 0 && (e.currentTarget.files[0].size / 1024) / 1024 > Number(MAXFILESIZE)) {
			setErrors({ message: "File maximum size " + MAXFILESIZE + " MB" });
			e.target.value = null;
		}
		e.preventDefault();
		if (isplans)
			setPlans([
				...plans.slice(0, index),
				{
					...plans[index],
					File: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '',
					FileName: e.currentTarget.files.length > 0 ? e.currentTarget.files[0].name : '',
				},
				...plans.slice(index + 1)
			]);
		else
			setProgresses([
				...progresses.slice(0, index),
				{
					...progresses[index],
					File: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '',
					FileName: e.currentTarget.files.length > 0 ? e.currentTarget.files[0].name : '',
				},
				...progresses.slice(index + 1)
			]);
	}

	function validateData() {
		let errrs = [] as any;
		let required = [] as any;
		setErrors({ message: '' });

		plans.forEach((plan, index) => {
			if (plan.txt) {
				return;
			}
			required.push('plan');
		});

		progresses.forEach((progress, index) => {
			if (progress.txt) {
				return;
			}
			required.push('progress');
		});

		if (!values.Problems || values.Problems.length === 0) {
			//formErrors["FirstName"] = { cls: 'is-invalid', edited: true } ;
			//errrs.push('Plan');
			required.push('Problems');
		}

		/*if (errrs.length == 0 && required.length == 0) {
			props.signupAction(values, props.history);
		} else {*/
		if (errrs.length > 0 || required.length >= 3) {
			var error: string = '';
			if (errrs.length > 1)
				error = errrs.join(', ') + " are invalid!";
			else if (errrs.length == 1)
				error = errrs[0] + " is invalid!";
			if (required.length >= 3)
				//error = "Please enter data for " + required.join(', ') + '!\n\r' + error;
				error = "Please enter data " + '!\n\r' + error;

			setErrors({ message: error });
			return false;
		}
		else
			return true;
	}

	function addPlan() {
		setErrors({ message: '' });
		setPlans([...plans, { Flag: "white" } as data]);
	}
	function removePlan(idx: number) {
		setErrors({ message: '' });
		const fplans = plans.filter((plan: any, index: number) => index != idx);
		setPlans([...fplans]);
	}
	function addProgress() {
		setErrors({ message: '' });
		setProgresses([...progresses, { Flag: "white" } as data]);
	}
	function removeProgress(idx: number) {
		setErrors({ message: '' });
		const fprogresses = progresses.filter((progress: any, index: number) => index != idx);
		setProgresses([...fprogresses]);
	}
	const saveData = async (event: any) => {
		event.preventDefault();
		const formData = new FormData();
		plans.forEach((plan, index) => {
				formData.append(`Plans[${index}].txt`, plan.txt || '');
				formData.append(`Plans[${index}].Flag`, plan.Flag == "white" ? '' : plan.Flag);
				if (plan.File) {
					formData.append(`Plans[${index}].File`, plan.File);
				  }

		});
				progresses.forEach((progress, index) => {
					formData.append(`Progresses[${index}].txt`, progress.txt || '');
					formData.append(`Progresses[${index}].Flag`, progress.Flag === "white" ? '' : progress.Flag);
					if (progress.File) {
					formData.append(`Progresses[${index}].File`, progress.File);
					}
				});
		if (validateData()) {
			setSaveInprogress(true);
			formData.append('EmployeeGuid', values.EmployeeGuid);
			if (values.Problems)
				formData.append('Problems', values.Problems);
			formData.append('Emoji', values.Emoji);

			dispatch(saveCheckin(formData) as any);
			setTimeout(() => {
				setSaveInprogress(false);
			}, 2000);
		}
	}

	useEffect(() => {

		if (user.success && user.success === "checkin success") {
			console.log("user.success", user.success)
			let toast = {
				type: 'Message',
				title: "Status",
				message: 'Checkin created successfully.'
			};
			dispatch(ShowToast(toast) as any);
			dispatch(removeSuccessStatus() as any);
			setValues({ EmployeeGuid: empId, Emoji: 'https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png' } as Checkin);
			setPlans([{ Flag: "white" } as data]);
			setProgresses([{ Flag: "white" } as data]);
		}
	}, [user.success]);

	useEffect(() => {
		setErrors({ message: user.error?.message });
		console.log("reporting is render are not", parentReportingList.length)
	}, [user.error, parentReportingList]);

	return (
		<section className="bgCadet position-relative d-flex pt-4 pb-4 minh-100">
			<div className="col-sm-8 popup-settings ml-auto">
				<CheckinsRightMenu path="checkins" ReportingListMenu={setParentReportingList} />
				{errors.message && errormsg}
				<div className="row">
					<div className="col-sm-10 m-auto popup-settings">
						<div className="card loginBox addTeamMemberBox mt-5 mb-5">
							<div className="card-body pl-5 pr-5">
								<div className="row m-auto">
									<div className="col-6 text-white pl-0">
										<h5>Add Check-In</h5>
									</div>
									{/* {parentReportingList.length > 0 && (
										<>
											<div className="col-2 mb-4 mt-4 text-white pl-0">
												<p>Access By:</p>
											</div>
											<div className="col-4 mb-4 mt-4 text-white pl-0">
												<h6>{parentReportingList}</h6>
											</div>
										</>
									)} */}

									{(user?.empData?.ReportingName?.trim().length > 0)?<>
											<div className="col-2 mb-4 mt-4 text-white pl-0">
												<p>Access By:</p>
											</div>
											<div className="col-4 mb-4 mt-4 text-white pl-0">
												<h6>{user?.empData?.ReportingName}</h6>
											</div>
										</>:<></>}
									<div className="col-12  text-white pl-0 mt-2">
										<h6>How Do You Feel Today <span className="mr-2 c-pointer" onClick={() => setOpenEmoji('emoji')}><img width="25px" src={values.Emoji} /></span>
										</h6>
										<h6 className="mt-3">Plans and objectives :</h6>
									</div>
									{(plans || []).map((plan: data, index: number) => {
										return (
											<div className="col-12 row m-0 p-0 mt-2">
												<div className="col-8 pl-0">
													<input name="Plan" value={plan.txt || ''} onChange={handleChangeData(plan)} required maxLength={100} type="text" className="checkin-input SuteInputPrimary h-100"  />

												</div>
												<div className="col-1 p-0">
													<button type="button" className="upload-btn"><span>+</span><span className="ml-1"><img src="assets/media/images/file-icon.png" /><input type="file" className="inputWithPhoto" name={"Plan_" + index} id={"Plan_" + index} onChange={(e) => fileSelectHandler(e, index, true)} /></span></button>
												</div>
												<div className="col-1 p-0">
													<button type="button" className="upload-btn">
														<span>+</span>
														<Flag width="25px" height="25px" onClick={() => setOpenFlag('planflag' + index)} fill={plan.Flag}></Flag>
													</button>
												</div>
												{plans.length > 1 ?
													<div className="col-1 p-0">
														<button onClick={() => removePlan(index)} type="button" className="upload-btn plus-btn"><span><img src="assets/media/images/minus.png" /></span></button>
													</div> : ''
												}
												{index == plans.length - 1 ?
													<div className="col-1 p-0">
														<button onClick={addPlan} type="button" className="upload-btn plus-btn"><span><img src="assets/media/images/plus.png" /></span></button>
													</div> : ''
												}
												<div className="col-12 p-0">
													<span className="file-upload-text">{plan.FileName}</span>
												</div>
											</div>
										)
									})}
									<div className="col-12  text-white pl-0 mt-2">
										<h6 className="mt-2">Progress updates :</h6>
									</div>
									{(progresses || []).map((progress: data, index: number) => {
										return (
											<div className="col-12 row m-0 p-0 mt-2">
												<div className="col-8 pl-0">
													<input name="Progress" value={progress.txt || ''} onChange={handleChangeData(progress)} maxLength={100} type="text" className="checkin-input SuteInputPrimary h-100" />
												</div>
												<div className="col-1 p-0">
													<button type="button" className="upload-btn"><span>+</span><span className="ml-1"><img src="assets/media/images/file-icon.png" /><input type="file" className="inputWithPhoto" name="Progress_" onChange={(e) => fileSelectHandler(e, index, false)} /></span></button>
												</div>
												<div className="col-1 p-0">
													<button type="button" className="upload-btn">
														<span>+</span>
														<Flag width="25px" height="25px" onClick={() => setOpenFlag('progressflag' + index)} fill={progress.Flag}></Flag>
													</button>
												</div>
												{progresses.length > 1 ?
													<div className="col-1 p-0">
														<button onClick={() => removeProgress(index)} type="button" className="upload-btn plus-btn"><span><img src="assets/media/images/minus.png" /></span></button>
													</div> : ''
												}
												{index == progresses.length - 1 ?
													<div className="col-1 p-0">
														<button onClick={addProgress} type="button" className="upload-btn plus-btn"><span><img src="assets/media/images/plus.png" /></span></button>
													</div> : ''
												}
												<div className="col-12 p-0">
													<span className="file-upload-text">{progress.FileName}</span>
												</div>
											</div>
										)
									})}
									<div className="col-12  text-white pl-0 mt-2">
										<h6 className="mt-2">Problems or comments :</h6>
									</div>
									<div className="col-12 row m-0 p-0">
										<div className="col-12 pl-0">
											<input name="Problems" value={values.Problems || ''} onChange={handleChange} maxLength={100} type="text" className="checkin-input SuteInputPrimary"  style={{ height: '35px' }} />
										</div>
									</div>
								</div>
								<div className="form-group mt-3">
									<button onClick={saveData} type='button' disabled={isSaveInprogress} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-2 mb-2">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={openEmoji != '' ? true : false} onHide={handleEmojiClose}>
				<Modal.Header closeButton className="border-0">
					<Modal.Title className="modal-title h5 text-white">Select Emoji</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-12">
						<ul className="emojiUL">
							{emojis.map((emoji: any) => {
								return (
									<li className={emoji.image === values.Emoji ? "active" : ''} onClick={function () { handleSelectEmoji(emoji.image) }}>
										<img className="emojiSingle" src={emoji.image} title={emoji.title} />
									</li>
								)
							})}
						</ul>
					</div>
				</Modal.Body>
				<Modal.Footer className="border-0">
					<button type="button" style={{ width: '80px' }} className="btn btn-SeaGreen" onClick={handleEmojiClose}>Cancel</button>
				</Modal.Footer>
			</Modal>
			<Modal show={openFlag != '' ? true : false} onHide={handleFlagClose} size="sm"  backdrop="static"  keyboard={false}>
				<Modal.Header closeButton className="border-0">
					<Modal.Title className="modal-title text-light small">Select Flag</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-12">
						<ul className="emojiUL emoji4Cols">
							{flags.map((flag: any) => {
								return (
									<li className={flag === plans[Number(openFlag.replace('planflag', ''))]?.Flag || flag === progresses[Number(openFlag.replace('progressflag', ''))]?.Flag ? "active" : ''}
										onClick={function () { handleSelectFlag(flag) }}>
										<Flag fill={flag}></Flag>
									</li>
								)
							})}
						</ul>
					</div>
				</Modal.Body>
				<Modal.Footer className="border-0">
					<button type="button" style={{ width: '80px' }} className="btn btn-secondary btn-sm" onClick={handleFlagClose}>Cancel</button>
				</Modal.Footer>
			</Modal>
		</section>
	);
}


export default Checkins;