export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';
export const SET_AUTHENTICATED = 'SET_CURRENT_USER';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_STORE = 'CLEAR_STORE';
export const LOGOUT = 'LOGOUT';
export const WINDOW_FOCUS = 'WINDOW_FOCUS';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';
export const SIGNUP_MSG = 'SIGNUP_MSG';

export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const PREF_CONTACTS_SUCCESS = 'PREF_CONTACTS_SUCCESS';
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
//UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//MS LOGIN
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const NETWORK_SUCCESS = 'NETWORK_SUCCESS';
export const NETWORK_STOP = 'NETWORK_STOP';
export const NETWORK_START = 'NETWORK_START';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
//general saving status for all apis
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const REMOVE_SUCCESS = "REMOVE_SUCCESS";

//Teams
export const GET_TEAMMEETING = 'GET_TEAMMEETING';
export const GET_TEAMMEETING_SUCCESS = 'GET_TEAMMEETING_SUCCESS';
export const GET_TEAMMEETING_FAILURE = 'GET_TEAMMEETING_FAILURE';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';

export const CREATE_MEETING = 'CREATE_MEETING';
export const CREATE_MEETING_FAILURE = 'CREATE_MEETING_FAILURE';
export const ACTIVE_MEETINGS = 'ACTIVE_MEETINGS';
export const GET_MEETING_INFO = 'GET_MEETING_INFO';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";

export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS';
export const GET_ROOM_MEM_SUCCESS = 'GET_ROOM_MEM_SUCCESS'
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE';
export const ROOM_STATS = "ROOM_STATS";

export const GET_EMPLOYEE_EVENTS = "GET_EMPLOYEE_EVENTS";
export const SYNC_GOOGLE_CALENDER = "SYNC_GOOGLE_CALENDER";
export const SYNC_EXCHANGE_CALENDER = "SYNC_EXCHANGE_CALENDER";

export const ORG_LOGO_UPLOAD_SUCCESS = "ORG_LOGO_UPLOAD_SUCCESS";
export const ORG_LOGO_UPLOAD_FAILURE = "ORG_LOGO_UPLOAD_FAILURE";

export const ADD_MEMBERS_SUCCESS = "ADD_MEMBERS_SUCCESS";
export const ADD_MEMBERS_FAILURE = "ADD_MEMBERS_FAILURE";

export const PROFILE_IMAGE_UPLOAD_SUCCESS = "PROFILE_IMAGE_UPLOAD_SUCCESS";
export const PROFILE_IMAGE_UPLOAD_FAILURE = "PROFILE_IMAGE_UPLOAD_FAILURE";

export const ADD_AVSETTINGS_SUCCESS = "ADD_AVSETTINGS_SUCCESS";
export const ADD_AVSETTINGS_FAILURE = "ADD_AVSETTINGS_FAILURE";

export const GET_ORG_DETAILS_SUCCESS = "GET_ORG_DETAILS_SUCCESS"
export const GET_ORG_DETAILS_FAILURE = "GET_ORG_DETAILS_FAILURE"

export const GET_ORG_LIST_SUCCESS = "GET_ORG_LIST_SUCCESS"
export const GET_ORG_LIST_FAILURE = "GET_ORG_LIST_FAILURE"

export const GET_EMP_DATA_SUCCESS = "GET_EMP_DATA_SUCCESS"
export const GET_EMP_DATA_FAILURE = "GET_EMP_DATA_FAILURE"
export const GET_EMP_STATUS = "GET_EMP_STATUS";
export const UPDATE_EMP_STATUS = "UPDATE_EMP_STATUS";
export const GET_EMP_LIST_SUCCESS = "GET_EMP_LIST_SUCCESS"
export const GET_EMP_LIST_FAILURE = "GET_EMP_LIST_FAILURE"
export const GET_EMP_LIST_REPORTINGTO_SUCCESS = "GET_EMP_LIST_REPORTINGTO_SUCCESS"
export const GET_EMP_LIST_REPORTINGTO_FAILURE = "GET_EMP_LIST_REPORTINGTO_FAILURE"

export const CHECK_UNCHECK_EVENT_EMAIL = 'CHECK_UNCHECK_EVENT_EMAIL';
export const CHECK_UNCHECK_EVENT_CATEGORY = 'CHECK_UNCHECK_EVENT_CATEGORY';

export const CALENDAR_EVENT_UPDATE_SUCCESS = "CALENDAR_EVENT_UPDATE_SUCCESS"
export const CALENDAR_EVENT_UPDATE_FAILURE = "CALENDAR_EVENT_UPDATE_FAILURE"
export const REMOVE_CAL_SUCCESS = "REMOVE_CAL_SUCCESS";
export const CALENDER_EVENT_CATEGORIES = "CALENDER_EVENT_CATEGORIES"

export const AVAILABLE_TIME_SLOTS_SUCCESS = "AVAILABLE_TIME_SLOTS_SUCCESS"
export const INTERNAL_EVENT_CREATE_SUCCESS = "INTERNAL_EVENT_CREATE_SUCCESS"

export const DELETE_CALENDAR_EVENT_FAILURE = "DELETE_CALENDAR_EVENT_FAILURE"
export const DELETE_CALENDAR_EVENT_SUCCESS = "DELETE_CALENDAR_EVENT_SUCCESS"

export const SAVE_GOOGLE_CALENDAR_EVENT_FAILURE = "SAVE_GOOGLE_CALENDAR_EVENT_FAILURE"
export const SAVE_GOOGLE_CALENDAR_EVENT_SUCCESS = "SAVE_GOOGLE_CALENDAR_EVENT_SUCCESS"

export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const DELETE_CALENDAR_ACCOUNT_FAILURE = "DELETE_CALENDAR_ACCOUNT_FAILURE";
export const DELETE_CALENDAR_ACCOUNT_SUCCESS = "DELETE_CALENDAR_ACCOUNT_SUCCESS";

export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const GET_FOLDER_FAILURE = "GET_FOLDER_FAILURE";
export const SUTE_USER_STATUS = "SUTE_USER_STATUS";
export const AGORA_USER_STATUS = "AGORA_USER_STATUS";
export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT";
export const EVENT_PARTICIPANTS = "EVENT_PARTICIPANTS";

export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";

export const MEDIA_TRACK_LIST_SUCCESS = "MEDIA_TRACK_LIST_SUCCESS";
export const MEDIA_TRACK_LIST_FAILURE = "MEDIA_TRACK_LIST_FAILURE";

export const MEETINID_SET = "MEETINGID_SET";

export const SOUND_TRACK_LIST_SUCCESS = "SOUND_TRACK_LIST_SUCCESS";
export const SOUND_TRACK_LIST_FAILURE = "SOUND_TRACK_LIST_FAILURE";

export const LEADER_TRACK_LIST_SUCCESS = "LEADER_TRACK_LIST_SUCCESS";
export const LEADER_TRACK_LIST_FAILURE = "LEADER_TRACK_LIST_FAILURE";

export const ANXIETY_TRACK_LIST_SUCCESS = "ANXIETY_TRACK_LIST_SUCCESS";
export const ANXIETY_TRACK_LIST_FAILURE = "ANXIETY_TRACK_LIST_FAILURE";

export const DIVERSITY_TRACK_LIST_SUCCESS = "DIVERSITY_TRACK_LIST_SUCCESS";
export const DIVERSITY_TRACK_LIST_FAILURE = "DIVERSITY_TRACK_LIST_FAILURE";

export const LIFE_BALANCE_TRACK_LIST_SUCCESS = "LIFE_BALANCE_TRACK_LIST_SUCCESS";
export const LIFE_BALANCE_TRACK_LIST_FAILURE = "LIFE_BALANCE_TRACK_LIST_FAILURE";

export const GUIDED_MEDITATION_TRACK_LIST_SUCCESS = "GUIDED_MEDITATION_TRACK_LIST_SUCCESS";
export const GUIDED_MEDITATION_TRACK_LIST_FAILURE = "GUIDED_MEDITATION_TRACK_LIST_FAILURE";

export const PERSONAL_GROWTH_TRACK_LIST_SUCCESS = "PERSONAL_GROWTH_TRACK_LIST_SUCCESS";
export const PERSONAL_GROWTH_TRACK_LIST_FAILURE = "PERSONAL_GROWTH_TRACK_LIST_FAILURE";

export const PILANTHROPY_TRACK_LIST_SUCCESS = "PILANTHROPY_TRACK_LIST_SUCCESS";
export const PILANTHROPY_TRACK_LIST_FAILURE = "PILANTHROPY_TRACK_LIST_FAILURE";

export const MENTALHEALTH_TRACK_LIST_SUCCESS = "MENTALHEALTH_TRACK_LIST_SUCCESS";
export const MENTALHEALTH_TRACK_LIST_FAILURE = "MENTALHEALTH_TRACK_LIST_FAILURE";

export const GET_EMPLOYEE_CALENDAR_EMAILS = "GET_EMPLOYEE_CALENDAR_EMAILS";

export const GROUP_EVENTS_SUCCESS = "GROUP_EVENTS_SUCCESS";
export const GROUP_EVENTS_FAILURE = "GROUP_EVENTS_FAILURE";

export const PROFILE_AVATAR_LIST_SUCCESS = "PROFILE_AVATAR_LIST_SUCCESS";
export const PROFILE_AVATAR_LIST_FAILURE = "PROFILE_AVATAR_LIST_FAILURE";

export const SHARED_FILES_GET_SUCCESS = "SHARED_FILES_GET_SUCCESS";
export const SHARED_FILES_GET_FALIURE = "SHARED_FILES_GET_FALIURE";
export const STORE_MSG = "STORE_MSG";
export const SAVE_MSG = "SAVE_MSG";
export const UPDATE_MSG = "UPDATE_MSG";
export const DELETE_MSG = 'DELETE_MSG';
export const SAVE_GROUP_MSG = "SAVE_GROUP_MSG";

export const EDIT_CONTENT_MSG = 'EDIT_CONTENT_MSG';
export const GET_UNREAD_MSGS = 'GET_UNREAD_MSGS';
export const GET_GROUP_UNREAD_MSGS = 'GET_GROUP_UNREAD_MSGS';
export const GET_MSG_LIST = 'GET_MSG_LIST';
export const GET_GROUP_MSG_LIST = 'GET_GROUP_MSG_LIST';
export const GET_CHAT_HEADERS = 'GET_CHAT_HEADERS';

export const GET_UNREAD_MSGS_ERROR='GET_UNREAD_MSGS_ERROR';
export const GET_MSG_LIST_ERROR = 'GET_MSG_LIST_ERROR';
export const GET_CHAT_HEADERS_ERROR = 'GET_CHAT_HEADERS_ERROR';
export const SHARED_FILE_CONTACTS_GET_SUCCESS = "SHARED_FILE_CONTACTS_GET_SUCCESS";
export const SHARED_FILE_CONTACTS_GET_FAILURE = "SHARED_FILE_CONTACTS_GET_FAILURE";

export const USER_FILES_UPLOADED = 'USER_FILES_UPLOADED';
export const GET_FILES_LIST = 'GET_FILES_LIST';
export const USER_FILES_SENTMSG = 'USER_FILES_SENTMSG';
export const GET_FEEDBACK_TYPES = 'GET_FEEDBACK_TYPES';
export const GET_SESSION_TOTALS ='GET_SESSION_TOTALS';
export const GET_SESSIONS_SPLIT = 'GET_SESSIONS_SPLIT';
export const GET_PRODUCTIVE_NON_PRODUCTIVE = 'GET_PRODUCTIVE_NON_PRODUCTIVE';

export const TOAST = 'TOAST';
export const GET_IP_ADRESS = 'GET_IP_ADRESS';
export const CREATE_CHECKIN_SUCCESS = "CREATE_CHECKIN_SUCCESS";
export const CREATE_CHECKIN_FAILURE = "CREATE_CHECKIN_FAILURE";
export const GET_CHECKIN_SUCCESS = "GET_CHECKIN_SUCCESS";
export const UPDATE_RATING_SUCCESS = "UPDATE_RATING_SUCCESS";
export const UPDATE_RATING_FAILURE = "UPDATE_RATING_FAILURE";

export const CREATE_ORGFILE_SUCCESS = "CREATE_ORGFLE_SUCCESS";
export const CREATE_ORGFILE_FAILURE = "CREATE_ORGFILE_FAILURE";
export const GET_ORGFILES_SUCCESS = "GET_ORGFILES_SUCCESS";

export const ADD_SOCKET = "ADD_SOCKET";
export const ADD_INCALL = "ADD_INCALL";
export const OPEN_CALL = "OPEN_CALL";
export const SELECTED_USER = "SELECTED_USER";
export const IS_GUEST = "IS_GUEST";


