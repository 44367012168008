/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Select from "react-select";
import {
  signupAction,
  getEmpDetailsByEmpGuid,
  getOrgList,
  UpdateuiComponentResponse,
  SendForgetPasswordEmail,
  getEmpDetailsList,
  getOrgDesignations,
} from "../../services/UserActions";
import { removeSuccessStatus } from "../../services/ContactsService";
import { Login } from "../../actions/AuthActions";
import SetupComponent from "../uicomponents/SetupComponent";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { Modal } from "react-bootstrap";
import { t } from "../../i18n";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import clsx from "classnames";
import { getOrgGroups } from "../../services/UserActions";
import { useSelector } from "react-redux";
import { StoreClass } from "../../store/Store";
import { DesignationsList } from "../../utils/types";
import { PatternFormat } from "react-number-format";
interface SignupProps {
  UserGuid: string;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Email: string;
  PhoneNumber: string;
  Password: string;
  CPassword: string;
  UserTypeId: number;
  OrgId: number;
  ReportingTo: number;
  ActionBy: string;
  groups: string;
  Designation_Id?: number;
  Designation: string;
}
var isCompanyDisabled = false;
var isEmailDisabled = false;

function Signup(props: any) {
  const [values, setValues] = useState({ UserTypeId: 2 } as SignupProps);
  const [groups, setGroups] = useState<any>([]);
  const [sltGroups, setSltGroups] = useState([{ value: "", label: "" }]);
  const [orgvalues, setOrgvalues] = useState();
  const [formErrors, setFormErrors] = useState<any>({});
  const [apiStatus, setApiStatus] = useState("");
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [optionsReportingTo, setOptionsReportingTo] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmResetPassword, setShowConfirmResetPassword] = useState(false);
  const [designationListOptions, setDesignationsListOptions] = useState<[]>([]);
  const empGuid = localStorage.getItem("EmpGuid");
  const orgGuid = localStorage.getItem("OrgGuid");
  const [reportingTo, setReportingTo] = useState<any>([]);
  const [reportingTwo, setReportingTwo] = useState<any>([]);

  const { user } = useSelector((state: StoreClass) => ({
    user: state?.AuthReducer,
  }));

  useEffect(() => {
    if (props.user.signupResponse) {
      if (props.user.signupResponse.message === "Success") {
        setApiStatus(props.user.signupResponse.result[0]);
        setIsError(false);

        setValues({ UserTypeId: 2 } as SignupProps);

        setTimeout(() => {
          dispatch(UpdateuiComponentResponse("signupResponse") as any);
          history("/Users");
        }, 3000);
      } else if (props.user.error) {
        setApiStatus("Account is already exists");
        setIsError(true);
      }
    } else if (props.user.error && props.user.error.message) {
      setApiStatus(props.user.error.message);
      setIsError(true);
    } else if (
      props.user.error &&
      props.user.error.result &&
      props.user.error.result.length > 0
    ) {
      setApiStatus(props.user.error.result[0].Description);
      setIsError(true);
    } else if (props.user.error && props.user.error.statusCode !== 200) {
      //console.log("props.user.error.statusCode",props.user.error.statusCode);
      //setApiStatus("Account is already exists");
      setApiStatus(props.user.error);
      setIsError(true);
    }
    props.RemoveSuccessStatus();
  }, [props.user.signupResponse, props.user.error]);
  useEffect(() => {
    if (!props.user.orgList) {
      props.getOrgList(new FilesTableBody(), isCompanyDisabled);
    } else {
      setOptions(
        props.user.orgList.map((org: any) => ({
          value: org.OrgId,
          label: org.OrgName,
        }))
      );
    }
  }, [props.user.orgList]);

  useEffect(() => {
    isCompanyDisabled = false;
    isEmailDisabled = false;
    props.getOrgList(new FilesTableBody(), isCompanyDisabled);
    props.getEmpDetailsByEmpGuid(empGuid);
  }, []);

  useEffect(() => {
    //get user details for edit
    const userGuid = searchParams.get("id");
    if (userGuid) {
      const empData = getEmployee(userGuid);
      if (!empData) return;
      setValues((previousValues) => ({
        ...previousValues,
        ["UserGuid"]: empData.UserGuid,
        ["FirstName"]: empData.FirstName,
      }));
      //setValues(previousValues => ({ ...previousValues, ["FirstName"]: empData.FirstName }));
      setValues((previousValues) => ({
        ...previousValues,
        ["LastName"]: empData.LastName,
        ["Email"]: empData.OfficeEmail,
      }));
      //	setValues(previousValues => ({ ...previousValues, ["Email"]: empData.OfficeEmail }));
      setValues((previousValues) => ({
        ...previousValues,
        ["PhoneNumber"]: empData.Mobile,
        ["OrgId"]: empData.OrgId,
      }));
      //setValues(previousValues => ({ ...previousValues, ["OrgId"]: empData.OrgId }));
      setValues((previousValues) => ({
        ...previousValues,
        ["ReportingTo"]: empData.ReportingTo,
        ["groups"]: empData.GroupsId,
        ["Designation"]: empData.Designation,
        // ["Designation_Id"]: empData.Desgination_Id,

      }));
      //setValues(previousValues => ({ ...previousValues, ["Groups"]: empData.Groups }));
      isCompanyDisabled = true;
      isEmailDisabled = true;
    }
    dispatch(getEmpDetailsList(empGuid) as any);
  }, []);

  useEffect(() => {
    if (props.user.success) {
      props.RemoveSuccessStatus();
      if (props.user.success == "Mail sent successfully") {
        setApiStatus(props.user.success);
        setIsError(false);
      }
    }
  }, [props.user.success]);

  useEffect(() => {
    const clearErrors = () => {
      setFormErrors({
        ...formErrors,
        ReportingTo: { cls: "", msg: "" },
        groups: { cls: "", msg: "" },
        // Add other form errors as needed
      });
    };

    const timeoutId = setTimeout(clearErrors, 100); // Adjust the delay as needed (e.g., 100 milliseconds)

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [values.ReportingTo, values.groups]);

  //set user type and orgr id based on logged in user
  //get user list for reporting to if logged in user is orgranization admin
  useEffect(() => {
    if (props.user.empData.EmpGuid) {
      setValues((previousValues) => ({
        ...previousValues,
        ["ActionBy"]: props.user.empData.EmpGuid,
      }));
      if (props.user.empData.UserTypeId == 3) {
        setValues((previousValues) => ({
          ...previousValues,
          ["UserTypeId"]: 2,
        }));
      } else if (props.user.empData.UserTypeId == 2) {
        setValues((previousValues) => ({
          ...previousValues,
          ["UserTypeId"]: 1,
        }));
        setValues((previousValues) => ({
          ...previousValues,
          ["OrgId"]: props.user.empData.OrgId,
        }));
        isCompanyDisabled = true;
        //get user list for reporting to if logged in user is orgranization admin
        var emps = [];
        emps.push(props.user.empData);
        if (props.user.empList) {
          emps = [...emps, ...props.user.empList];
        }
        console.log("emps", emps);
        setOptionsReportingTo(
          emps
            .filter(
              (emp) =>
                emp.EmailConfirmed &&
                !emp.IsDeleted &&
                emp.OfficeEmail != values.Email
            )
            .map((emp) => ({
              value: emp.EmployeeId,
              label: emp.FirstName + " " + emp.LastName,
            }))
        );
        getOrgGroups(props.user.empData.OrgId).then((groups) => {
          //setGroups(groups.map((group: any) => ({ value: group.BatchGroupId, label: group.BatchName })));
          setGroups(
            groups
              .filter((group: any) => {
                return group.IsDeleted == false;
              })
              .map((group: any) => ({
                value: group.BatchGroupId,
                label: group.BatchName,
              }))
          );
        });
        // getOrgDesignations(orgGuid).then((groups) => {
        //   setDesignationsListOptions(
        //     groups
        //     .map((group: DesignationsList) => ({
        //       value: group.designation_id,
        //       label: group.designation_name,
        //     }))
        //   )
        // });

      }
    }
  }, [props.user.empData]);

  useEffect(() => {
    if (props.user.empList?.length > 0) {
      let emps = [];
      if (props.user.empData) {
        emps.push(props.user.empData);
      }
      if (props.user.empList) {
        emps = [...emps, ...props.user.empList];
      }

      const filteredOptions = emps
        .filter(
          (emp) =>
            emp.EmailConfirmed &&
            !emp.IsDeleted &&
            emp.OfficeEmail !== values.Email
        )
        .map((emp) => ({
          value: emp.EmployeeId,
          label: `${emp.FirstName} ${emp.LastName}`,
        }))
        .filter((emp) => emp.value !== values.ReportingTo); // Exclude the selected ReportingTo from options

      setOptionsReportingTo(filteredOptions);
    }
  }, [props.user.empList, props.user.empData, values.ReportingTo, values.Email]);
  console.log(optionsReportingTo)

  useEffect(() => {
    _getOptionGroups();
  }, [groups, values.groups]);

  function getEmployee(userGuid: any): any {
    if (props.user.empList) {
      const empData = props.user.empList.find(
        (emp: any) => emp.UserGuid === userGuid
      );
      return empData;
    }
    return undefined;
  }

  const handlePhoneChange = (Pvalue: any) => {
    const value = Pvalue.value;
    setValues((values) => ({
      ...values,
      "PhoneNumber": value,
    }));

    if (!value || value.length < 10 ) {
      setFormErrors({
        ...formErrors,
        "PhoneNumber": { cls: "is-invalid", edited: true },
      });
    } else {
      setFormErrors({
        ...formErrors,
        "PhoneNumber": { cls: "is-valid", edited: true },
      });
    }
  }
  var pwdRegEx = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])/;
  const handleChange = (e: any) => {
    //console.log(e.target.name, e.target.value);
    e.persist();
    e.target.value = e.target.value.trimStart();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    let value = e.target.value;
    switch (e.target.name) {
      case "Email": {
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setFormErrors({
          ...formErrors,
          Email: { cls: emailValid ? "is-valid" : "is-invalid", edited: true },
        });
        break;
      }
      case "PhoneNumber": {
        if (!value || value.length === 0) {
          setFormErrors({
            ...formErrors,
            [e.target.name]: { cls: "is-invalid", edited: true },
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: { cls: "is-valid", edited: true },
          });
        }
        break;
      }
      case "FirstName": {
        // const validFirstname = value.match(/^([A-Za-z ])+$/);
        if (!value || value.length === 0) {
          setFormErrors({
            ...formErrors,
            FirstName: {
              cls: value.length > 0 ? "is-valid" : "is-invalid",
              edited: true,
            },
          });
        } else {
          setFormErrors({
            ...formErrors,
            FirstName: {
              cls: value.length > 0 ? "is-valid" : "is-invalid",
              edited: true,
            },
          });
        }
        break;
      }

      case "LastName": {
        // const validLastname = value.match(/^([A-Za-z ])+$/);
        if (!value || value.length === 0) {
          setFormErrors({
            ...formErrors,
            LastName: {
              cls: value.length > 0 ? "is-valid" : "is-invalid",
              edited: true,
            },
          });
        } else {
          setFormErrors({
            ...formErrors,
            LastName: {
              cls: value.length > 0 ? "is-valid" : "is-invalid",
              edited: true,
            },
          });
        }
        break;
      }
      /*case "CompanyName": {
        if (!value || value.length === 0) {
          setFormErrors({ ...formErrors, [e.target.name]: { cls: "is-invalid", edited: true } });
        } else {
          setFormErrors({ ...formErrors, [e.target.name]: { cls: "is-valid", edited: true } });
        }
      }*/
      case "OrgId": {
        if (!value || value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: { cls: "is-invalid", edited: true },
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: { cls: "is-valid", edited: true },
          });
        }
      }
      default:
        break;
    }
  };

  const _handleChange = (option: any) => {
    setValues((values) => ({
      ...values,
      ["OrgId"]: option.value,
    }));  
      setApiStatus("");
      setIsError(false);
    console.log("option", option);
  };
  const _getOption = (orgId: any) => {
    if (options && orgId)
      return options.find((emp: any) => emp.value === orgId);
    else return undefined;
  };

  const _handleChangeReportingTo = (option: any) => {
    setValues((prevValues) => ({
      ...prevValues,
      ReportingTo: option ? option.value : undefined,
    }));
  };

  // const _handleChangeReportingTo = (option: any) => {
  // 	if (option && option.value) {
  // 		setValues(values => ({
  // 			...values,
  // 			["ReportingTo"]: option.value
  // 		}));
  // 	}
  // }

  const _getOptionReportingTo = (reportingTo: any) => {
    if (reportingTo) {
      const allEmps = props.user.empList.concat(props.user.empData ? [props.user.empData] : []);
      const selectedEmp = allEmps.find((emp: any) => emp.EmployeeId === reportingTo);
      if (selectedEmp) {
        return {
          value: selectedEmp.EmployeeId,
          label: `${selectedEmp.FirstName} ${selectedEmp.LastName}`
        };
      }
    }
    return undefined;
  };



  const signupHandler = (e: any) => {
    e.preventDefault();
    let errrs = [];
    let required = [];
    setApiStatus("");
    if (!values.FirstName || values.FirstName.length === 0) {
      //setFormErrors({ ...formErrors, 'FirstName': { cls: 'is-invalid', edited: true } });
      //formErrors['FirstName'] = "FirstName cannot be empty";
      formErrors["FirstName"] = { cls: "is-invalid", edited: true };
      errrs.push("First Name");
    }
    if (!values.LastName || values.LastName.length === 0) {
      //setFormErrors({ ...formErrors, 'LastName': { cls: 'is-invalid', edited: true } });
      //formErrors['LastName'] = "LastName cannot be empty";
      formErrors["LastName"] = { cls: "is-invalid", edited: true };
      errrs.push("Last Name");
    }
    /*if (!values.CompanyName || values.CompanyName.length === 0) {
      setFormErrors({ ...formErrors, 'CompanyName': { cls: 'is-invalid', edited: true } });
      //formErrors['CompanyName'] = "CompanyName cannot be empty";
      errrs.push('Company Name');
    }*/
    if (!values.OrgId || values.OrgId === 0) {
      //setFormErrors({ ...formErrors, 'OrgId': { cls: 'is-invalid', edited: true } });
      //formErrors['CompanyName'] = "CompanyName cannot be empty";
      formErrors["OrgId"] = { cls: "is-invalid", edited: true };
      required.push("Company Name");
    }
    if (!values.Email || values.Email.length === 0) {
      //setFormErrors({ ...formErrors, 'Email': { cls: 'is-invalid', edited: true } });
      formErrors["Email"] = { cls: "is-invalid", edited: true };
      errrs.push("Email");
    } else {
      const emailValid = values.Email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      //formErrors['Email']  = emailValid ? '' : 'Email is invalid';
      if (!emailValid) {
        errrs.push("Email");
        formErrors["Email"] = { cls: "is-invalid", edited: true };
        //setFormErrors({ ...formErrors, 'Email': { cls: emailValid ? '' : 'is-invalid', edited: true } });
      }
    }
    if (!values.PhoneNumber || values.PhoneNumber.length < 10) {
      formErrors["PhoneNumber"] = { cls: "is-invalid", edited: true };
      errrs.push("Phone Number");
    }
    if (props.user.empData && props.user.empData.UserTypeId == 2) {
      // if (!values.ReportingTo) {
      // 	setFormErrors({
      // 		...formErrors,
      // 		ReportingTo: { cls: 'error', msg: 'Please select a reporting manager' },
      // 		// Add other form errors as needed
      // 	});
      // 	return;
      // } else {
      // 	setFormErrors({
      // 		...formErrors,
      // 		ReportingTo: { cls: '', msg: '' },
      // 	});
      // }
      // if (!sltGroups || sltGroups.length === 0) {
      // 	setFormErrors({
      // 		...formErrors,
      // 		groups: { cls: 'error', msg: 'Please select at least one group' },
      // 	});
      // 	return;
      // }
      // }else{
      // 	setFormErrors({
      // 		...formErrors,
      // 		groups: { cls: '', msg: null },
      // 	});
      // }
    }

    values.groups = sltGroups.map((x) => x.value).join(",");
    //console.log("values-before-user-creation",values);
    if (errrs.length == 0 && required.length == 0) {
      formRef.current = true;
      props.signupAction(values, props.history);
    } else {
      var error: string = "";
      if (errrs.length > 1) error = errrs.join(", ") + " are invalid!";
      else if (errrs.length == 1) error = errrs[0] + " is invalid!";
      if (required.length > 0)
        error = "Please select " + required.join(", ") + "!\n\r" + error;
      setApiStatus(error);
      setIsError(true);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const AddOrg = (orgDataUpload: any) => {
    if (props.user.orgList) {
      // & props.user.createOrgResponse)
      props.user.orgList.push({
        OrgId: orgDataUpload.result[2],
        OrgName: orgDataUpload.result[3],
      });
      //setOptions(props.user.orgList.map( (org:any) => ({ value: org.OrgId, label: org.OrgName })));
      setOptions([
        ...options,
        { value: orgDataUpload.result[2], label: orgDataUpload.result[3] },
      ]);
      //make select newly added company
      setValues({ ...values, ["OrgId"]: orgDataUpload.result[2] });
      dispatch(UpdateuiComponentResponse("UploadOrgDataResponse") as any);
      handleClose();
    }
  };

  const handleShowResetPassword = (e: any) => {
    setShowConfirmResetPassword(!showConfirmResetPassword);
  };
  const handleClosePopupResetPassword = (e: any) => {
    setShowConfirmResetPassword(!showConfirmResetPassword);
  };
  const sendEmail = (e: any) => {
    props.SendForgetPasswordEmail(values.Email);
    setShowConfirmResetPassword(!showConfirmResetPassword);
  };
  const _handleChangeGroup = (option: any) => {
    console.log("Selected Option in groups:", option);
    setFormErrors({
      ...formErrors,
      groups: { cls: "", msg: null },
    });
    setSltGroups(option);
  };

  const _handleChangeDesignation = (options: any) => {
    setValues((prevValues: SignupProps) => {
      return {
        ...prevValues,
        Designation_Id:
          options && options.value !== undefined ? options.value : undefined,
      };
    });
  }


  const selectedOptionDesignation = designationListOptions.find((option: any) => option.value === values.Designation_Id);

  const _getOptionGroups = () => {
    var valArr = [] as any;
    if (values.groups && values.groups.length > 0) {
      let arr = values.groups.split(",");
      arr.forEach((val) => {
        let grp = groups.filter((group: any) => group.value == val);

        if (grp && grp.length > 0) {
          valArr.push(grp[0]);
        }
      });
      setSltGroups(valArr);
    } else setSltGroups([]);
  };


  return (
    <>
      <ConfirmPopup
        show={showConfirmResetPassword}
        close={handleClosePopupResetPassword}
        okHandle={sendEmail}
        message="Do you want to reset password?"
      />
      <section className="myProfilePage  position-relative  h-100">
        <div className="container align-self-center h-100">
          <div className="row">
            <div className="col-sm-8 m-auto">
              {apiStatus ? (
                <p
                  style={{ whiteSpace: "break-spaces" }}
                  className={clsx(
                    "alert mt-2 ",
                    isError ? "alert-danger" : "alert-success"
                  )}
                >
                  {apiStatus}
                </p>
              ) : (
                ""
              )}
              <div className="cRoomHeader mt-4">
                <div className="cRsuteLogo align-self-center">
                  <Link to="/users">
                    <img src="/assets/media/images/logo.png" />
                  </Link>
                </div>
                <div className="cRTitle">
                  <h5>
                    {props.user.empData && props.user.empData.UserTypeId == 3
                      ? t("user.adminuser")
                      : t("user.user")}
                  </h5>
                  <p></p>
                </div>
              </div>
              <form onSubmit={signupHandler}>
                <div className="card mt-2 loginBox">
                  <div className="card-body pl-5 pr-5">
                    <div className="row">
                      <div className="col-sm-12 m-0">
                        <div className="form-group col-sm-12 p-0 m-0">
                          <label className="text-light small m-0">
                            {t("signup.company_name")} <sup>*</sup>
                          </label>
                          {/* <input onChange={handleChange} autoComplete="off"
													className={"form-control " + (formErrors['CompanyName'] && formErrors['CompanyName'].cls ? formErrors['CompanyName'].cls : '')} type="text" name="CompanyName" placeholder={t('signup.company_name')} minLength={2} maxLength={50} required />  */}
                          <div className="col-12 row m-0 p-0 mt-2 mb-2">
                            <div className="col-sm-11 p-0">
                              <Select
                                isDisabled={isCompanyDisabled}
                                options={options}
                                value={_getOption(values.OrgId)}
                                onChange={_handleChange}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      formErrors["OrgId"] &&
                                        formErrors["OrgId"].cls
                                        ? "red"
                                        : "grey",
                                  }),
                                }}
                              ></Select>
                            </div>
                            <div className="col-sm-1 text-right p-0 m-auto">
                              {isCompanyDisabled ? undefined : (
                                <button
                                  className="add-btn-transparent"
                                  type="button"
                                  onClick={handleShow}
                                  title="Add Company"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#fff"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    {" "}
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>{" "}
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-light small m-0">
                            {t("signup.first_name")} <sup>*</sup>
                          </label>
                          <input
                            value={values.FirstName || ""}
                            onChange={handleChange}
                            className={
                              "form-control " +
                              (formErrors["FirstName"] &&
                                formErrors["FirstName"].cls
                                ? formErrors["FirstName"].cls
                                : "")
                            }
                            type="text"
                            name="FirstName"
                            // placeholder={t("signup.first_name")}
                            autoComplete="off"
                            minLength={1}
                            maxLength={50}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-light small m-0">
                            {t("signup.last_name")} <sup>*</sup>
                          </label>
                          <input
                            value={values.LastName || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            className={
                              "form-control " +
                              (formErrors["LastName"] &&
                                formErrors["LastName"].cls
                                ? formErrors["LastName"].cls
                                : "")
                            }
                            type="text"
                            name="LastName"
                            // placeholder={t("signup.last_name")}
                            minLength={1}
                            maxLength={50}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-light small m-0">
                            {t("myprofile.email")} <sup>*</sup>
                          </label>
                          <input
                            disabled={isEmailDisabled}
                            value={values.Email || ""}
                            onChange={handleChange}
                            autoComplete="Off"
                            className={
                              "form-control " +
                              (formErrors["Email"] && formErrors["Email"].cls
                                ? formErrors["Email"].cls
                                : "")
                            }
                            type="email"
                            name="Email"
                            // placeholder={t("myprofile.email")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-light small m-0">
                            {t("signup.phone_number")} <sup>*</sup>
                          </label>

                          <PatternFormat
                            format="###-###-####"
                            name="PhoneNumber"
                            className={
                              "form-control " +
                              (formErrors["PhoneNumber"] &&
                              formErrors["PhoneNumber"].cls
                                ? formErrors["PhoneNumber"].cls
                                : "")
                            }
                            required
                            minLength={10}
                            value={values.PhoneNumber || ""}
                            onValueChange={handlePhoneChange} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-light small m-0">
                            {'Function'} <sup>*</sup>
                          </label>
                          <input
                            value={values.Designation || ""}
                            onChange={handleChange}
                            autoComplete="Off"
                            className={
                              "form-control " +
                              (formErrors["Designation"] && formErrors["Designation"].cls
                                ? formErrors["Designation"].cls
                                : "")
                            }
                            type="text"
                            name="Designation"
                            // placeholder={'Designation'}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 text-center">
                      <label className="text-light small">
                        NOTE: All the above fields are mandatory
                      </label>
                    </div> */}
                    {props.user.empData &&
                      props.user.empData.UserTypeId == 2 ? (
                      <div className="row">
                        <div className="col-sm-6 m-0">
                          <div className="form-group col-sm-12 p-0 m-0">
                            <label className="text-light small m-0">
                              {t("signup.reporting_to")}
                            </label>
                            <div className="col-12 row m-0 p-0 mt-2 mb-2">
                              <div className="col-sm-11 p-0">
                                <Select
                                  options={optionsReportingTo}
                                  onChange={_handleChangeReportingTo}
                                  value={_getOptionReportingTo(
                                    values.ReportingTo
                                  )}
                                  isClearable={true} // This allows users to clear the selected option
                                // Remove the styles object that applies validation styling
                                ></Select>
                              </div>
                            </div>
                            {/* <div className="col-12 row m-0 p-0 mt-2 mb-2">
														<div className="col-sm-11 p-0">
															<Select options={optionsReportingTo}
																onChange={_handleChangeReportingTo}
																value={_getOptionReportingTo(values.ReportingTo)}
																isClearable={true} // This allows users to clear the selected option
																styles={{
																	control: (baseStyles, state) => ({
																		...baseStyles,
																		borderColor: formErrors['ReportingTo'] && formErrors['ReportingTo'].cls ? 'red' : 'grey',
																	}),
																}}
															></Select>
														</div>
													</div> */}
                            {/* {formErrors['ReportingTo'] && (
														<div className="text-danger small">{formErrors['ReportingTo'].msg}</div>
													)} */}
                          </div>
                        </div>
                        <div className="col-sm-6 m-0">
                          <div className="form-group col-sm-12 p-0 m-0">
                            <label className="text-light small m-0">
                              {"Teams"}
                            </label>
                            <div className="col-12 row m-0 p-0 mt-2 mb-2">
                              <div className="col-sm-11 p-0">
                                <Select
                                  isMulti
                                  options={groups}
                                  onChange={_handleChangeGroup}
                                  value={sltGroups}
                                  isClearable={true} // This allows users to clear the selected options
                                // styles={{
                                // 	control: (baseStyles, state) => ({
                                // 		...baseStyles,
                                // 		borderColor: formErrors['groups'] && formErrors['groups'].cls ? 'red' : 'grey',
                                // 	}),
                                // }}
                                ></Select>
                              </div>
                            </div>
                            {/* {formErrors['groups'] && (
														<div className="text-danger small">{formErrors['groups'].msg}</div>
													)} */}
                          </div>
                        </div>
                        {/* <div className="col-sm-6 m-0">
                          <div className="form-group col-sm-12 p-0 m-0">
                            <label className="text-light small m-0">
                              {"Designation"}
                            </label>
                            <div className="col-12 row m-0 p-0 mt-2 mb-2">
                              <div className="col-sm-11 p-0">
                                <Select
                                  options={designationListOptions}
                                  onChange={_handleChangeDesignation}
                                  value={selectedOptionDesignation}
                                  isClearable={true}
                                ></Select>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <label className="text-light medium m-0"  > NOTE: All the above fields are mandatory</label>
									<div className="form-group mt-4 text-center">
										<button type="submit" className="btn btn-SeaGreen font-weight-bold pl-5 pr-5" disabled={props.user?.signupResponse?.message === "Success" ? true : false} >{t('signup.save')}
 										</button>										 
									</div> */}
                    <div className="align-items-center">
                      <div className="form-group mt-4 text-center">
                        <button
                          type="submit"
                          className="btn btn-SeaGreen font-weight-bold pl-5 pr-5"
                          disabled={
                            props.user?.signupResponse?.message === "Success"
                              ? true
                              : false
                          }
                        >
                          {t("signup.save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title className="modal-title text-light small">
              Add Company
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SetupComponent DataSubmited={AddOrg} />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.AuthReducer,
  UI: state.uiReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  signupAction: (stateobj: any, history: any) => {
    dispatch(signupAction(stateobj));
  },
  loginSubmit: (stateobj: any, history: any) => {
    dispatch(Login(stateobj, history));
  },
  getOrgList: (params: FilesTableBody, isDisabled: any) => {
    dispatch(getOrgList(params, isDisabled));
  },
  SendForgetPasswordEmail: (email: string) => {
    dispatch(SendForgetPasswordEmail(email));
  },
  RemoveSuccessStatus: () => {
    dispatch(removeSuccessStatus());
  },
  getEmpDetailsByEmpGuid: (empGuid: any) => {
    dispatch(getEmpDetailsByEmpGuid(empGuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
