import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../i18n';
import { connect } from "react-redux";
import { useNavigate} from 'react-router-dom';
const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
});

function SettingsRightMenu(props: any) {
    console.log(props)
    return (
        <div className="col-sm-4 mobile-none">
            <ul className="settingsMenu OnboardMenu" id="OBhexGrid">
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                {props.user && props.user.UserTypeId == 1 ?  
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li> : 
                <li className={`OBhex ${props.path == 'companySetup' ? 'active' : ''}`}>
                    <Link className="OBhexIn" to="/companyInfo" state = {props.from }>
                        <p> <span className="align-self-center">
                            <img src="assets/media/svg/onboarding/company.svg" style={{ padding: '5px' }} />
                            <u className="OBhexTitle">{t('signup.company_profile')}</u> </span> </p>
                    </Link>
                </li>}
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className={`OBhex ${props.path == 'calendarIntegration' ? 'active' : ''}`}>
                    <Link to={props.from}  className="OBhexIn">
                        <p> <span className="align-self-center">
                            <img src="/assets/media/images/logo.png" />
                        </span>
                        </p>
                    </Link>
                </li>
                <li className={`OBhex ${props.path == 'profileImage' ? 'active' : ''}`}>
                    <Link className="OBhexIn" to="/userProfile" state = {props.from }>
                        <p> <span className="align-self-center">
                            <img src="assets/media/svg/onboarding/avatar-setup.svg" />
                            <u className="OBhexTitle">{t('signup.employee_profile')}</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                {/* <li className={`OBhex ${props.path == 'intialSettings' ? 'active' : ''}`}>
                    <a className="OBhexIn">
                        <p> <span className="align-self-center">
                            <img src="assets/media/svg/onboarding/audio-video.svg" />
                            <u className="OBhexTitle">{t('button.settings')}</u> </span> </p>
                    </a>
                </li> */}
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" >
                        <p> <span className="align-self-center">
                            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 7V1h1v6h6v1H8v6H7V8H1V7h6z" fill="#6264a5"></path>
                            </svg>
                        </span> </p>
                    </a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" ></a>
                </li>
            </ul>
        </div>
    );
}

export default connect(mapStateToProps)(SettingsRightMenu);