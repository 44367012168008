import {postRequest, getRequest} from "../utils/http";
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;
const getEmployeeGoals =async (empGuid:any) => {
    try {  
        
     return getRequest(`${api_domian}${api_port}/api/Wellness/GetEmployeeGoals?empGuid=`+empGuid)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }
 //get Metrics
 const getMetrics =async () => {
    try {
        return getRequest(`${api_domian}${api_port}/api/Wellness/GetMetric`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
                return "ERROR";
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }
 //UpdateEmployeeGoals
 const SaveEmployeeGoals  = async (params: any) => {
    try {
        return postRequest(`${api_domian}${api_port}/api/Wellness/SaveEmployeeGoals`,params)
            .then((resp: any) => {
                if(resp.status == 'success'){
                    return resp.status
                }
            }).catch(e => {
                return "Error";
            });
    } catch (e) {
        return "Error";
    }
};
const GetGoals = async(empGuid:any) => {
     try {       
     return   getRequest(`${api_domian}${api_port}/api/Wellness/GetGoals?empGuid=`+empGuid)
        .then((resp: any)=>{
             return resp
         }).catch(e=>{
             console.log(e);
             
                 return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         
         return "ERROR";
     }
  }
  const GetChallenges = async(myOrgId:any) => {
     try {
         
     return getRequest(`${api_domian}${api_port}/api/Wellness/GetChallenges?orgGuid=`+ myOrgId)
        .then((resp: any)=>{
             return resp
         }).catch(e=>{
             console.log(e);
             
                 return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         
         return "ERROR";
     }
  }
  const SaveStepChallenge  = async (params: any) => {
    try {
        return postRequest(`${api_domian}${api_port}/api/Wellness/SaveStepChallenge`,params)
            .then((resp: any) => {
                if(resp.status === 'success'){
                    return resp.status
                }
            }).catch(e => {
                return "Error";
            });
    } catch (e) {
        return "Error";
    }
};
const GetStepChallenges = async(empGuid:any,challengeGuid : any,stepId : number) => {
     try {
         
     return   getRequest(`${api_domian}${api_port}/api/Wellness/GetStepChallenges?empGuid=${empGuid}&challengeGuid=${challengeGuid}&stepId=${stepId}`)
        .then((resp: any)=>{
             return resp
         }).catch(e=>{
             console.log(e);
             
                 return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         
         return "ERROR";
     }
  }

const GetOngoingChallenges = async(orgGuid:string, stepId : number) => {
     try {
     return getRequest(`${api_domian}${api_port}/api/Wellness/GetOngoingChallengs?orgGuid=${orgGuid}&stepId=${stepId}`)
        .then((resp: any)=>{
             return resp;
         }).catch(e=>{
            console.log(e);
                return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         return "ERROR";
     }
  }

const SaveOngoingStatus = async(params: any) => {
     try {
     return postRequest(`${api_domian}${api_port}/api/Wellness/SaveOngoingStatus`, params)
        .then((resp: any)=>{
             return resp;
         }).catch(e=>{
            console.log(e);
                return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         return "ERROR";
     }
  }

  const GetOngoingStepChallengs = async(orgGuid:string) => {
    try {
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetOngoingStepChallengs?orgGuid=${orgGuid}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const GetCompletedChalleges = async(orgGuid:string , stepId: number) => {
    try {
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetCompletedChalleges?orgGuid=${orgGuid}&stepId=${stepId}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const GetLeaderBoard = async(orgGuid:string , stepId: number) => {
    try {
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetLeaderBoard?orgGuid=${orgGuid}&stepId=${stepId}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const GetChallengesStepList = async(myOrgId:any) => {
    try {
        
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetChallengesStepList?orgGuid=`+ myOrgId)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

 const GetMediaTracksList = async(orgGuid :any) => {
    try {
        
    return getRequest(`${api_domian}${api_port}/api/Media/GetMediaContent?orgGuid=${orgGuid}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

 const GetTracksListed = async(mediaGuid :any) => {
    
    try {
        
    return getRequest(`${api_domian}${api_port}/api/Media/GetSoundTracksListed?MediaTypeGuid=${mediaGuid}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

 const GetChatMessages = async(ID :any) => {    
    try {
        
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetChallengeChatMessages?stepId=${ID}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

 const SaveChallengeChatMessages = async(body: any) => {
    try {
    return postRequest(`${api_domian}${api_port}/api/Wellness/SaveChallengeChatMessage`, body)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const GetNewChallenges = async(orgGuid :any) => {    
    try {
        
    return getRequest(`${api_domian}${api_port}/api/Wellness/GetNewChallengesList?orgGuid=${orgGuid}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

 const SavestatusChallenges = async(stepId: number) => {
    try {
    return postRequest(`${api_domian}${api_port}/api/Wellness/SavestatusChallenges?stepId=${stepId}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const SaveNewChallenge = async(params: any) => {
    try {
    return postRequest(`${api_domian}${api_port}/api/Wellness/SaveNewChallenge`,params)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
           console.log(e);
               return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 const GetCommingSoon = async(screenId :any,emojiname : any,count: number) => {  
    debugger  
    try {      
    return getRequest(`${api_domian}${api_port}/api/Employee/GetComingSoon?screenid=${screenId}&emojiname=${emojiname}&emojicount=${count}`)
       .then((resp: any)=>{
        debugger
            return resp
        }).catch(e=>{
            console.log(e);            
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        
        return "ERROR";
    }
 }

export {
    SaveEmployeeGoals,
    getEmployeeGoals,
    getMetrics,
    GetGoals,
    GetChallenges,
    SaveStepChallenge,
    GetStepChallenges,
    GetOngoingChallenges,
    SaveOngoingStatus,
    GetOngoingStepChallengs,
    GetCompletedChalleges,
    GetLeaderBoard,
    GetChallengesStepList,
    GetTracksListed,
    GetMediaTracksList,
    GetChatMessages,
    SaveChallengeChatMessages,
    GetNewChallenges,
    SavestatusChallenges,
    SaveNewChallenge,
    GetCommingSoon
}