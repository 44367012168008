import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, } from 'react-redux';
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { GetPreferredContacts, removeSuccessStatus, updateRtmStatus } from '../../services/ContactsService';
import * as T from "../../actions/Types";
import { t } from '../../i18n';
import ProfileView from './ProfileView';
import PreCallPopup from './PreCallPopup';
import { globalStore } from '../../store/global_store';
import { StoreClass } from "../../store/Store";
import UserChatComponent from "../../components/UserChatComponent";
import TimeSlotPopup from "./TimeSlotPopup";

const statusCls = {
    "Available": "active", "Away": 'away', "In Office": 'inoffice',
    "Busy": 'busy', "Offline": 'offline'
};

function Contacts(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [selectUser, setSelectedUser] = useState({ EmployeeId: 0, FirstName: '', LastName: '', ProfileImageURL: '', EmpGuid: '', OfficeEmail: '', Mobile: '', status:''});
    // const [userStatus, setUserStatus] = useState<any>({});
    const [showSchedule, setShowSchedule] = useState(false);

    let orgId = localStorage.getItem('OrgGuid') || '';
    const empId = localStorage.getItem('EmpGuid') || '';
    let currentId = localStorage.getItem('EmpId') || 0 as number;
    const [openChat, setOpenChat] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openCall, setOpenCall] = useState(false);
    const dispatch = useDispatch();
    const lock = useRef(false);
    const [prefcontacts, setPrefcontacts] = useState<any[]>([]);
    const history = useNavigate();
    const tmuserInfo = useRef<any>(0);
    const [usrStatus, setUsrStatus] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const getContacts = () => {
        dispatch(GetPreferredContacts(empId) as any)
    }
    const setLoading = () => async (dispatch: Dispatch) => {
        dispatch({
            type: T.ADD_LOADING
        })
    }
    useEffect(() => {
        // chatClient.login(myid || '');
        // if (!lock.current && (!user.prefcontacts || user.prefcontacts.length === 0)) {
        setLoading();
        getContacts();
        tmuserInfo.current = setInterval(getContacts, 5000);
        //    lock.current = true;
        // }
        return () => {
            clearInterval(tmuserInfo.current);
        }

    }, []);

    useEffect(() => {
        if (user.success && user.success === 'RemovedPreferredContact') {
            globalStore.showToast({
                type: 'Message',
                title: "Removed Favorite Contact",
                message: "Favorite Unmarked successfully"
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(GetPreferredContacts(empId) as any);
        }
    }, [user.success]);

    useEffect(() => {
        dispatch({
            type: T.REMOVE_LOADING
        })
        if (searchValue?.length > 0) {
            const prefcontacts = user.prefcontacts?.filter((prefcontact: any) =>
                (prefcontact.FirstName + ' ' + prefcontact.LastName).toLowerCase().includes(searchValue))
            setPrefcontacts(prefcontacts as any);
        }
        else {
            setPrefcontacts(user.prefcontacts as any);
        }
    }, [user.prefcontacts]);

    const toggleSchedule = () => {
        console.log("========================================== SHOW ========================", showSchedule);
        setShowSchedule(!showSchedule)
    }
    // chatClient.onStatusUpdate = (msg: any) => {
    //     if (msg && Object.keys(msg).length > 0) {
    //         let statusAr = { ...msg };
    //         dispatch(updateRtmStatus(statusAr) as any);
    //     }
    // };
    const getUserProfile = (event: any) => {
        setUsrStatus((event.IsOnline) ? 'Online' : 'Offline');
        let eid = event.EmpGuid;
        getUserDetails(eid);
        setOpenProfile(true);
    }
    const startChat = (eid: any) => {
        setOpenProfile(false);
        setOpenChat(true);
        if (eid === selectUser.EmpGuid) return;
        if (!user.prefcontacts) return;
        const selectedUserAr = user.prefcontacts.filter((account: any) => { if (account.EmpGuid === eid) return account; })
        setSelectedUser(selectedUserAr[0]);
    }

    const getUserDetails = (eid: any) => {
        setOpenProfile(false);
        if (eid === selectUser.EmpGuid) return;
        if (!user.prefcontacts) return;
        const selectedUserAr = user.prefcontacts.filter((account: any) => { if (account.EmpGuid === eid) return account; })
        setSelectedUser(selectedUserAr[0]);
    }

    const closeChat = () => {
        setOpenChat(false);
    }
    const closeProfile = () => {
        setOpenProfile(false);

    }

    const sendCallReq = (type: string, emid: string) => {
        // // if (openCall) {
        // //     alert("Are you want to end the call?");
        // //     return false;
        // // }
        console.log('#OPENCALL sendCallReq-contacts',user.openCall,user.selectedUser); 

       /// getUserDetails(emid);
        // SaveMyActivity({ Type: "CALL", FromId: empId, ToId: emid, OrgGuid: orgId, Status: "CALLING" }).then((res) => {
        //    tmCallInfo.current = setInterval(GetCallDetails, 5000);
        // });

        dispatch({
            type: T.SELECTED_USER,
            payload: emid
        })

        dispatch({
            type: T.OPEN_CALL,
            payload: true
        })
       /// setOpenCall(true);
        setOpenProfile(false);
        setOpenChat(false);
    }

    const cancelCallLocal = () => {
        setOpenCall(false);

    }
    const staticStatus = [
        { id: "1", color: "green", title: "Available" },
        { id: "2", color: "orange", title: "Away" },
        // { id: "3", color: "#04f1b2", title: "In Office" },
        { id: "4", color: "red", title: "Busy" }
    ]
    const getStatus = (isOnline: any, status: any) => {

        if (isOnline) {
            status = status.length > 0 ? status : 'available';
            const sts = staticStatus.filter((s: any) => s.title.toLowerCase() === status.toLowerCase());
            if (sts.length > 0)
                return sts[0].color;
        }
        return 'gray';
        //user.contacts

    }
    function onSearch(e: any) {
        setSearchValue(e.target.value)
        const prefcontacts = user.prefcontacts?.filter((prefcontact: any) =>
            (prefcontact.FirstName + ' ' + prefcontact.LastName).toLowerCase().includes(e.target.value))
        setPrefcontacts(prefcontacts as any);
    }
    return (<>
        <div className={props.showContacts ? "SideNav SideNavLeft" : "SideNav SideNavLeft d-none"} id="ParticipantsSideNav">
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={props.handleShowContacts}>
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('title.close')} /> </div>
            </div>
            <div className="sideNavBody" id="ParticipantsList">
                <div className="row mt-4 small">
                    <div className="col-12 mb-2 text-light font-weight-bold">{t('contacts.contacts')}
                        {/* <Link to="/earn-learn/deada461-4b95-477a-bc45-02d5345f681d">
                            <svg className="float-right c-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#fff" /></svg>
                        </Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 small ParticipantsSearch">
                        <input className="SuteInputPrimary" value={searchValue} type="search" placeholder="Search" onChange={e => onSearch(e)} /> </div>
                </div>
                <div className="row mt-3 small ParticipantsAvatars" style={{ "overflow": "auto", height: "80%", alignContent: "flex-start" }}>
                    {prefcontacts && prefcontacts.map((event: any) => {                    
                        return (
                            <div className="col-sm-6 text-center mb-3" title={(event.IsOnline)? (event.status.length > 0 ?  event.status : 'Available') : 'Offline'} key={event.EmpGuid}>
                                <svg className={"ParticipantImage c-pointer m-auto " + (event.IsOnline ? (event.status.length > 0 ?  event.status.toLowerCase(): 'available') : 'Offline')} viewBox="0 0 100 100" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" onClick={() => getUserProfile(event)}>
                                    <defs>
                                        <pattern id={event.EmpGuid} patternUnits="userSpaceOnUse" width="100" height="100">
                                            <image xlinkHref={event.ProfileImageURL ? event.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />
                                        </pattern>
                                    </defs>
                                    <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${event.EmpGuid})`}></polygon>
                                </svg>
                                <p className="text-white mb-0"><span className="hexUserStatus mr-1" style={{ backgroundColor: getStatus(event.IsOnline, event.status) }}></span>{event.FirstName + ' ' + event.LastName}</p>
                                <div className="float-left">
                                    {empId !== event.EmpGuid ? <p className="ParticipantDesignation">
                                        <img className="c-pointer" title={t('chat.chat')} alt="Chat" onClick={() => startChat(event.EmpGuid)} src="/assets/media/svg/profile/message.png" width="25%" />
                                        {/* {getStatus(event.IsOnline, event.status) == 'gray' ? <img className="c-pointer  opacity05" title='Offline' alt="Chat" src="/assets/media/svg/profile/message.svg" width="25%" /> : <img className="c-pointer" title={t('chat.chat')} alt="Chat" onClick={() => startChat(event.EmpGuid)} src="/assets/media/svg/profile/message.svg" width="25%" />} */}
                                        {getStatus(event.IsOnline, event.status) == 'gray' ? <img className="c-pointer  opacity05" title='Offline' alt="Direct Call" src="/assets/media/svg/profile/call.png" width="25%" /> : <img className="c-pointer" title={t('chat.direct_call')} alt="Direct Call" onClick={() => sendCallReq('audio', event.EmpGuid)} src="/assets/media/svg/profile/call.png" width="25%" />}
                                        {/* { getStatus(event.EmpGuid) == 'gray' ? <img className="c-pointer  opacity05" title='Offline' alt="Video Call" src="/assets/media/images/collaborate/camera.svg" width="25%" />: <img className="c-pointer" title={t('chat.video_call')} alt="Video Call" onClick={() => sendCallReq('video', event.EmpGuid)} src="/assets/media/images/collaborate/camera.svg" width="25%" />} */}
                                    </p> : ''}
                                </div>
                                {user.messageCount[event.EmpGuid] && user.messageCount[event.EmpGuid] > 0 ? <div className="chatBubble">
                                    <span>{user.messageCount[event.EmpGuid]}</span>
                                </div> : ''}
                            </div>);
                    })}
                    {!user.prefcontacts || user.prefcontacts.length == 0 ? <div className="col-12 text-center text-light">No prefered contacts</div> : ''}
                </div>
            </div>
            <div className="sideNavFooter">
                <div className="row">
                    <div className="col-12 text-left text-light small ParticipantsPagination d-none">
                        <img className="c-pointer ml-3" src="/assets/media/svg/left-arrow.svg" width="30" height="30" /> 1/3
                        <img className="c-pointer" width="30" height="30" src="/assets/media/svg/right-arrow.svg" />
                    </div>
                </div>
            </div>
        </div>
        {openChat ? <UserChatComponent openChat={openChat} closeChat={closeChat}
            selEmpName={selectUser.FirstName + " " + selectUser.LastName}
            selEmpGuid={selectUser.EmpGuid} /> : ''}

        {openProfile ? <ProfileView audioCall={() => sendCallReq('audio', selectUser.EmpGuid || "")}
            videoCall={() => sendCallReq('video', selectUser.EmpGuid || "")}     
            openChat={() => { startChat(selectUser.EmpGuid || "") }}
            openProfile={openProfile} closeProfile={closeProfile} user={selectUser} toggleSchedule={toggleSchedule} /> : ''}

        {/* {openCall ? <PreCallPopup cancelCall={cancelCallLocal} type={'audio'}
            openCall={openCall} selectedUser={selectUser} /> : ''} */}
        {showSchedule ? <TimeSlotPopup EmpOfficeEmail={selectUser.OfficeEmail} EmpGuid={selectUser.EmpGuid} show={showSchedule} close={toggleSchedule} /> : ''}
    </>
    );
}


export default Contacts;