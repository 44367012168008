import { Loader } from 'react-bootstrap-typeahead';
import { StoreClass } from "../../store/Store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import {SaveFeedback, GetFeedbackTypes} from "../../services/ActivityServices";
import { globalStore } from '../../store/global_store';
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";
interface FeedbackProps {
    employeeGuid: string,
    orgGuid: string,
    feedBackTypeId: number,
    feedBackType: string,
    subject: string,
    description: string
}
function Feedback(props: any) {
    const empId = localStorage.getItem('EmpGuid') as string;
    const orgId = localStorage.getItem('OrgGuid') as string;
    const dispatch = useDispatch();
    const {user } = useSelector((state: StoreClass) => ({ user: state?.EmployeeReducer}));
    const [feedbackData, setFeebackData] = useState<FeedbackProps>({} as FeedbackProps);
    const [apiStatus, setApiStatus] = useState('');
    const [formErrors, setFormErros] = useState<any>({});
    const loadRef = React.useRef(false);
    const history = useNavigate();
    const [isCloseNav,setIsCloseNav] = useState(true)
    const [showComingsoon, setComingsoon] = useState(false);
    const [screenName,setScreenName] = useState("");
    const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')

    useEffect(() =>{
        console.log('feedback form', user.feedbackTypes);
        loadRef.current = false;
        if(!user.feedbackTypes || user.feedbackTypes.length ===0){
            dispatch(GetFeedbackTypes() as any);
        }
        setFeebackData({...feedbackData, employeeGuid:empId, orgGuid: orgId})
    },[]);

    useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

    const handleChange = (e: any) => {
        debugger
        setApiStatus('');
		e.persist();
        let feedbackValue = e.target.value.trim();
		setFeebackData((feedbackData: FeedbackProps) => ({
			...feedbackData,
			[e.target.name]: feedbackValue
		}));
		let value = e.target.value;
		switch (e.target.name) {			
			case "feedBackTypeId":
				if (!value || value === 0) {
					setFormErros({ ...formErrors, [e.target.name]: { cls: "is-invalid", edited: true } });
				} else {
					setFormErros({ ...formErrors, [e.target.name]: { cls: "is-valid", edited: true } });
				}
                const selOption = (user.feedbackTypes||[]).filter(fd=>fd.Id == value);
                if(selOption.length>0)
                    setFeebackData({...feedbackData, feedBackTypeId: value, feedBackType: selOption[0].Type});
                break;
			case "subject":
            case "description":
				if (!value || value.length == 0) {
					setFormErros({ ...formErrors, [e.target.name]: { cls: "is-invalid", edited: true } });
				} else {
					setFormErros({ ...formErrors, [e.target.name]: { cls: "is-valid", edited: true } });
				}
                break;
			default:
				break;
		}
	};
    const submitHandler = (e: any) => {	
		let errrs = [];
		setApiStatus("");
        if (!feedbackData.feedBackTypeId || feedbackData.feedBackTypeId == 0) {
			setFormErros({ ...formErrors, 'feedBackTypeId': { cls: 'is-invalid', edited: true } });
			errrs.push('Feedback Type');
		}
		// if (!feedbackData.subject || feedbackData.subject.length === 0) {
		// 	setFormErros({ ...formErrors, 'Subject': { cls: 'is-invalid', edited: true } });
		// 	errrs.push('Subject');
		// }
        if (!feedbackData.description || feedbackData.description.length === 0) {
			setFormErros({ ...formErrors, 'description': { cls: 'is-invalid', edited: true } });
			errrs.push('Description');
		}
	
		if (errrs.length == 0) {
            loadRef.current = true;
			SaveFeedback(feedbackData).then((resp: any)=>{
                if(resp.status === 'success') {
                    globalStore.showToast({
                        type: 'Message',
                        title: "Success",
                        message: "Feedback sent successfully"
                    });
                    history('/help-feedback');
                    loadRef.current = false;
                }else{
                    globalStore.showToast({
                        type: 'Error',
                        title: "Error",
                        message: "Error in sending Feedback, try after sometime"
                    });
                    loadRef.current = false
                }
            });
		} else {
            console.log(feedbackData);
			if (errrs.length > 1)
                setApiStatus("Please fill in the required fields");
				//setApiStatus(errrs.join(', ') + " are invalid!");
			else
                setApiStatus("Please fill in the required fields");
				//setApiStatus(errrs[0] + " is invalid!");
		} 
        e.preventDefault();
	}

    const handleClose = () =>{
       setIsCloseNav(!isCloseNav)
    }

    const cancelButton = () =>{
        setFeebackData({...feedbackData, feedBackTypeId: 0 , feedBackType: "" ,subject:"",description:"" });
        setApiStatus('');
        history("/help-feedback")
    }

    const handleChat = () =>{
        const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'CHAT');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
      }

     const closeComingsoon = () => {
        setComingsoon(false);
     }
    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
        {isCloseNav ? (<ul id="OBhexGrid" className="HangOutMenu">
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex" style={{ top: '94px' }}>
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}>
                 <div className="CloseNav">
                        <img style={{ marginLeft: '50px', marginTop: '12px', height: '30px' }} src="assets/media/svg/x-close.svg" onClick={handleClose}/> 
                    </div>
                    </a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    <Link className="OBhexIn" to="/resources">
                        <p> <span className="align-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" fill="rgba(255,255,255,1)" /></svg> <br />
                            <u className="OBhexTitle">Resources</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    {/* <Link className="OBhexIn" to="/help-feedback"> */}
                    <Link className="OBhexIn" onClick={handleChat}>
                        <p> <span className="align-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176zm.29-2.113l.653.35A7.955 7.955 0 0 0 12 20a8 8 0 1 0-8-8c0 1.334.325 2.618.94 3.766l.349.653-.655 2.947 2.947-.655z" fill="rgba(255,255,255,1)" /></svg> <br />
                            <u className="OBhexTitle">Chat</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    <Link className="OBhexIn active" to="/feedback">
                        <p> <span className="align-self-center">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 3H6C5.60218 3 5.22064 3.15803 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V31.5C4.5 31.8978 4.65804 32.2794 4.93934 32.5607C5.22064 32.842 5.60218 33 6 33H30C30.3978 33 30.7794 32.842 31.0607 32.5607C31.342 32.2794 31.5 31.8978 31.5 31.5V4.5C31.5 4.10218 31.342 3.72064 31.0607 3.43934C30.7794 3.15803 30.3978 3 30 3ZM28.5 6V30H7.5V6H28.5ZM12 25.5H24V22.5H12V25.5ZM12 19.5H24V16.5H12V19.5ZM12 13.5H19.5V10.5H12V13.5Z" fill="white" /></svg> <br />
                            <u className="OBhexTitle">Feedback</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex" style={{ top: '-94px', zIndex: -1 }}>
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
                </li>
            </ul>)  :  
                      <div className="open-sidemenu" onClick={() => { setIsCloseNav(true) }}>

                                <span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>

                       </div>}

            <div className="container-fluid">
                <div className="row mt-4">

                    <div className="col-9">
                        <div className="col-10 ml-auto p-0">
                        <div className="cRoomHeader pt-2">
                            <div className="cRsuteLogo align-self-center">
                                <Link to="/help-feedback"><img src="/assets/media/images/logo.png" /></Link>
                            </div>
                            <div className="cRTitle p-3"><h5>Feedback</h5></div></div>
                            </div>
                        <div className="row">
                            <div className="col-sm-10 ml-auto pl-0">
                                <div className="card loginBox addTeamMemberBox">
                                        {apiStatus ? <p className="alert mt-2 alert-danger">{apiStatus}</p> : ''}
                                    <div className="card-body pl-5 pr-5">
                                    <form onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col-6 mb-4 text-white">Feedback Type <sup>*</sup>
                                                <select name="feedBackTypeId" onChange={handleChange} className="form-control">
                                                    <option value="0">Select</option>
                                                    {user.feedbackTypes&& user.feedbackTypes.map((feedbackType:any) =>{
                                                        return  (<option key={feedbackType.Id} value={feedbackType.Id}>{feedbackType.Type}</option>)
                                                    })}                                                   
                                                </select>
                                            </div>
                                            {/* <div className="col-6 mb-4 text-white">Subject <sup>*</sup>
                                                <input className={"form-control " + (formErrors['CompanyName'] && formErrors['CompanyName'].cls ? formErrors['CompanyName'].cls : '')}
                                                onChange={handleChange} autoComplete="off" name="subject" type="text" placeholder="Enter subject" maxLength={100}  />
                                            </div> */}
                                            <div className="col-12 mb-4 text-white">Feedback <sup>*</sup>
                                                <textarea className={"form-control " + (formErrors['CompanyName'] && formErrors['CompanyName'].cls ? formErrors['CompanyName'].cls : '')}
                                                onChange={handleChange} autoComplete="off" name="description" placeholder="Enter your feedback" style={{ height: '150px' }}></textarea>
                                            </div>
                                            <div className="form-group col-6">
                                                <button type="reset" onClick={cancelButton} className="btn btn-secondary btn-block font-weight-bold pl-5 pr-5 float-left  mt-4 mb-4">Cancel</button>
                                            </div>
                                            <div className="form-group col-6">
                                                <button type="submit" className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-4 mb-4" disabled={loadRef.current ? true : false}>Submit 
                                                {loadRef.current ? <Loader /> : null}</button>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
        </section>
    </>
    );
}


export default Feedback;