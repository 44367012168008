import { openAICall } from "../../services/OpenService";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { StoreClass } from "../../store/Store";
import { useLocation } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  BsMic,
  BsMicMute,
  BsMicFill,
  BsChatDots,
  BsStopCircle,
} from "react-icons/bs";
import {
  FaMicrophoneAlt,
  FaMicrophoneSlash,
  FaRobot,
  FaVolumeUp,
  FaVolumeMute,
} from "react-icons/fa";
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";
import { useDispatch } from 'react-redux';

function ChatBot() {
  const { speak, cancel, speaking } = useSpeechSynthesis();
  const location = useLocation();
  const [chat, setChat] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const el = useRef<HTMLDivElement>(null);
  let loc = useRef<string>("");
  const [showChat, setShowChat] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [chatMic, setChatMic] = useState(false);
  const fromMic = useRef(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const { user } = useSelector((state: StoreClass) => ({
    user: state?.AuthReducer,
  }));
  const [showComingsoon, setComingsoon] = useState(false);
	const [screenName,setScreenName] = useState("");
  const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("transcript", transcript);
    console.log("transcript2", listening);
    if (transcript.length > 0 && !listening) {
      console.log("transcript3", "done");
      fromMic.current = true;
      setValue(transcript);
    }
  }, [transcript, listening]);
  useEffect(() => {
    console.log("user", user);
    if (el.current)
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
  });
  useEffect(() => {
    console.log("transcript5", value);
    if (fromMic.current == true) {
      handleSubmit();
      resetTranscript();
      fromMic.current = false;
    }
  }, [value]);
  useEffect(() => {
    loc.current = location.pathname;
    // if (loc.current.length > 0 && loc.current !== '/' && loc.current !== '/login' && loc.current !== '/forget-password' && loc.current.indexOf('/reset-password') == -1) {

    setChatActive(user.isAuthenticated);
  }, [location]);
  useEffect(() => {
    if (!speaking) setChatMic(false);
  }, [speaking]);
  const handleSubmit = () => {
    if (value === "") return;
    sendMessage({ sender: "user", message: value });
    setValue("");
  };

  // const onChatClick = () => {
  //   setShowChat(!showChat);
  //   onStopClick();
  // };

  const handleChatClick = () => {
    const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'ASSISTANT');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}    
  };
  const onSpeackClick = (msg: any) => {
    speak({ text: msg });
  };
  const onchatMicClick = () => {
    if (!chatMic) SpeechRecognition.startListening();
    else SpeechRecognition.stopListening();
    setChatMic(!chatMic);
  };
  const onStopClick = () => {
    if (speaking) {
      cancel();
      setChatMic(false);
    }
  };
  const sendMessage = async (message: any) => {
    setLoading(true);
    const newMessages = [...chat, message];
    setChat(newMessages);
    await fetchRes(newMessages);
  };

  const fetchRes = async (messages: any) => {
    openAICall(messages).then((res) => {
      setLoading(false);
      // if (res.success) {
      setChat([
        ...messages,
        {
          message: res,
          sender: "ChatGPT",
        },
      ]);

      if (chatMic) {
        onSpeackClick(res);
        setChatMic(false);
      }
    });
  };

  const closeComingsoon = () => {
		setComingsoon(false);
	  }

useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

  return (
    <>
      {chatActive == true && user.isAuthenticated ?  (
        <>
          {showChat ? (
            <div className={"text-left SideNavChat"} id="ChattingSideNav">
              {/* <div className="sideNavHeader">
            <div className="CloseNav" >
                <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" />
            </div>
        </div> */}
              <div className="SideNavChatBody">
                <div className="chatbot">
                  {/* header */}
                  <div className="header row">
                    <div className="col-10">
                      Chat B<FaRobot size={24} />t
                      {/* <FaVolumeUp /><FaVolumeMute /> */}
                    </div>
                    <div className="col-2"
                     //onClick={onChatClick}
                     >
                      <img
                        src="/assets/media/svg/x-close.svg"
                        alt="Close Side Nav"
                        title="Close"
                      />
                    </div>
                  </div>

                  {/* body */}
                  <div className="messages">
                    {chat.map((message: any, i: number) => {
                      return (
                        <>
                          <div className="message-container">
                            <div
                              key={i}
                              className={` ${
                                message.sender === "user"
                                  ? "user-message"
                                  : "bot-message"
                              }`}
                            >
                              {message.message}
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div id={"el"} ref={el} />
                  </div>

                  {/* input */}
                  <div className="input">
                    {loading ? (
                      <img src="./loader.gif" className="c-l" />
                    ) : (
                      <>
                        <input
                          className="txtar"
                          onKeyDown={(e) => {
                            e.keyCode === 13 &&
                              e.shiftKey === false &&
                              handleSubmit();
                          }}
                          type="textarea"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                        <button className="txtimg">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 500 500"
                          >
                            <g>
                              <g>
                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                              </g>
                            </g>
                          </svg>
                          {chatMic ? (
                            <FaMicrophoneAlt
                              onClick={onchatMicClick}
                              color="green"
                              size={"20px"}
                            />
                          ) : speaking ? (
                            <BsStopCircle
                              onClick={onStopClick}
                              color="red"
                              size={"20px"}
                            ></BsStopCircle>
                          ) : (
                            <BsMicMute
                              onClick={onchatMicClick}
                              color="red"
                              size={"20px"}
                            />
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="sideNavFooter d-none">
                <div className="col-12 text-right mt-4">
                  <img
                    className="c-pointer"
                    src="/assets/media/svg/settings.svg"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="fixed-mic" onClick={onSpeackClick}>
                        {
                            chatMic ? <BsMicFill color="#04f1b2" size={'28px'} /> : <BsMicMute color="#6264a5" size={'28px'} />
                        }


                    </div> */}
                      <div className="question-mark">
        <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path></svg></span>
    </div>
          <div className="fixed-chat" onClick={handleChatClick}>
            <span>Virtual Assistant</span>

            <BsChatDots color="#2e3151" size={"28px"} />
          </div>
        </>
      ) : null}
      <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
    </>
  );
}

export default ChatBot;
