import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { t } from '../i18n';

function RoomloginPopup(props: any) {
    const modelRef = useRef<HTMLDivElement>();
    const history = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('')
    const [mailError, setEmailError] = useState('')
    useEffect(() => {
        console.log(history);
    }, [])
    const rdToLogin = () => {
        localStorage.setItem("afterLoginRedirect", location.pathname);
        history('/login');
    }
    const submitName = () => {        
        setEmailError('');
        if (email.length === 0) {
            setEmailError('email is required');
            return;
        }else{
            const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            if(!email.match(isValidEmail)){
                setEmailError('Please enter a valid email');
            return;
            }
        }
        if (name.length === 0) {
            setEmailError('Display name is required');
            return;
        }
        props.okHandle(name,email)
    }
    return (
        <Modal ref={modelRef} show={true} onHide={props.close} backdrop='static' className="text-light" onEscapeKeyDown={(e: any) => { e.preventDefault(); }}>
            <Modal.Body>
                <div className="border-bottom mb-4 pb-2 text-center">
                    <h5 className="border-secondary mb-0 modal-title text-center" id="confirmPopupLabel">{t('roomloginpopup.guest_msg')}</h5>
                </div>
                {mailError ? <div className="alert alert-danger col-12">{mailError}</div> : ''}
                <div className="modal-body small">
                    <label className="mb-0">Enter Email</label>
                    <input type="text" required onChange={(e: any) => { setEmail(e.target.value) }} name="displayName"  className="SuteInputPrimary fs12 h40px" />
                </div>
                {/* {nameError ? <div className="alert alert-danger col-12">{nameError}</div> : ''} */}
                <div className="modal-body small">
                    <label className="mb-0">{t('roomloginpopup.enter_name')}</label>
                    <input type="text" required onChange={(e: any) => { setName(e.target.value) }} name="displayName"  className="SuteInputPrimary fs12 h40px" />
                </div>
                <div className="form-group text-center border-0 mt-4">
                    {/* <button onClick={rdToLogin} type="button" className="btn btn-secondary" data-dismiss="modal">Login</button> */}
                    <button onClick={submitName} type="button" className="btn btn-SeaGreen btn-sm">{t('button.join_room')}</button>
                </div>
                <div className="form-group m-0"><p className="pl-5 pr-5 fs12 text-center text-muted">{t('roomloginpopup.service_agree')} <a target="_blank" className="text-white font-weight-light" href="">{t('roomloginpopup.terms_service')}</a> {t('roomloginpopup.confirm_age_msg')}</p>

                    <p className="text-light small text-center">{t('signup.had_account')} <button onClick={rdToLogin} type="button" className="btn btn-secondary" data-dismiss="modal">{t('button.login')}</button></p></div>
            </Modal.Body>
        </Modal>
    );
}

export default RoomloginPopup;