import React, { useEffect, useRef, useState } from 'react';
import { t } from '../../i18n';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import emojiRegex from "emoji-regex";
import { Emoji } from "emoji-mart";
import { UploadChatFiles } from '../../services/FileService';
import * as T from "../../actions/Types";
interface ChatProps {
  name?: string
  value: string
  teacher?: boolean
  mute?: boolean
  roomCount?: number
  sendMessage: (evt: any) => void
  handleChange: (evt: any) => void
  handleChange2: (evt: any) => void
  typing: Function
  onUpload: Function
  removeFile: Function
  handleFilePopup: Function
  selectedFiles: any[]  
}
const regexPattern = /^\s+/;
const truncateBlank: (m: string) => string = (message: string) => message.replace(regexPattern, '');

export default function ChatInputPanel(props: ChatProps) {
  const [attachmentMenu, setAttachmentMenu] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const inputRef = useRef(null as any);
  const [text, setText] = useState('');
  const empGUID = localStorage.getItem('EmpGuid') || '';
  const GuestName = localStorage.getItem('GuestName') || '';
  
  const toggleAttachment = () => {
    setAttachmentMenu(!attachmentMenu);
  }
 
  function onUpload(e: any) {    
    e.preventDefault();
    toggleAttachment();
    console.log(e.target.files);
    const formData = new FormData();        
    for (const file of e.currentTarget.files) {
        formData.append('files', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
    }
  //   dispatch({
  //     type: T.ADD_LOADING
  // })
    UploadChatFiles(formData).then((resp) => {
     console.log('UploadChatFiles  ', resp);
     props.onUpload(resp);
  //    dispatch({
  //     type: T.REMOVE_LOADING
  // });
    }).catch((err) => {
    //   dispatch({
    //     type: T.REMOVE_LOADING
    // })
    })
    
  }
  const removeFile = (fileName: string, sute: boolean) => {
    props.removeFile(fileName, sute);
  }
  const attPreview = (file: any) => {
    return (<a key={file.fileId} className="dropdown-item c-pointer pl-2 pr-2" onClick={() => removeFile(file.fileId, file.sute)}>{file.fileName} <svg className="float-right" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 4.5l6 6m-6 0l6-6" stroke="red"></path></svg></a>
    );
  }
  const openSuteFiles = ()=>{
    props.handleFilePopup();
    toggleAttachment()
  }
 
  const handleSelectEmoji = (emoji:any) => {
    const input = inputRef.current;
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const newText = text.substring(0, startPos) + emoji.native + text.substring(endPos)  
    input.focus();
    input.setSelectionRange(startPos + emoji.native.length, startPos + emoji.native.length);
   input.focus();   
   props.handleChange2(newText);

  };

  useEffect(() => {
    console.log('EMP ', empGUID)
    console.log('GuestName ', GuestName)
    setText(props.value);
}, [props.value])

const onMsgChange = () => {  
            setOpenEmoji(false);
            const val = truncateBlank(props.value)
            val.length > 0 && props.sendMessage(val);
}
const onMsgClick = (e: any) => {
  onMsgChange();
}
  return (
    <div className="chattingInput">
      <input
        value={text}
        placeholder={t("chat.placeholder")}
        ref={inputRef}
        className="SuteInputPrimary fs10"
        onKeyPress={async (evt: any) => {
          if (evt.key === 'Enter') {
            onMsgChange();
          } else {
            props.typing();
          }
        }}
        onChange={props.handleChange} />
        <button className="chat-send-icon" onClick={onMsgClick}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m476.59 227.05-.16-.07L49.35 49.84A23.56 23.56 0 0 0 27.14 52 24.65 24.65 0 0 0 16 72.59v113.29a24 24 0 0 0 19.52 23.57l232.93 43.07a4 4 0 0 1 0 7.86L35.53 303.45A24 24 0 0 0 16 327v113.31A23.57 23.57 0 0 0 26.59 460a23.94 23.94 0 0 0 13.22 4 24.55 24.55 0 0 0 9.52-1.93L476.4 285.94l.19-.09a32 32 0 0 0 0-58.8z"></path></svg></button>

      <div className="col-12 text-right mt-2 p-0">
        
        {attachmentMenu ? <ul className="chatAttachOptions">
         { empGUID.length > 0 ? <li title="Open Saved files"><svg onClick={openSuteFiles} data-toggle="modal" data-target="#AttchFilePopup" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M10.5.5l.354-.354L10.707 0H10.5v.5zm3 3h.5v-.207l-.146-.147-.354.354zm-1 10.5h-10v1h10v-1zM2 13.5v-12H1v12h1zM2.5 1h8V0h-8v1zM13 3.5v10h1v-10h-1zM10.146.854l3 3 .708-.708-3-3-.708.708zM2.5 14a.5.5 0 01-.5-.5H1A1.5 1.5 0 002.5 15v-1zm10 1a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM2 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 001 1.5h1zM7 5v5h1V5H7zM5 8h5V7H5v1z" fill="currentColor"></path></svg></li> : ''}
          <li title="Browse from your System">
            <span className="importFileC c-pointer"> <input type="file" multiple={true} onChange={(e) => onUpload(e)} />
              <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                <path d="M7.5 1.5l3.25 3m-3.25-3l-3 3m3-3V11m6-4v6.5h-12V7" stroke="currentColor"></path></svg>
            </span>
          </li>
        </ul> : ''}
        <div className="dropdown-menu dropdown-menu-right fs12" aria-labelledby="attachmentsView">
          {props.selectedFiles && props.selectedFiles.map((f: any) => {
            return attPreview(f);
          })}
        </div>
        {props.selectedFiles.length>0?<span className="attchmentCount" role="button" id="attachmentsView" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{props.selectedFiles.length}</span>:''}
        <svg onClick={toggleAttachment} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15" className="mr-2 c-pointer"><path d="M13.5 7.5l-5.757 5.757a4.243 4.243 0 01-6-6l5.929-5.929a2.828 2.828 0 014 4l-5.758 5.758a1.414 1.414 0 01-2-2L9.5 3.5" stroke="#fff"></path></svg>
        <span className="mr-2 c-pointer" onClick={() => setOpenEmoji(!openEmoji)}>😀</span>
        {/* <Picker data={data} onEmojiSelect={handleSelectEmoji} /> */}
        {openEmoji ? <>
        <ul className="emji">
       <li> <Picker data={data} onEmojiSelect={handleSelectEmoji} 
        /></li></ul> </> : ''}
        {/* <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15" className="c-pointer"><path d="M4.9 8.7l-.3-.4-.8.6.3.4.8-.6zm6 .6l.3-.4-.8-.6-.3.4.8.6zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM4 6h1V5H4v1zm6 0h1V5h-1v1zm.1 2.7a3.25 3.25 0 01-5.2 0l-.8.6c1.7 2.267 5.1 2.267 6.8 0l-.8-.6z" fill="#fff"></path></svg> */}
      </div>
      
        
    </div>
  )
}

function dispatch(arg0: { type: any; }) {
  throw new Error('Function not implemented.');
}
