import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { chatClient } from "../../services/chat-api";
import { getGroupMems, getGroupsByEmpId } from '../../services/ContactsService';
import { useNavigate, useLocation} from "react-router-dom";
import CreateRoom from "../dashboard/CreateRoom";
import GroupFormInfo from "./GroupFormInfo";
import { StoreClass } from "../../store/Store";
import { t } from '../../i18n';
import { SaveActivity } from '../../services/ActivityServices';
import GroupTimeSlotPopup from "./GroupTimeSlot";
import GroupChatComponent from "../../components/GroupChatComponent";
import { GetMessageList } from "../../services/ChatServices";

function Groups(props: any) {
    const userDisplayName = localStorage.getItem('FirstName') as string;
    let orgId = localStorage.getItem('OrgGuid') || '';
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [openChat, setOpenChat] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [chatText, setChatText] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const history = useNavigate();
    const [teamId, setTeamId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamCount, setTeamCount] = useState(0);
    const [showInfo, setShowInfo] = useState(false);
    const [selectedGrp, setSelectedGrp] = useState<any>();
    const [canPublish, setCanPublish] = useState(true);// user typing publish
    const [typingUser, setTypingUser] = useState('');
    const empId = localStorage.getItem('EmpGuid') || '';
    const dispatch = useDispatch();
    const [startMeeting, setStartMeeting] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const[searchValue,setSearchValue] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (!user.groups || user.groups.length === 0) {
            dispatch(getGroupsByEmpId(empId) as any);
        } 
        //else {
            // user.groups.map((team: any) => {
            //     chatClient.createChannel(team.GroupGuid).then(() => {
            //         console.log('joined team group', team.GroupGuid);
            //     }).catch(error => {
            //         console.log('error in creating channel', team.GroupGuid);
            //     });
            // });
       // }
       // chatClient.on('chatNotify', handleRemoteTypingEvent)
        return () => {
           // chatClient.off('chatNotify', handleRemoteTypingEvent);
        }
    }, [user.groups]);

    useEffect(() => {
        if (teamId) {
            //dispatch(getGroupMems(teamId));
            //chatClient.subscribeChannelEvents(teamId);
        }
    }, [teamId]);

    // useEffect(() => {
    //     if (user.groups && user.groups.length > 0) {
    //         /* user.groups.map((team: any) => {
    //              chatClient.subscribeChannelEvents(team.GroupGuid);
    //          });*/
    //     }
    //     setGroups(user.groups);
    // }, [user.groups])

    useEffect(() => {
        if (user.groups && user.groups.length > 0) {
            user.groups.forEach((team: any) => {
                // Assuming chatClient.subscribeChannelEvents is the function to subscribe to channel events
                // Uncomment the line below to subscribe
                // chatClient.subscribeChannelEvents(team.GroupGuid);
            });
    
            // Ensure that user.groups is defined before updating state
            
            // setDupGroups(user.groups)
            if (searchValue?.length > 0) {
                const filteredGroups = user.groups.filter((group: any) =>
                    group.DisplayName.toLowerCase().includes(searchValue.toLowerCase())
                );
                setGroups(filteredGroups);
            }
            else{
                setGroups(user.groups);
            }
        }
    }, [user.groups]);
    
    
    useEffect(() => {

        if (user.teamMems && user.teamMems.length > 0) {
            let uids: string[] = [];
            user.teamMems.map((u: any) => {
                uids.push(u.EmpGuid);
            });
            setTeamCount(user.teamMems.length);
            chatClient.subscribeOnlineStatus(uids);
        }
    }, [user.teamMems]);

    chatClient.onMsgsLoad = (msgs: any[], cId: string) => {
        if (cId && cId === teamId) {
            setMessages([...msgs]);
            if (startMeeting) {
                if (user.teamMems && user.teamMems.length > 0) {
                    user.teamMems.forEach((teamusr: any) => {
                        SaveActivity({ msgType: "CALL_GROUP_INVITATION", meetingId: teamId, reason: "Group Call Notification", name: localStorage.getItem("FirstName"), fromEmpId: empId }, 'CALL', teamusr.EmpGuid);
                        setStartMeeting(false);
                    });
                }
            }
            
        }
        if(msgs.toString() == 'PEER')
        {
            setTimeout(() => {  dispatch(GetMessageList(empId, cId, 0)  as any); }, 1000);
        }
    };

    const handleRemoteTypingEvent = (...args: any) => {
        const [msg, memberId] = args;
        const msgt = JSON.parse(msg.text);
        if (msgt.toUser && msgt.toUser === teamId && msgt.from) {
            if (typingUser.length === 0)
                setTypingUser(msgt.from);
            else
                setTypingUser(typingUser + " and " + msgt.from);
            setTimeout(() => {
                setTypingUser('');
            }, 2000);
        }
    }
    const closeChat = () => {
        setOpenChat(false);
    }
    const createMeeting = (type: string, teamId: string) => {
        //const newMeetingid = getRoomId(10);
        let tName = "noName";
        if (user.groups && user.groups.length > 0) {
            const selTeam = user.groups.filter((team: any) => team.GroupGuid === teamId);
            tName = selTeam.length > 0 ? selTeam[0].GroupName : 'NONAME';
            setTeamId(teamId);
            setTeamName(tName);

        }
        localStorage.setItem('newMeetingType', type);
        localStorage.setItem('newMeetingid', teamId);
        setStartMeeting(true);
        chatClient.sendChannelCallMsg(teamId, teamId, userDisplayName, tName).then(() => {
            // const link = '/conference/'+newMeetingid;
            // chatClient.sendCallMsg({text:JSON.stringify({t: '__CALL__',f:userDisplayName, l: link, rn: tName}), messageType: "TEXT"}, empId);
          ///alert('dfdsfdsf')
            history('/customrm/' + teamId, {state:  location.pathname});

        });
    }
    const createAudioCall = (teamId: string) => {
        createMeeting('audio', teamId);
    }
    const createVideoCall = (teamId: string) => {
        createMeeting('video', teamId);
    }
    const handleOpenChat = (cid: string) => {
        if (user.groups && user.groups.length > 0) {
            const selTeam = user.groups.filter((team: any) => team.GroupGuid === cid);
            setTeamName(selTeam.length > 0 ? selTeam[0].GroupName : 'NONAME');
            setTeamId(cid);
            setOpenChat(true);
            // setMessages(chatClient.messages[cid]);
            setShowInfo(false);
        }
    }
    const showGroupInfo = (groupId: string) => {
        const selTeam = (user.groups || []).filter((team: any) => team.GroupGuid === groupId);
        if (selTeam.length > 0) {
            setSelectedGrp(selTeam[0]);
            setShowInfo(true);
            setOpenChat(false);
            setShowAddForm(false)
            setShowSchedule(false)
        }
    }
    const toggleAddForm = () => {
        if (!showAddForm) {
            setShowInfo(false);
            setOpenChat(false);
            setShowSchedule(false);
        }
        setShowAddForm(!showAddForm);
        
    }
    const toggleInfo = () => { setShowInfo(!showInfo); }


    const closeGroupWindows = () => {
        setSearchValue("");
        if (user.groups) {
            setGroups(user.groups);
        }
        setShowAddForm(false)
        setShowInfo(false);
        setOpenChat(false);
        props.handleShowGroups();
    }
    const ClickSchedule = (groupGuid: string) => {
        setTeamId(groupGuid);
        setShowSchedule(!showSchedule)
        setShowInfo(false);
    }

    const toggleSchedule = () => {
        setShowSchedule(!showSchedule)
    }
    useEffect(() => {
        if (!props.showGroups) {
            setOpenChat(false);
            setSearchValue("")
            if (user.groups) {
                setGroups(user.groups);
            }
        }
    }, [props.showGroups])

    // function onSearch(e: any) {
    //     const groups = user.groups?.filter((group:any)=>
    //         group.DisplayName.toLowerCase().includes(e.target.value))
    //         setGroups(groups);
    // }

    function onSearch(e: any) {
        setSearchValue(e.target.value);
        if (user.groups) {
            const filteredGroups = user.groups.filter((group: any) =>
                group.DisplayName.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setGroups(filteredGroups);
        }
    }
    
    

    return (<>
        {/* <AddGroupForm toggleAddForm={toggleAddForm} showAddForm={showAddForm} /> */}
        <GroupFormInfo toggleInfo={toggleInfo} showInfo={showInfo} groupInfo={selectedGrp}  />
        <CreateRoom show={showAddForm} close={toggleAddForm} defaultPrivate={true} />
        <div className={props.showGroups ? "SideNav SideNavLeft" : "SideNav SideNavLeft d-none"} id="GroupsSideNav">
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={closeGroupWindows}>
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" /> </div>
            </div>
            <div className="sideNavBody" id="GroupsList">
                <div className="row mt-4 small">
                    <div className="col-12 mb-2 text-light font-weight-bold">{t('groups.collaborate')}
                        <svg onClick={toggleAddForm} className="float-right c-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <title>{t('groups.create_new_room')}</title>
                            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#fff" /></svg>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 small ParticipantsSearch">
                        <input className="SuteInputPrimary" value={searchValue} type="search" placeholder={t('title.search')} onChange={e => onSearch(e)}/> </div>
                </div>
                <div className="row mt-3 small">
                    {groups && groups.map((event: any) => {
                        if (!event.IsPrivate) return ('');
                        return (
                            <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary d-flex" key={event.GroupGuid}>
                                <div>
                                    <svg onClick={() => showGroupInfo(event.GroupGuid)} className="ParticipantImage c-pointer active" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <pattern id={event.GroupGuid} patternUnits="userSpaceOnUse" width="100" height="100">
                                                <image xlinkHref="/assets/media/images/group-thumbnail.png" x="-25" width="150" height="100" />
                                            </pattern>
                                        </defs>
                                        <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${event.GroupGuid})`}></polygon>
                                    </svg>
                                    {event.GroupTypeName === 'Microsoft Teams' ? <span className="groupSource"><img src="/assets/media/svg/teamsLogo.svg" /></span> : ''}
                                </div>
                                <div>
                                    <p className="ParticipantName">{event.GroupName}</p>
                                    <p className="ParticipantDesignation">
                                        <img className="c-pointer" title={t('chat.chat')} alt="Chat" onClick={() => handleOpenChat(event.GroupGuid)} src="/assets/media/svg/profile/message.png" />
                                        <img className="c-pointer" title={t('chat.direct_call')} alt="Direct Call" onClick={() => createAudioCall(event.GroupGuid)} src="/assets/media/svg/profile/call.png" />
                                        {/* <img className="c-pointer" title={t('chat.video_call')} alt="Video Call" onClick={() => createVideoCall(event.GroupGuid)} src="/assets/media/svg/profile/video-call.svg" /> */}
                                        <img className="c-pointer" title="Schedule" alt="Schedule" onClick={() => ClickSchedule(event.GroupGuid)} src="/assets/media/svg/profile/calendar.png" />
                                    </p>
                                    {user.messageCount[event.GroupGuid] && user.messageCount[event.GroupGuid] > 0 ? <div className="chatBubble" onClick={() => handleOpenChat(event.GroupGuid)}>
                                        <span>{user.messageCount[event.GroupGuid]}</span>
                                    </div> : ''}
                                </div>
                            </div>);
                    })}
                </div>
            </div>
            {/* <div className="sideNavFooter">
                <div className="col-12 text-left mt-4"> <img className="c-pointer" src="/assets/media/svg/settings.svg" /> </div>
            </div> */}
        </div>
        {openChat ?
            <GroupChatComponent openChat={openChat} closeChat={closeChat}
                teamName={teamName}
                teamId={teamId} /> : ''}
        {showSchedule ? <GroupTimeSlotPopup GroupGuid={teamId} show={showSchedule} close={toggleSchedule} /> : ''}
    </>
    );
}


export default Groups;
