import React, { useEffect, useRef, useState } from 'react';
import ChatTranscrit from "../pages/myoffice/ChatTranscrit";
import ChatInputPanel from '../pages/myoffice/ChatInputPanel';
//import { chatClient } from "../services/chat-api";
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';
import { StoreMsg, SaveChat, GetMessageList, UpdateAsSeen, saveFilesForUser, GetUnseenUserMessagesCount } from '../services/ChatServices';
import SuteFilesPopup from '../components/SuteFilesPopup';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from './Loader';
import emojiRegex from 'emoji-regex';
import { flushSync } from 'react-dom';
function getGuid() {
    return uuidv4();
}
function UserChatComponent(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [value, setValue] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const [typingUser, setTypingUser] = useState('');
    const [msgSound] = useState(new Audio('/assets/audios/microbounce.mp3'));
    const empId = localStorage.getItem('EmpGuid') || '';
    const userDisplayName = localStorage.getItem('FirstName');
    const [canPublish, setCanPublish] = useState(true);// user typing publish
    const [saveFile, setSaveFile] = useState({} as File);
    const [scrollEnd, setScrollEnd] = useState(0);
    const dispatch = useDispatch();
    const reqGetmore = React.useRef(true);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [ssFiles, setSsFiles] = useState<any[]>([]);//sute selected files
    const [displayFiles, setDisplayFiles] = useState<any[]>([]);
    const [displayEmojis, setDisplayEmojis] = useState<any[]>([]);
    const sendingFiles = useRef(false);
    const [openFilesPopup, setOpenFilesPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const isFirst = useRef(false);
    let postMsgsIn = useRef<any>(0);

    useEffect(() => {
        if (user.success && user.success === "Save message") {
            console.log('#TEST user.success ')
            if (user && user.Socket && user.Socket.connected) {
                console.log('#TEST userMessage ')
                user.Socket?.emit("userMessage", props.selEmpGuid);
            }
            else
                console.log('#TEST NO SOCKET ')
            user.success = ""
        }
    }, [user.success]);

    useEffect(() => {
        console.log("#CHAT user.messages", user.messages);

        const prevMsg = user.messages[props.selEmpGuid];
        setLoading(false);
        if (prevMsg && prevMsg.length > 0) {
            setMessages([...prevMsg]);
            reqGetmore.current && setScrollEnd(1 + scrollEnd);
            markAsSeen(prevMsg[prevMsg.length - 1].TimeStamp);
            if (prevMsg.length < 50) {
                getMoreMessage(prevMsg[0].TimeStamp);
            }

        }
        reqGetmore.current = true;
    }, [user.messages]);


    // useEffect(() => {
    //     console.log("EEE openChat", user.messages);

    //     const prevMsg = user.messages[props.selEmpGuid];
    //     if (prevMsg && prevMsg.length > 0 && prevMsg.length < 10) {
    //         console.log("EEE openChat 222", user.messages);
    //         getMoreMessage(prevMsg[0].TimeStamp);
    //         console.log("EEE openChat 333", user.messages);
    //     }
    // }, [props.openChat])

    const handleChange = (event: any) => { setValue(event.target.value) }
    const GetMegs = () => {
        dispatch(GetMessageList(empId, props.selEmpGuid, 0) as any);
    }

    useEffect(() => {
        if (!isFirst.current) {
            isFirst.current = true;
            console.log("#CHAT selEmpGuid", props.selEmpGuid);

            if (!props.selEmpGuid) return;
            setMessages([]);
            // const prevMsg = user.messages[props.selEmpGuid];
            // if (!prevMsg || prevMsg.length == 0) {
            console.log("EEE selEmpGuid 222", props.selEmpGuid);
            setLoading(true);

            if (user && user.Socket && user.Socket.connected) {
                user.Socket.on('userNewMessage', (UserId: any) => {
                    console.log('#CHAT userMessage got', UserId)
                  //  GetMegs();
                    dispatch(GetMessageList(empId, props.selEmpGuid, 0) as any);
                })
            } else {
                console.log('#CHAT NO SOCKET 1')
                postMsgsIn.current = setInterval(GetMegs, 5000);
            }

            dispatch(GetMessageList(empId, props.selEmpGuid, 0) as any);
            console.log("#CHAT selEmpGuid 3333", props.selEmpGuid);
            setTimeout(() => {
                setLoading(false);
            }, 5000);

            setScrollEnd(1 + scrollEnd);
        }

        return () =>{
            if (isFirst.current){
            console.log("#CHAT return", props.selEmpGuid);
            clearInterval(postMsgsIn.current);           
            }
        }
    }, [props.selEmpGuid]);

    const markAsSeen = (ts: string) => {
        const form = new FormData();
        form.append('TimeStamp', ts);
        form.append('EmpGuid', props.selEmpGuid);
        form.append('ToGuid', empId);
        dispatch(UpdateAsSeen(form, props.selEmpGuid) as any);
        dispatch(GetUnseenUserMessagesCount(empId) as any);
    }

    const getstring = (msg: string) => {
        const regex = emojiRegex();
        regex.lastIndex = 0;
        const matches = msg.match(regex) || [];
        const emojis = matches.map((match) => match.trim());
        let native = '' as any
        let code = '';
        let idx = 0;
        emojis.forEach(emoji => {
            native = emoji as any
            code = native.codePointAt(0).toString(16);
            idx = msg.indexOf(native);
            if (idx == 0) {
                msg = '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
            }
            else {
                msg = msg.substring(0, idx) + '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
            }
        })
        return Linkify(msg);
    }
    const Linkify = (children: any) => {
        const words = children.split(' ')
        const formatedWords = words.map((w: any, i: any) => addMarkup(w))
        const html = formatedWords.join(' ')
        return html;
    }
    const isUrl = (word: any) => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
        return word.match(urlPattern)
    }
    const addMarkup = (word: any) => {
        return isUrl(word) ?
            `_LINK_${word}_LINK_` :
            word
    }
    const sendMessage = (msg: string) => {
        console.log("#CHAT sendMessage ");
        if (selectedFiles && selectedFiles.length > 0) {
            sendingFiles.current = true;
            uploadtoServer();
        } else {
            const msgId = getGuid();
            msg = getstring(msg);
            const jsonMsg: any = { text: msg, UUID: msgId };
            if (ssFiles && ssFiles.length > 0) {
                jsonMsg.files = ssFiles;
            }
            const msgStr = JSON.stringify(jsonMsg);
            const retMsg = { text: msg, messageType: "TEXT", SenderName: 'me', SenderGuid: empId, TimeStamp: Date.now().toString() };

            // chatClient.sendPeerMessage(msgStr, props.selEmpGuid).then((retMsg: any) => {
            //     if (!retMsg) {
            //         console.log('error in sending message', chatClient._logined);
            //         return;
            //     }
            const form = new FormData();
            form.append('Message', msgStr);
            form.append('ToGuid', props.selEmpGuid);
            form.append('FromGuid', empId);
            form.append('TimeStamp', retMsg.TimeStamp);
            form.append('UUID', msgId);
            form.append('IsGroupChat', "false");
            console.log("#CHAT 222");
            dispatch(StoreMsg({ ...retMsg, SenderGuid: empId, UUID: msgId }, props.selEmpGuid) as any);
            console.log("#CHAT 333");
            dispatch(SaveChat(form) as any);
            console.log("#CHAT 444");


            // });
            setValue('');
        }
    }

    useEffect(() => {
        if (user.filesResponse && sendingFiles.current) {
            const msgId = getGuid();
            const jsonMsg: any = { text: value, UUID: msgId };
            jsonMsg.files = user.filesResponse.map((f: any) => {
                return {
                    fileId: f.FileId,
                    url: f.FileURL,
                    filename: f.FileName
                }
            });
            const msgStr = JSON.stringify(jsonMsg);
            const retMsg = { text: value, messageType: "TEXT", SenderName: 'me', SenderGuid: empId, TimeStamp: Date.now().toString() };

            //  chatClient.sendPeerMessage(msgStr, props.selEmpGuid).then((retMsg: any) => {
            const form = new FormData();
            form.append('Message', msgStr);
            form.append('ToGuid', props.selEmpGuid);
            form.append('FromGuid', empId);
            form.append('TimeStamp', retMsg.TimeStamp);
            form.append('UUID', msgId);
            form.append('IsGroupChat', "false");
            dispatch(StoreMsg({ ...retMsg, SenderGuid: empId, UUID: msgId }, props.selEmpGuid) as any);
            dispatch(SaveChat(form) as any);
            //  });
            setValue('');
            sendingFiles.current = false;
        }
    }, [user.filesResponse]);

    const typingHandler = () => {
        if (canPublish) {
            // chatClient.sendNotification(props.selEmpGuid || '', userDisplayName || '');
            setCanPublish(false);
            setTimeout(() => {
                setCanPublish(true);
            }, 2000);
        }
    }

    const getMoreMessage = (ts: any) => {
        console.log('timestapm to get more messages', ts);
        reqGetmore.current = false;
        // setLoading(true);
        dispatch(GetMessageList(empId, props.selEmpGuid, ts) as any);

    }

    const uploadtoServer = () => {
        const form = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            form.append(`file`, selectedFiles[i], selectedFiles[i].name);
        }
        form.append('employeeGuid', empId);
        dispatch(saveFilesForUser(form) as any);
    }

    function onUpload(files: any) {
        //e.preventDefault();
        // console.log(files.length);
        // const sfiles: any = [];
        // const afiles: any = [];
        // for (var i = 0; i < files.length; i++) {
        //     sfiles.push(files[i]);
        //     afiles.push({ fileId: files[i].name, fileName: files[i].name, sute: false })
        // }
        // setSelectedFiles([...sfiles]);
        // setDisplayFiles(afiles);
        files.forEach((f: any) => {
            sendMessage('_MEDIA_' + f + '_MEDIA_');
        });
    }

    function removeFile(fn: string, sute: boolean) {
        if (!sute) {
            const filFiles = selectedFiles.filter((sf: any) => sf.name !== fn);
            setSelectedFiles([...filFiles]);
            const afiles = filFiles.map((f: any) => {
                return { fileId: f.name, fileName: f.name, sute: false }
            })
            setDisplayFiles(afiles);
        } else {
            const filFiles = ssFiles.filter((sf: any) => sf.filename !== fn);
            setSsFiles(filFiles);
            const afiles = filFiles.map((f: any) => {
                return { fileId: f.fileId, fileName: f.filename, sute: true }
            })
            setDisplayFiles(afiles);
        }
    }

    const suteSelectFiles = (sfiles: any) => {
        // setSsFiles(sfiles);
        const afiles = sfiles.map((f: any) => {
            console.log(' SUTEFiles', f.url)
            sendMessage('_MEDIA_' + f.url + '_MEDIA_');
            //  return { fileId: f.fileId, fileName: f.filename, sute: true }
        })
        //  setDisplayFiles(afiles);
    }

    const handleFilePopup = () => {
        setOpenFilesPopup(!openFilesPopup);
    }

    const emojiChange = (val: string) => {
        setValue(val);
    }
    return (<>
        {props.mini ? <>
            <ChatTranscrit members={user.contacts} messages={messages} typingUser={typingUser}
                getmore={(ts: any) => { getMoreMessage(ts) }} scrollEnd={scrollEnd} peerId={props.selEmpGuid} />
            <ChatInputPanel
                value={value}
                handleChange2={emojiChange}
                sendMessage={sendMessage}
                handleChange={handleChange} typing={typingHandler}
                selectedFiles={displayFiles}
                onUpload={onUpload} removeFile={(fn: any, sute: boolean) => removeFile(fn, sute)}
                handleFilePopup={handleFilePopup} /></> : ''}
        {props.openChat && !props.mini ? <div className={"SideNav text-left"} id="ChattingSideNav">
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={props.closeChat}>
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" />
                </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4 small">
                    <div className="col-12 mb-2 text-light" >
                        {props.selEmpName} </div>

                </div>
                {!loading ? <><ChatTranscrit members={user.contacts} messages={messages} typingUser={typingUser}
                    getmore={(ts: any) => { getMoreMessage(ts) }} scrollEnd={scrollEnd} peerId={props.selEmpGuid} />
                    <ChatInputPanel
                        value={value}
                        sendMessage={sendMessage}
                        handleChange2={emojiChange}
                        handleChange={handleChange} typing={typingHandler}
                        selectedFiles={displayFiles}
                        onUpload={onUpload} removeFile={(fn: any, sute: boolean) => removeFile(fn, sute)}
                        handleFilePopup={handleFilePopup} /></> : <div className="row mt-4 small">
                    <div className="col-12 mb-2 spinDiv"><br /><br /><svg className="spinLoad2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="45" height="45"><path d="M8 1V.5H7V1h1zM7 4.5V5h1v-.5H7zm1 6V10H7v.5h1zM7 14v.5h1V14H7zM4.5 7.995H5v-1h-.5v1zm-3.5-1H.5v1H1v-1zM14 8h.5V7H14v1zm-3.5-1.005H10v1h.5v-1zM7 1v3.5h1V1H7zm0 9.5V14h1v-3.5H7zM4.5 6.995H1v1h3.5v-1zM14 7l-3.5-.005v1L14 8V7zM2.147 2.854l3 3 .708-.708-3-3-.708.708zm10-.708l-3 3 .708.708 3-3-.708-.708zM2.854 12.854l3-3-.708-.708-3 3 .708.708zm6.292-3l3 3 .708-.708-3-3-.708.708z" fill="currentColor"></path></svg></div></div>}
                {sendingFiles.current ? <Loader /> : ''}
            </div>
            {openFilesPopup ? <SuteFilesPopup onSubmit={suteSelectFiles} show={openFilesPopup} close={handleFilePopup} /> : ''}
        </div> : ''}</>
    );
}

export default UserChatComponent;