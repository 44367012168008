import { useState, useEffect, useRef } from 'react';
//import { suteClient } from "../../services/sute-rtc-api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getGroupsByEmpId } from "../../services/ContactsService";
import { getOrgDetailsByOrgId } from '../../services/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import WindowTitle from '../../layout/WindowTitle';
import { t } from '../../i18n';
import { StoreClass } from '../../store/Store';
import CompanyDirectory from './CompanyDirectory';
import { SetMeetingId } from "../../services/MediaService";
import './earn.css';
import ShowOrgFiles from '../orgFiles/ShowOrgFiles';
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";

function EarnLearn(props: any) {
    const orgId = localStorage.getItem('OrgGuid');
    const [showContacts, setShowContacts] = useState(false);
    
    const [roomDtls, setRoomDtls] = useState<any>({ Name: '' });
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
   
    const history = useNavigate();
    
    const [meetingId, setMeetingId] = useState('');
    const dispatch = useDispatch();
    const {meetingPId} = useParams ();
    const media = useSelector((store: any) => store.MediaReducer);
    const [showBulletinBoard, setShowBulletinBoard] = useState(0);
    const [showLearnMenu, setshowLearnMenu] = useState(false);
    const [showComingsoon, setComingsoon] = useState(false);
    const [screenName,setScreenName] = useState("");
    const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')


    useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

    const handleShowContacts = () => {
        setShowContacts(!showContacts);
        setshowLearnMenu(false)
    }
    // const getUserDisplayName = (streamId: any) => {
    //     if (chatClient.streamsInfo && chatClient.streamsInfo[streamId])
    //         return chatClient.streamsInfo[streamId].dp + (chatClient.streamsInfo[streamId].screen ? '(Presentation)' : '');
    //     return "Loading...";
    // }

    // const handleShowLearnMenu = () => {
    //     if(!showLearnMenu){
    //         // setPwindow(false);
    //         // setChatWindow(false);
    //     }
    //     setshowLearnMenu(!showLearnMenu);
    //     setShowContacts(false)
    // }

    const closeWindows = ()=>{
        setshowLearnMenu(false); 
    }

    useEffect(() => {
        
        setMeetingId(meetingPId  || '');
        // if (!user.groups || user.groups.length == 0) {
        //     dispatch(getGroupsByEmpId(orgId) as  any);
        // } else {
        //     const room = user.groups.filter((r: any) => r.GroupGuid === meetingPId);
        //     if (room.length > 0) {
        //         setRoomDtls(room[0]);
        //     }
        // }
        // dispatch(getOrgDetailsByOrgId(orgId) as any);
        if(!media.meetingId)
        {
            dispatch(SetMeetingId(meetingPId) as any);
        }
    }, []);
   
    // useEffect(() => {
    //     if (user.success && user.success === 'AddEmployeeToRoom') {
    //         globalStore.showToast({
    //             type: 'Rooms',
    //             title: "Room Message",
    //             message: "Joined room successfully"
    //         });
    //         dispatch(removeSuccessStatus());
    //     } else if ("RemoveEmployeeFromRoom" === user.success) {
    //         globalStore.showToast({
    //             type: 'Rooms',
    //             title: "Room Message",
    //             message: "You left the room"
    //         });
    //         dispatch(removeSuccessStatus());
    //         history('/pod');
    //     }
    // }, [user.success]);

    // useEffect(() => {
    //     if (!suteClient.joined) {
    //         makeJoin(meetingPId);
    //         suteClient.on('playerStatuschanged', playerStatusChangeEvent);
    //     }
    //     return () => {
    //         suteClient.off('playerStatuschanged', playerStatusChangeEvent);
    //     }
    // }, [suteClient.joined]);

    // const playerStatusChangeEvent = (event: any) => {
    //     //setAudio(suteClient.params.audio);
    // }

    // useEffect(() => {
    //     setLocalStream({ ...suteClient.localStream });
    //     setPublished(suteClient.published);
    //     setStreamsInfo(chatClient.streamsInfo);
    //     if (suteClient.published) {
    //         updateIntId(setInterval(postRoomStatus, 120000));
    //     }
    //     return () => {
    //         clearInterval(postRoomStatusInt._id);
    //     }
    // }, [suteClient.published, chatClient.streamsInfo]);

    useEffect(() => {
        console.log(user.orgData)
    }, [user.orgData]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLocalStream(suteClient.localStream);
    //     }, 500);
    // }, [joined]);
    // suteClient.onRemoteAdded = (newstream: any) => {
    //     setRemoteStreams([...suteClient.remoteStreams]);
    //     setStreamsInfo(chatClient.streamsInfo);
    // }

    function leave() {
        
        history('/pod');       
    }

   // async function justLeave() {
        //await suteClient.leave();
   // }

    // const makeJoin = (meetingId: any) => {
    //     if (joined && published) {
    //         //left from existing only rooms on navigation
    //         justLeave();
    //     }
    //     if (!suteClient.joined) {
    //         suteClient.join({ channel: meetingId, uid: empId, displayName: uname, type: 'onlyaudio' })
    //             .then(function (res: any) {
    //                 setJoined(true);
    //                 setAudio(true);
    //                 setStreamsInfo(chatClient.streamsInfo);
    //                 const form = new FormData();
    //                 form.append("GroupGuid", meetingId);
    //                 form.append("EmployeeGuid", empId);
    //                 dispatch(AddEmployeeToRoom(form));
    //             });
    //     } else {
    //         setPublished(suteClient.published);
    //         setRemoteStreams([...suteClient.remoteStreams]);
    //         setLocalStream({ ...suteClient.localStream });
    //         if (localStream) {
    //             setAudio(localStream.audio);
    //         }
    //     }
    // }
    // const onAudioClick = (evt: any) => {
    //     console.log('Click for audio change');
    //     //suteClient.onPlayerClick('audio', !suteClient.params.audio);
    // }

    // const admitRequestedUsr = (empGuid: string) => {
    //     chatClient.sendNotification(empGuid, meetingId, "ROOM_ADMIT");
    //     const reqs = remoteRequests.filter(req => req.empGuid !== empGuid);
    //     setRemoteRequests([...reqs]);
    // }
    // const rejectRequestedUsr = (empGuid: string) => {
    //     chatClient.sendNotification(empGuid, meetingId, "ROOM_REJECT");
    //     const reqs = remoteRequests.filter(req => req.empGuid !== empGuid);
    //     setRemoteRequests([...reqs]);
    // }
    // const getChatNotify = (args: any) => {
    //   //  const [msg, memberId] = args;
    //     const msgt = JSON.parse(args.text);
    //     console.log("hangout getChatNotify>empId=", empId, msgt, meetingPId);
    //     if (msgt.toUser && msgt.toUser === meetingPId) {
    //         if (msgt.t === 'ROOM_LOCKED' || msgt.t === 'ROOM_UNLOCK') {
    //             setLocked(msgt.t === 'ROOM_LOCKED' ? true : false);
    //         } else if (msgt.t === 'REQUEST_TO_JOIN') {
    //             getAsyncEmpDetailsByEmpGuid(msgt.from).then((usrDtls: any) => {
    //                 setRemoteRequests([...remoteRequests, { empGuid: msgt.from, ProfileImageURL: usrDtls.ProfileImageURL, name: usrDtls.FirstName }]);
    //             });
    //         } else {
    //             if (typingUser.length === 0)
    //                 setTypingUser(msgt.from);
    //             else
    //                 setTypingUser(typingUser + " and " + msgt.from);
    //             setTimeout(() => {
    //                 setTypingUser('');
    //             }, 2000);
    //         }
    //     } else if (msgt.from === meetingPId  && msgt.toUser !== empId) {
    //         console.log("to other remote users");
    //         const reqs = remoteRequests.filter(req => req.empGuid !== msgt.toUser);
    //         setRemoteRequests([...reqs]);
    //     } else if (msgt.toUser === empId) {
    //         const reqs = remoteRequests.filter(req => req.empGuid !== empId);
    //         setRemoteRequests([...reqs]);
    //         console.log("to user waiiting to join the call", msgt);
    //         if (msgt.t === 'ROOM_ADMIT') {
    //             setWaitingMsg("Accepted your request, joining into the room...")
    //             //makeJoin(meetingId);
    //             setShowWaiting(false);
    //         } else if (msgt.t === 'ROOM_REJECT') {
    //             setShowWaiting(false);
    //             globalStore.showToast({
    //                 type: 'Error',
    //                 title: "Deny Message",
    //                 message: 'Denied to join the room'
    //             });
    //             history('/pod');
    //         }
    //     }
    // }
    // const toggleChat = () => {
    //     if (chatWindow) {
    //         setChatWindow(false);
    //         //setPwindow(true);            
    //     } else {
    //         setPwindow(false);
    //         setChatWindow(true);
    //         setNewMsg(false);
    //     }
    //     setshowLearnMenu(false);
    // }
    // const toggleParticipants = () => {
    //     if (pWindow) {
    //         setPwindow(false);
    //     } else {
    //         setPwindow(true);
    //         setChatWindow(false);
    //         setshowLearnMenu(false);
    //     }
    // }
    const closeSideBar = () => {
        // setChatWindow(false);
        // setPwindow(false);
    }
    // const postRoomStatus = async () => {
    //     //console.log('post last activity....')
    //     const form = new FormData();
    //     form.append("GroupGuid", roomDtls.GroupGuid);
    //     form.append("EmployeeGuid", empId);
    //     await UpdateLastActivityTime(form);
    // }
    // const openProfile = (profile: any, empGuid: string, name: string, image: string) => {
    //     if (user.isAuthenticated && empGuid !== '00000000-0000-0000-0000-000000000000') {
    //         if (!profile) {
    //             profile = { EmpGuid: empGuid, FirstName: name, LastName: '', ProfileImageURL: image, NoChat: true };
    //         }
    //         setSelectUser({ ...profile, NoChat: true });
    //         setProfileWin(true);
    //         setshowLearnMenu(false);
    //     }
    // }
    const openBulletinBoard = () => {
        setShowBulletinBoard((showBulletinBoard) => showBulletinBoard + 1);
        setshowLearnMenu(false)
        setShowContacts(false)
    }

    const handleShowLearnMenu = () => {
        const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'EVENTS');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
    }
    
    const closeComingsoon = () => {
        setComingsoon(false);
     }
    return (
        <section className="overflow-hidden position-relative h-100 EarnandLearn_PODBG" style={{ "backgroundSize": "cover", "backgroundPosition": "center" }}>
            <WindowTitle title={t('earnlearn.earn_learn')} />
            <div className="col-12 mt-2 mb-block d-none">
               <Link to="/pod">
               <svg enable-background="new 0 0 64 64" height="40" viewBox="0 0 64 64" width="40" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></svg>
                </Link>
            </div>
            <div className="d-flex h-100 text-center w-100">
                {/* <ul className="CollabMenu hangoutActions">
                    <li className={!audio ? "active" : "in-active"} title={t('title.toggle_audio_mute_unmute')} onClick={onAudioClick}>
                        <img className="inactived" src="/assets/media/images/collaborate/mic.svg" alt="" />
                        <img className="actived" src="/assets/media/images/collaborate/mic-inactive.svg" alt="" />
                    </li>

                    <li className={chatWindow ? "active" : "in-active"} title={t('title.toggle_chat_window')} onClick={toggleChat} >
                        {!chatWindow && newMsg ? <span className="newMessageIndicator"></span> : ""}
                        <img className="inactived" src="/assets/media/images/collaborate/chatting.svg" alt="" />
                        <img className="actived" src="/assets/media/images/collaborate/chatting-inactive.svg" alt="" />
                    </li>
                    <li className={pWindow ? "active" : "in-active"} title={t('title.toggle_participants')} onClick={toggleParticipants}>
                        <img className="inactived" src="/assets/media/images/collaborate/participants.svg" alt="" />
                        <img className="actived" src="/assets/media/images/collaborate/participants-inactive.svg" alt="" />
                    </li>
                </ul> */}
                <div className="LearnEarnMenuGrid">
                    <ul id="hexagonContainer">
                        <li className="hex-row" style={{ paddingLeft: "81%" }}>
                            <div className={showContacts ? "hexagon active" : "hexagon"}>
                                <div className="hex-inner c-pointer" onClick={handleShowContacts}>
                                    <div className="hex-img"> <a>{t('earnlearn.directory')}</a> </div>
                                </div>
                            </div>
                        </li>
                        <Link to="/pod">
                        <li className="hex-row mb-block d-none">
                            <img className="margin-tablet" src="/assets/media/images/sute-blue-logo.png" style={{width: "120px", marginTop: "0"}}/>
                        </li>
                        </Link>
                        <li className="hex-row loby-mb-mr">
                            <div className="hexagon">
                                <div className="hex-inner c-pointer">
                                    <div className="hex-img"> <a onClick={()=>openBulletinBoard()}>{t('earnlearn.bulletin1')}<br/>{t('earnlearn.bulletin2')}<br/>{t('earnlearn.bulletin3')}</a> </div>
                                </div>
                            </div>
                            <div className="hexagon">
                                <div className="hex-inner c-pointer">
                                    <div className="hex-img"> <a onClick={handleShowLearnMenu}>Events</a> </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="align-self-center m-auto position-relative w-100">
                    <div className="LearnEarnBrandLogo earn-learn-logo c-pointer" title={t('earnlearn.exit_room')} onClick={leave}>
                        <img src={user.orgData.LogoURL ? user.orgData.LogoURL : "/assets/media/images/sute-logo.png"} alt="Sute Logo" />
                    </div>
                    <div className="LearnEarnLogoClick c-pointer" title={t('earnlearn.exit_room')} onClick={leave}></div>
                    <div className="LearnEarnBoard" style={{ "backgroundImage": "url('https://miro.medium.com/max/1838/0*6wYLPCC0AbBWfx_m')" }}></div>
                </div>
                {/* <div className="d-none">
                    {remoteStreams && remoteStreams.map((rsObj: any) => {
                        return (<AudioPlayer
                            domId={`dom-${rsObj.stream.streamId}`}
                            key={`${rsObj.stream.streamId}`}
                            id={`${rsObj.stream.uid}`}
                            account={getUserDisplayName(rsObj.stream.streamId)}
                            streamID={rsObj.stream.streamId}
                            stream={rsObj.stream}
                            video={rsObj.video}
                            audio={rsObj.audio}
                            local={false}
                            autoplay={true}
                        />)
                    }
                    )}
                </div> */}
            </div>
            {showContacts ? <CompanyDirectory showContacts={showContacts} handleShowContacts={handleShowContacts} closeOthers={closeWindows} showLearnMenu={showLearnMenu} /> : ""}
            {/* {pWindow ? <Participants meetingId={meetingId} closeSideBar={closeSideBar}
                roomName={roomDtls.GroupName}
                userDisplayName={userDisplayName}
                remoteStreams={suteClient.remoteStreams} remoteReqs={remoteRequests} offlineUsers={[]}
                admitRequestedUsr={admitRequestedUsr} openProfile={openProfile}
                rejectRequestedUsr={rejectRequestedUsr} isCalendarMeeting={true} />  : ""}
            {chatWindow ? <MiniRoomChatWindow meetingId={meetingId} closeSideBar={props.closeSideBar} /> : ''}
            <WaitingRoomPopup show={showWaiting} close={() => { setShowWaiting(false) }} message={waitingMsg} /> */}
            {showLearnMenu ? <div className="SideNav hangSideNav text-left d-block position-fixed">
            <div className="sideNavHeader">
                        <div className="CloseNav" title="Close" onClick={handleShowLearnMenu}> <img src="assets/media/svg/x-close.svg" /> </div>
                    </div>
                <div className="sideNavBody">
                    <div className="row small">
                        <Link to="/learn" className="col-12 mb-3 text-center">
                            <div className="SidenavHex d-flex">
                                <span className="align-self-center w-100">
                                    <img src="./assets/media/images/hangouts/suteSelectionsIcon.svg" />
                                    <p className="pt-2">Sute<br />Selections</p>
                                </span>
                            </div>
                        </Link>
                        <div className="col-12 text-center">
                            <div className="SidenavHex d-flex">
                                <span className="align-self-center w-100">
                                    <img src="./assets/media/images/hangouts/podcastsIcon.svg" />
                                    <p className="pt-2">Podcasts</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
            <ShowOrgFiles showFiles={showBulletinBoard} type="bulletin" title="Company announcements"></ShowOrgFiles>
            <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
        </section>
    );
}

export default EarnLearn;