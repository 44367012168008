
const chatgptUrl = 'https://api.openai.com/v1/chat/completions';
const symblAppId = '6d57415171386b3230325a49475a5741774f765059494b456a43467a7a364250';
const symblAppSecret = '544a64756365576132444665664b78636e6c665f544a4e62507a505469595a61395a69375a4d36684d45654d7751515f356b414c534d714c4470365970437344';
const symblApiBasePath ="https://api.symbl.ai";

 export const openAICall =  async (chatMessages : any) =>{
    const cc = chatMessages;
    const API_KEY = "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"
    let apiMessages = chatMessages.map((messageObject : any)=>{
        let role="";
        if(messageObject.sender === "ChatGPT"){
            role = "assistant"
        }else{
            role = "user"
        }
        return (
            {role: role, content: messageObject.message}
        )
    });

    const systemMessage = {
        role: "system",
        content: "Explain all concept like i am 10 year old"
    }

    const apiRequestBody = {
        "model": "gpt-3.5-turbo",
        "messages": [
            systemMessage,
            ...apiMessages
        ]
    }

   return await fetch(chatgptUrl,{
        method: "POST",
        headers: {
            "Authorization": `Bearer ${API_KEY}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
    }).then((response)=>{     
        return response.json();
    })
    .then((data)=>{
        console.log(data.choices[0].message.content);
        return Promise.resolve(data.choices[0].message.content);
      
    })
}

export const SymblUICall =  async (token : any) =>{
    

   // return await fetch('https://api.symbl.ai/v1/conversations/experiences/insights/list?includeCallScore=tru',{
        return await fetch('https://api.symbl.ai/v1/conversations/experiences/insights/details/4518471026868224',{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
       // body: JSON.stringify(apiRequestBody)
    }).then((response)=>{     
        return response.json();
    })
    .then((data)=>{
        console.log(data);
        return Promise.resolve(data);
      
    })
}
export const SymblUI =  async (token : any) =>{
    

    return await fetch('https://insights-ui.symbl.ai/meeting-details/4518471026868224?o='+token+'&conversationType=general&includeCallScore=false',{
         method: "GET"         
     }).then((response)=>{     
         return response.json();
     })
     .then((data)=>{
         console.log(data.choices[0].message.content);
         return Promise.resolve(data.choices[0].message.content);
       
     })
 }
 
export const symblToken =  async () =>{
    try {
        const response = await fetch(
            `${symblApiBasePath}/oauth2/token:generate`,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    type: "application",
                    appId: symblAppId,
                    appSecret: symblAppSecret
                })
            }
        );
       // res.setHeader("Content-Type", "application/json");
        return await response.json();
    } catch (e) {
        console.error("Error while issuing Symbl Token.", e);        
    }
}