import React, { Fragment, useEffect, useRef, useState, DragEvent } from 'react';
import clsx from 'classnames';
import { Link } from 'react-router-dom';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
import { GetLabelList } from '../services/FileService';
import { useDispatch, useSelector } from "react-redux";
import { GetContacts } from '../services/UserActions';
import { GetFileListByEmpGuid } from '../services/FileService';
import { FilesTableBody } from '../interfaces/EmployeeData';
import { File } from 'buffer';
interface ContactOption {
    FirstName: string;
    EmpGuid: string;
}

const mediaFormats: { [key: string]: string } = {
    "": "",
    video: "mp4,avi,mov,mkv,wmv",
    audio: "mp3,wav,ogg,wma,flac",
    document: "pdf,docx,doc,pptx,ppt,xlsx,xls,txt",
    image: "jpg,jpeg,png,gif,bmp,tiff",
    archive: "zip,rar,7z,tar,gz",
};


export const Table = (props: any) => {
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const orgId = localStorage.getItem('OrgGuid') || '';
    const employeeId = localStorage.getItem('EmpId') || 0;

    const [pageSize, setPageSize] = useState(props.pageSize || 8)
    const [totalCount, setTotalCount] = useState(props.totalCount || 0)
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    const [lastPage, setLastPage] = useState(props.lastPage || 1)
    const [pagesRange, setPagesRange] = useState([1])
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const [minPages, setMinPages] = useState(1);
    const [options, setOptions] = useState<any[]>([]);
    const [contactSelected, setContactSelected] = useState({} as any);
    const typeaheadRef = useRef<Typeahead<ContactOption>>(null);
    const [load, setLoad] = useState(false)
    const [showSearchFilter, setShowSearchFilter] = useState(false);
    const [EmpLabelsoptions, setEmpLabelsOptions] = useState<any>([]);
    const [sltEmpLabel, setSltEmpLabel] = useState<any>([]);
    const [OwnerOptions, setOwnerOptions] = useState<any>([]);
    const [SltOwner, setSltOwner] = useState<any>();
    const [SearchWords, setSearchWords] = useState('');
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [size, setSize] = useState({ Symbol: ">", size: 0, Bytes: "MB" } as any)
    const [FolderId, setFolderId] = useState('0');
    const [FileType, setFileType] = useState("");
    const dispatch = useDispatch();
    const { user } = useSelector((state: any) => ({ user: state?.AuthReducer }));
    const isDND = props.DND ? true : false;
    useEffect(() => {
        GetLabelList(orgId, employeeId).then((resp: any) => {
            if (resp.status == "success") {
                setEmpLabelsOptions(resp?.result?.map((obj: any) => { return { value: obj.LabelId, label: obj.LabelName } }));
            }
        })
        dispatch(GetContacts(orgId, empGuid) as any);
        console.log("user.folderList", user.folderList);
    }, [])
    useEffect(() => {
        setFolderId(props.selectedFolderId)
    }, [props.selectedFolderId])
    useEffect(() => {
        if (user.contacts) {
            const cont = user.contacts.map((con: any) => {
                return { value: con.EmployeeId, label: `${con.FirstName} ${con.LastName}` };
            })
            setOwnerOptions([...cont]);
        }
    }, [user.contacts])
    useEffect(() => {
        setTotalCount((props.data || []).length > 0 ? (props.data[0].TotalCount || props.data[0].totalCount || 0) : 0)
        //setPageSize((props.data || []).length)
    }, [props.data])

    useEffect(() => {
        if (props.contactsList && props.contactsList.length > 0) {
            setOptions(props.contactsList);
        }
    }, [props.contactsList]);

    useEffect(() => {
        if (totalCount > 0) {
            let lastPageNo = Math.ceil((totalCount || 1) / pageSize);
            lastPageNo = lastPageNo > 1 ? lastPageNo : 1;
            setLastPage(lastPageNo);

            if (lastPageNo > 5) {
                setMinPages(5);
                setEndPage(5)
            } else {
                setMinPages(lastPageNo);
                setEndPage(lastPageNo)
            }
            // assign last page as current page when it exceeds the last page; 

            let temp: number[] = [];
            //const offset = Math.round(lastPageNo / 2);

            // add right side page numbers
            for (let i = 0; i < lastPageNo; i++) {
                temp.push(i + 1);
            }
            setPagesRange(temp)
        }
        else {
            setPagesRange([1])
        }
        console.log("tc:", totalCount)
    }, [totalCount])

    useEffect(() => {
        console.log("currentPage:" + currentPage + " startPage:" + startPage + " endPage:" + endPage);
        console.log("---------lastPage:" + lastPage + " minPages:" + minPages);
        if (props.onPageChange) {
            props.onPageChange(currentPage)
        }
    }, [currentPage])
    function onSearch(e: any) {
        if (props.onSearch) {
            setCurrentPage(1);
            setSearchWords(e.target.value);
            props.onSearch(e.target.value)
        }
    }

    function onUpload(e: any) {
        e.preventDefault();
        if (props.onUpload) {
            props.onUpload(e.currentTarget)
        }
    }

    function onCreate(e: any) {
        e.preventDefault();
        if (props.onCreate) {
            props.onCreate(e)
        }
    }
    const addMemberToList = (e: any) => {
        // if (option.length == 0) return;
        // const existing = props.contactsList.filter((m: any) => m.EmpGuid === option[0].EmpGuid);
        // if (existing.length > 0) {
        //     console.log('existing contact selected');
        // } else {
        //     onSearch('');
        // }
        onSearch(e);
    }

    const OnContactSelect = (obj: any) => {
        setContactSelected(obj);
        // props.getSharedByContactGuid(obj.EmployeeGuid);
    }
    const handleSearchRange = (e: any, type: string) => {
        if (type === "FromDate") {
            if(ToDate != ''){
                if(new Date(e.target.value) > new Date(ToDate)){
                    setToDate(e.target.value);
                }
            }
            setFromDate(e.target.value);
        }
        else {
            setToDate(e.target.value);
        }
    }

    const handleEmpLabel = (option: any) => {
        setSltEmpLabel(option)
    }
    const handleOwner = (option: any) => {
        setSltOwner(option);
    }

    const Advancedsearch = () => {
        const label = sltEmpLabel.map((obj: any) => { return obj.value });

        const OwnerId = (SltOwner != null) ? SltOwner.value : 0;
        var FileSizeQuery = "";
        if (size.size != "" || size.size != 0) {
            let sz = 0
            if (size.Bytes == "MB") {
                sz = 1048576 * size.size
            } else if (size.Bytes == "KB") {
                sz = 1024 * size.size
            } else {
                sz = size.size
            }
            FileSizeQuery = FileSizeQuery + size.Symbol + " " + sz
        }
        const serobj = {
            fileNameQuery: SearchWords, owner_id: OwnerId, fileSizeQuery: FileSizeQuery, fileLabel: label.toString(), FromDate: FromDate, ToDATE: ToDate, FileType: mediaFormats[FileType]
        }

        dispatch(GetFileListByEmpGuid({ PageNo: currentPage, PageSize: 8, Search: '' } as FilesTableBody, empGuid, parseInt(FolderId), JSON.stringify(serobj)) as any)
        setShowSearchFilter(false);
    }
    const handleCancel = () => {
        setSltEmpLabel([]);
        setSltOwner(null);
        setSearchWords('');
        setFromDate('');
        setToDate('');
        setSize({ Symbol: ">", size: 0, Bytes: "MB" });
        setFolderId(props.selectedFolderId);
        setFileType("");
        setShowSearchFilter(false);
        dispatch(GetFileListByEmpGuid({ PageNo: 1, PageSize: 8, Search: '' } as FilesTableBody, empGuid, parseInt(props.selectedFolderId), JSON.stringify({})) as any)
    }

    const [isOver, setIsOver] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
         console.log("DND",'OVER')
        setIsOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
         console.log("DND",'LEAVE')
       setIsOver(false);
    };
    const handleDragenter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
         console.log("DND",'ENTER')
       //setIsOver(false);
    };
    const handleDragexit = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
         console.log("DND",'EXIT')
      // setIsOver(false);
    };
    const handleDragend = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
         console.log("DND",'END')
      // setIsOver(false);
    };
    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);

        if (props.onUpload) {
            props.onUpload(event.dataTransfer)
        }
        // Fetch the files
        // const droppedFiles = Array.from(event.dataTransfer.files);
        // setFiles(droppedFiles);

        // // Use FileReader to read file content
        // droppedFiles.forEach((file) => {
        //     const reader = new FileReader();

        //     reader.onloadend = () => {
        //         console.log(reader.result);
        //         alert(reader.result)
        //     };

        //     reader.onerror = () => {
        //         console.error("There was an issue reading the file.");
        //     };

        //     reader.readAsDataURL(file);
        //     return reader;
        // });
    };

    return (
        <Fragment>
            <div className={props.rootUrl === "pod" ? 'col-9 m-auto' : 'col-9'} >
                <div className="cRoomHeader">
                    <div className="cRsuteLogo align-self-center">
                        <Link to={props.rootUrl || "/produce"}><img src="/assets/media/images/logo.png" /></Link>
                    </div>
                    <div className="cRTitle users-title">
                        <div className="row">
                            <div className="col">
                                <h5>{props.header}</h5>
                                <p>{props.subHeader}</p>
                            </div>
                            {props.id === 'shared_files' && props.sharedContact.empGuid ? <div className="col pl-0 addTeamEmails">
                                <div className="float-right mt-2 teamEmailHolder">
                                    {props.sharedContact.name} <img onClick={props.clearContactSearch} className="c-pointer" src="assets/media/svg/onboarding/x-close.svg" />
                                </div>
                            </div> : ''}
                            <div className="col text-right pt-1 filesTypehead">
                                {/* <Typeahead
                                    ref={typeaheadRef}
                                    onInputChange={(text, e) => addMemberToList(e)}
                                    id="async-example"
                                    labelKey="EmployeeName"
                                    minLength={1}
                                    options={options}
                                    placeholder="Search"
                                    renderMenuItemChildren={(option, { }) => (
                                        <div className="fs12 w-auto" title={option.EmployeeName}>
                                            <p className="ParticipantName" onClick={() => OnContactSelect(option)}><span className="PNel"><Highlighter search={""}>{option.EmployeeName}</Highlighter></span></p>
                                        </div>)
                                    }
                                /> */}
                                <input type="search" className={(props.header == 'Files') ? "SuteInputPrimary fs12 w-90" : "SuteInputPrimary fs12 w-auto"} onChange={e => onSearch(e)} onClick={() => { setShowSearchFilter(false) }} placeholder="Search" />
                                {(props.header == 'Files') ? <span className="search-parent" onClick={() => { setShowSearchFilter(!showSearchFilter) }}>

                                    <svg className="search-icon color-white" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 5C13.567 5 12 6.567 12 8.5C12 10.433 13.567 12 15.5 12C17.433 12 19 10.433 19 8.5C19 6.567 17.433 5 15.5 5ZM10 8.5C10 5.46243 12.4624 3 15.5 3C18.5376 3 21 5.46243 21 8.5C21 9.6575 20.6424 10.7315 20.0317 11.6175L22.7071 14.2929L21.2929 15.7071L18.6175 13.0317C17.7315 13.6424 16.6575 14 15.5 14C12.4624 14 10 11.5376 10 8.5ZM3 4H8V6H3V4ZM3 11H8V13H3V11ZM21 18V20H3V18H21Z"></path>
                                    </svg>
                                </span> : <></>}
                                {props.onUpload ? (
                                    <span className="ml-2 c-pointer importFileC" title="Upload file">
                                        {!load && <input type="file" multiple onChange={(e) => { onUpload(e); setLoad(true); setTimeout(() => setLoad(false), 2000) }} />}
                                        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path className="c-pointer" fillRule="evenodd" clipRule="evenodd" d="M7.486.807l3.603 3.326-.678.734L8 2.642V11H7V2.707L4.854 4.854l-.708-.708 3.34-3.34zM2 13V7H1v7h13V7h-1v6H2z" fill="#fff"></path></svg>
                                    </span>) : null}
                                {props.createUrl ? <a href={props.createUrl} title={props.createLabel}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path> </svg></a> : null}
                                {props.onCreate ? (<button onClick={(e) => onCreate(e)} title={props.createLabel} className="add-btn-transparent"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path> </svg></button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {(showSearchFilter) ? <div className="advanced-search-main">
                        <div className="row m-0">
                            <div className="col-3 pr-0">
                                <label className="text-light small m-0">File Name</label>
                            </div>
                            <div className="col-9">
                                <input type="text" value={SearchWords} onChange={(e) => { setSearchWords(e.target.value) }} className="advanced-search-textbox" />
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 m-auto">
                                <label className="text-light small m-0">Owner</label>
                            </div>
                            <div className="col-9">
                                <Select className="advanced-search-textbox"
                                    options={OwnerOptions}
                                    onChange={handleOwner}
                                    value={SltOwner}
                                    isClearable={true}
                                ></Select>
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 pr-0">
                                <label className="text-light small m-0">Label</label>
                            </div>
                            <div className="col-9">
                                <Select className="advanced-search-textbox"
                                    isMulti
                                    options={EmpLabelsoptions}
                                    onChange={handleEmpLabel}
                                    value={sltEmpLabel}
                                    isClearable={true}
                                ></Select>
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 pr-0">
                                <label className="text-light small m-0">Folder</label>
                            </div>
                            <div className="col-9">
                                <select className="advanced-search-textbox" value={FolderId} onChange={(e) => { setFolderId(e.target.value) }}>
                                    <option value="0">All</option>
                                    {user.folderList?.result.map((obj: any) => { return <option value={obj.FolderId}> {obj.FolderName}</option> })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 pr-0">
                                <label className="text-light small m-0">File type</label>
                            </div>
                            <div className="col-9">
                                <select className="advanced-search-textbox" value={FileType} onChange={(e) => { setFileType(e.target.value) }}>
                                    <option value="">select</option>
                                    <option value="video">video</option>
                                    <option value="audio">audio</option>
                                    <option value="document">document</option>
                                    <option value="image">image</option>
                                    <option value="archive">archive</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 ">
                                <label className="text-light small m-0">Date range</label>
                            </div>
                            <div className="col-9 row p-0 m-0">
                                <div className="col-6">
                                    <input type="date" value={FromDate} onChange={(e) => { handleSearchRange(e, "FromDate") }} className="advanced-search-textbox" />
                                </div>

                                <div className="col-6">
                                    <input type="date" min={FromDate}   value={ToDate} onChange={(e) => { handleSearchRange(e, "ToDate") }} className="advanced-search-textbox" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 m-0">
                            <div className="col-3 ">
                                <label className="text-light small m-0">Size</label>
                            </div>
                            <div className="col-9 row p-0 m-0">
                                <div className="col-6">
                                    <select value={size.Symbol} className="advanced-search-textbox" onChange={(e) => { setSize({ ...size, Symbol: e.target.value }) }}>
                                        <option value=">">greater than</option>
                                        <option value="<">less than</option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <input value={size.size} type="number" min="1" max="100" className="advanced-search-textbox" onChange={(e) => { setSize({ ...size, size: e.target.value }) }} />
                                </div>
                                <div className="col-3">
                                    <select value={size.Bytes} className="advanced-search-textbox" onChange={(e) => { setSize({ ...size, Bytes: e.target.value }) }}>
                                        <option value="MB">MB</option>
                                        <option value="KB">KB</option>
                                        <option value="Bytes">Bytes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-right advanced-search-btn">
                            <button type="button" className="btn btn-SeaGreen" onClick={()=>{setShowSearchFilter(false)}}>Close</button>
                            <button type="button" className="btn btn-SeaGreen" onClick={handleCancel}>Reset</button>
                            <button type="button" className="btn btn-SeaGreen" onClick={Advancedsearch}>Search</button>
                        </div>
                    </div> : <></>}
                </div>
                <div className={"card bgCadet mb-3 "+ (isOver && isDND ? "onDND" : "")} style={{ height: '73vh', overflow: 'auto' }} onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onDragEnd={handleDragend} onDragEnter={handleDragenter} onDragExit={handleDragexit} >
                    <div className={"card-body" } >                    
                         <div className="col-12 drag-drop-opacity">
                        <h2>Drop Files Here</h2>
                        </div> 
                        <div className="row">
                            <div className="col-12">
                                <table id={props.id} className="table border-secondary small filesTable mb-0">
                                    <thead>
                                        {props.headerRender()}
                                    </thead>
                                    <tbody>
                                        {(props.data || []).map((row: any, index: number) => { return props.rowRender(row, index) })}
                                        {(props.data || []).length === 0 ? <tr><td colSpan={6}><div className="text-center no-data-text-center">{props.noDataMsg}</div></td></tr> : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex text-center">
                    <ul className="fileTablePagination small">

                        <li>
                            <svg onClick={e => {
                                if (startPage > 1)
                                    setStartPage(startPage - 1);
                                if (minPages < endPage)
                                    setEndPage(endPage - 1);
                                if (currentPage > 1)
                                    setCurrentPage(currentPage - 1);
                            }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.707 8l3.147 3.146-.708.707L.793 7.5l4.353-4.354.708.708L2.707 7H14v1H2.707z" fill="#fff"></path>
                            </svg>
                        </li>

                        {(pagesRange || []).map((range: number, index: number) => {

                            return (range >= startPage && range <= endPage) ?
                                (<li onClick={e => setCurrentPage(range)} key={index} className={clsx('', { active: currentPage === range })}>
                                    {range}
                                </li>)
                                : ""
                        })}

                        <li>
                            <svg onClick={e => {
                                if (startPage <= lastPage - minPages)
                                    setStartPage(startPage + 1);
                                if (endPage < lastPage)
                                    setEndPage(endPage + 1);
                                if (currentPage < lastPage)
                                    setCurrentPage(currentPage + 1);
                            }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.854 3.146L14.207 7.5l-4.353 4.354-.708-.708L12.293 8H1V7h11.293L9.146 3.854l.708-.708z" fill="#fff"></path>
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
};

