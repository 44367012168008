import React from 'react';
import CheckinGrid from './CheckinGrid';
import CheckinsRightMenu from './CheckinsRightMenu';

function CheckinHistory(props: any) {   
    return (
		<section className="bgCadet position-relative d-flex pt-4 minh-100">
        <div className="col-sm-8 popup-settings ml-auto">
				<CheckinsRightMenu path={window.location.pathname} />
            <div className="row">
                <div className="col-sm-10 m-auto popup-settings">
               
                    <div className="card loginBox addTeamMemberBox mt-3 overflow-box">
                        <div className="card-body pl-4 pr-4">
                            <div className="row m-auto">
                                <div className="col-12 mb-3 text-white p-0">
                                    <h5>Check-In History</h5> 
								</div>
                                <CheckinGrid selectEmp={-1} showZero={'FALSE'}></CheckinGrid>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</section>
    );
}

export default CheckinHistory;