import authService from '../global/MsAuth';
import InteractiveSignInRequired from '../global/InteractiveSignInRequired';
import * as T from './Types';
import { UpdateStorage } from '../services/StorageService';
import { LoginWithTeams } from "../actions/AuthActions";

// const NETWORK_ERROR  = Symbol.for('network.error');
// const NETWORK_START  = Symbol.for('network.start');
// const NETWORK_STOP   = Symbol.for('network.stop');
// const SIGN_IN        = Symbol.for('network.auth-sign-in');
// const SIGN_OUT       = Symbol.for('network.auth-sign-out');

const startNetwork = () => ({ type: T.NETWORK_START });
const stopNetwork = () => ({ type: T.NETWORK_STOP });
const networkError = (error: any) => ({ type: T.NETWORK_ERROR, error });
const networkSignIn = (identity: any) => ({ type: T.SIGN_IN, identity });
const networkSignOut = () => ({ type: T.SIGN_OUT });

/**
 * Public action for initializing the network module.  Tries to acquire
 * an auth token silently, and swallows an interactive sign-in required.
 */
export function initializeNetwork() {
  return async (dispatch: any) => {
    try {
     
      
      dispatch(startNetwork());
      const identity = await authService.getIdentity();
      dispatch(networkSignIn(identity));
      if (identity.accessToken) {
       
        const userData = {
          username: localStorage.getItem("email"),
          accesstoken: localStorage.getItem("msAccessToken")
        };
        UpdateStorage({ isAuthenticated: true });
        dispatch(LoginWithTeams(userData, null));
        // dispatch({
        //   type: T.LOGIN_SUCCESS,
        //   payload: { TeamsLogin: true }
        // })
      }
      dispatch(stopNetwork());
    } catch (error) {
      dispatch(stopNetwork());
      if (!(error instanceof InteractiveSignInRequired)) {
        dispatch(networkError(error));
      }
    }
  };
}

/**
 * Action for initiating an interactive sign-in.
 */
export function signIn(hisotry: any) {
  return async (dispatch: any) => {
    try {
      dispatch(startNetwork());
      
      let profile = await await authService.signInWithPopup();
      //let profile = await await authService.signIn();
      //console.log(identity);
       profile = await authService.getIdentity();
      if (profile.accessToken) {
       
        const userData = {
          username: localStorage.getItem("email"),
          accesstoken: localStorage.getItem("msAccessToken")
        };
        UpdateStorage({ isAuthenticated: true });
        dispatch(LoginWithTeams(userData, hisotry));
        dispatch({
          type: T.LOGIN_SUCCESS,
          payload: { TeamsLogin: true }
        })
      }
     // dispatch(networkSignIn(profile));
     // hisotry.push("/");
      dispatch(stopNetwork());
    } catch (error) {
      dispatch(stopNetwork());
      dispatch(networkError(error));
    }
  };
}
/**
 * Action for initiating a sign-out.
 */
export function signOut() {
  return (dispatch: any) => {
    dispatch(startNetwork());
    authService.signOut();
    dispatch(stopNetwork());
    dispatch(networkSignOut());
  };
}