import { FilesTableBody } from '../interfaces/EmployeeData';
import React, { useEffect, useRef, useState } from 'react';
import { GetFileListByEmpGuid, GetFileSharedContacts } from "../services/FileService";
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "../components/Table";
import { Modal } from 'react-bootstrap';
import { StoreClass } from '../store/Store';
import clsx from 'classnames';
function headerRender() {
    return (
        <tr>
            <th>&nbsp;</th>
            <th>Type</th>
            <th>Name</th>
        </tr>);
}

function SuteFilesPopup(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const empId = localStorage.getItem('EmpGuid') || '';
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
    const dispatch = useDispatch();
    const modelRef = useRef<HTMLDivElement>();
    const [pageSize] = useState(props.pageSize || 10);
    const [totalCount, setTotalCount] = useState(props.totalCount || 0);
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    const [lastPage, setLastPage] = useState(props.lastPage || 1);
    const [pagesRange, setPagesRange] = useState([1]);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const [minPages, setMinPages] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    useEffect(() => {
        if(user.userFiles && user.userFiles.length === 0)
            dispatch(GetFileListByEmpGuid(filesTableParams, empId, 0, JSON.stringify({})) as any);
        else{
            setTotalCount((user.userFiles || []).length > 0 ? (user.userFiles[0].TotalCount || user.userFiles[0].totalCount || 0) : 0)
        }
    }, [])

    useEffect(()=>{
        setTotalCount((user.userFiles || []).length > 0 ? (user.userFiles[0].TotalCount || user.userFiles[0].totalCount || 0) : 0)
    }, [user.userFiles])
    useEffect(() => {
        dispatch(GetFileListByEmpGuid(filesTableParams, empId, 0, JSON.stringify({})) as any);
    }, [filesTableParams])
    
    const selectFile = (e: any, file: any)=>{
        console.log(e.target.checked);
        if(e.target.checked){
            setSelectedFiles([...selectedFiles, {fileId: file.FileGuid,
                url: file.FileURL,
                filename: file.FileName}]);
        }else{
            const rest = selectedFiles.filter((f:any)=>f.fileId !== file.FileGuid);
            setSelectedFiles([...rest]);
        }
    }
    const attachSelected = ()=>{
        props.onSubmit(selectedFiles);
        props.close();
    }
    function rowRender(row: any) {
        const ft = row.FileName.split('.').pop();
        return (<tr key={row.FileId}>
                    <td style={{ width: '10%' }}>
                      <div className="custom-control custom-checkbox pt-0 pb-0 minh-100">
                        <input type="checkbox" className="custom-control-input" name="suteFile" onClick={(e: any)=>selectFile(e, row)} />
                        <label className="custom-control-label"></label>
                      </div>
                    </td>
                    <td style={{ width: '10%' }}><img alt={ft} title={ft} src={`./assets/media/svg/file-icon/${ft}.svg`} width="20" /></td>
                    <td>{row.OriginalName}</td>
                  
        </tr>);
    }
    useEffect(() => {
        if (totalCount > 0) {
            let lastPageNo = Math.ceil((totalCount || 1) / pageSize);
            lastPageNo = lastPageNo > 1 ? lastPageNo : 1;
            setLastPage(lastPageNo);

            if (lastPageNo > 5) {
                setMinPages(5);
                setEndPage(5)
            } else {
                setMinPages(lastPageNo);
                setEndPage(lastPageNo)
            }
            // assign last page as current page when it exceeds the last page; 

            let temp: number[] = [];
            //const offset = Math.round(lastPageNo / 2);

            // add right side page numbers
            for (let i = 0; i < lastPageNo; i++) {
                temp.push(i + 1);
            }
            setPagesRange(temp)
        }
        else {
            setPagesRange([1])
        }
        console.log("tc:", totalCount)
    }, [totalCount])
    useEffect(() => {
        setFilesTableParams({ ...filesTableParams, PageNo: currentPage })
    }, [currentPage])
    return (
        <Modal ref={modelRef} show={props.show} onHide={props.close} className="text-light"  backdrop="static"  keyboard={false}>
            <Modal.Header className="border-0">
                <h5 className="modal-title text-light" id="AttchFilePopupLabel">Select Files</h5>
                <button type="button" className="close" onClick={props.close}>
                    <span className="text-light" aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body> 
                {
                    (user.userFiles || []).length > 0 ? <>
<table className="table border-secondary small filesTable mb-0">
                    <thead>
                        {headerRender()}
                    </thead>
                    <tbody>
                        {(user.userFiles || []).map((row: any) => { 
                            return rowRender(row) 
                        })}
                    </tbody>
                </table>
                <div className="d-flex text-center">
                    <ul className="fileTablePagination small">

                        <li>
                            <svg onClick={e => {
                                if (startPage > 1)
                                    setStartPage(startPage - 1);
                                if (minPages < endPage)
                                    setEndPage(endPage - 1);
                                if (currentPage > 1)
                                    setCurrentPage(currentPage - 1);
                            }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.707 8l3.147 3.146-.708.707L.793 7.5l4.353-4.354.708.708L2.707 7H14v1H2.707z" fill="#fff"></path>
                            </svg>
                        </li>

                        {(pagesRange || []).map((range: number, index: number) => {

                            return (range >= startPage && range <= endPage) ?
                                (<li onClick={e => setCurrentPage(range)} key={index} className={clsx('', { active: currentPage === range })}>
                                    {range}
                                </li>)
                                : ""
                        })}

                        <li>
                            <svg onClick={e => {
                                if (startPage <= lastPage - minPages)
                                    setStartPage(startPage + 1);
                                if (endPage < lastPage)
                                    setEndPage(endPage + 1);
                                if (currentPage < lastPage)
                                    setCurrentPage(currentPage + 1);
                            }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.854 3.146L14.207 7.5l-4.353 4.354-.708-.708L12.293 8H1V7h11.293L9.146 3.854l.708-.708z" fill="#fff"></path>
                            </svg>
                        </li>
                    </ul>
                </div>
                    </> : <h3>No files found</h3>
                }
                
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button type="button" className="btn btn-secondary btn-sm" onClick={props.close}>Cancel</button>
              <button type="button" className="btn btn-primary btn-sm" 
               onClick={attachSelected} disabled={selectedFiles?.length === 0 ? true : false } >Selected</button>
            </Modal.Footer>
        </Modal>
    );
}

export default SuteFilesPopup;