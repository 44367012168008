import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { DeleteEmployeeByEmployeeGuid, GetContacts } from '../../services/UserActions';
import { GetPreferredContacts, AddPreferredContact, removeSuccessStatus, removePreferredContact, updateRtmStatus, getUserStatusess } from '../../services/ContactsService';
import { chatClient } from "../../services/chat-api";
import ProfileView from '../myoffice/ProfileView';
import PreCallPopup from '../myoffice/PreCallPopup';
import { globalStore } from '../../store/global_store';
import InviteTeam from "../../components/InviteTeam";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { StoreClass } from "../../store/Store";
import { t } from '../../i18n';
import UserChatComponent from "../../components/UserChatComponent";
import TimeSlotPopup from "../myoffice/TimeSlotPopup";
import * as T from '../../actions/Types';

var deleteEmployeeGuid: string;


function CompanyDirectory(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    let [selectUser, setSelectedUser] = useState({ EmployeeId: 0, FirstName: '', LastName: '', EmpGuid: '', OfficeEmail: '', Mobile: '', status: '' });
    const [callerDtl, setCallerDtl] = useState<any>({});
    const empId = localStorage.getItem('EmpGuid') || '';
    const [openChat, setOpenChat] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [openCall, setOpenCall] = useState(false);
    const [showinviteTeam, setShowInviteTeam] = useState(false);
    const [showConfirmEmployeeDelete, setShowConfirmEmployeeDelete] = useState(false);
    const orgId = localStorage.getItem('OrgGuid') || '';
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredContacts, setFilteredContacts] = useState([] as any);
    const dispatch = useDispatch();
    const tmuserInfo = useRef<any>(0);

    useEffect(() => {
        console.log('------------------------USERS ----------------', user.contacts);      
        // getContacts();
        // tmuserInfo.current = setInterval(getContacts, 5000);
       // return () => clearInterval(tmuserInfo.current);
        // }
    }, []);

    useEffect(()=>{
        if(user.contacts){
            if(searchQuery?.length>0){
                const filtered = user.contacts?.filter((prefcontact: any) =>
                (prefcontact.FirstName + ' ' + prefcontact.LastName).toLowerCase().includes(searchQuery))
                setFilteredContacts(filtered as any); 
            }
            else{
                setFilteredContacts(user.contacts)
            }
        }    
    },[user.contacts,searchQuery])

    const getContacts = () => {
        dispatch(GetContacts(orgId, empId) as any)
    }
    useEffect(() => {
        //debugger;
        if (props.showLearnMenu) {
            setOpenProfile(false);
            setOpenChat(false);
        }
    setFilteredContacts(user.contacts);
    }, [props.showLearnMenu])
    useEffect(() => {
        if (user.success &&
            (user.success === 'AddPreferredContact' || user.success === 'RemovedPreferredContact' || user.success === 'Updated successfully.')) {
            if (user.success === 'RemovedPreferredContact') {
                globalStore.showToast({
                    type: 'Message',
                    title: "Removed Favorite",
                    message: "Favorite unmarked successfully"
                });
            } else {
                globalStore.showToast({
                    type: 'Message',
                    title: "Add Favorite",
                    message: "Contact marked as favorite successfully"
                });
            }
            if (user.success === 'Updated successfully.') {
                globalStore.showToast({
                    type: 'Message',
                    title: "Removed Employee",
                    message: "Employee Deleted successfully"
                });
            }
            dispatch(removeSuccessStatus() as any);
            dispatch(GetContacts(orgId, empId) as any);
            dispatch(GetPreferredContacts(empId) as any);
        }
        // console.log("getting data 1234",user.aus)

    }, [user.success, user.aus]);

    const removePreferedContact = async (contactId: string) => {
        const params = new FormData();
        params.append("empGuid", empId);
        params.append("prefEmpGuid", contactId);
        dispatch(removePreferredContact(params, empId) as any);
    }

    // useEffect(() => {
        // if (user.contacts && user.contacts.length > 0) {
        //     let uids: string[] = [];
        //     user.contacts.map((u: any) => {
        //         uids.push(u.EmpGuid);
        //     });
        //     chatClient.subscribeOnlineStatus(uids);
        //    // dispatch(getUserStatusess(uids.join(",")) as any);
        // }
    // }, [user.contacts]);

    // chatClient.onStatusUpdate = (msg: any) => {
    //     if (msg && Object.keys(msg).length > 0) {
    //         let statusAr = { ...msg };
    //         dispatch(updateRtmStatus(statusAr) as any);
    //     }
    // };

    const getUserProfile = (eid: any) => {
        getUserDetails(eid);
        setOpenProfile(true);
        props.closeOthers();
    }
    const startChat = (eid: any) => {
        const selectedUserAr = user.contacts?.filter((account: any) => { if (account.EmpGuid === eid) return account; })
        if (selectedUserAr && selectedUserAr?.length > 0) {
            setSelectedUser(selectedUserAr[0]);
            selectUser = selectedUserAr[0];
        }
        let empData = user.contacts?.filter(emp => {
            return emp.EmpGuid == empId
        })
        // if (selectUser.EmployeeId > 0 && empData && empData[0].EmployeeId) {
        //     setMeetingId(empData[0].EmployeeId > selectUser.EmployeeId ? selectUser.EmpGuid.split('-')[0] + empData[0].EmpGuid.split('-')[0] : empData[0].EmpGuid.split('-')[0] + selectUser.EmpGuid.split('-')[0]);
        // }
        // else {
        //     setMeetingId('')
        // }
        setOpenProfile(false);
        setOpenChat(true);
        props.closeOthers();
        if (eid === selectUser.EmpGuid) return;
        if (!user.contacts) return;
        console.log(" cons 4", user.contacts);

    }
    const getUserDetails = (eid: any) => {
        setOpenProfile(false);
        if (eid === selectUser.EmpGuid) return;
        if (!user.contacts) return;
        //console.log(" cons 5", props.user.contacts);
        const selectedUserAr = user.contacts.filter((account: any) => { if (account.EmpGuid === eid) return account; })
        setSelectedUser(selectedUserAr[0]);
    }

    const handleShowEmployeeDelete = (eid: any) => {
        deleteEmployeeGuid = eid;
        setShowConfirmEmployeeDelete(!showConfirmEmployeeDelete);
    }
    const toggleSchedule = () => {
        setShowSchedule(!showSchedule)
    }
    const closeChat = () => {
        setOpenChat(false);
    }
    const closeProfile = () => {
        setOpenProfile(false);
    }
    const makeFavorite = async (contactId: string) => {
        const params = new FormData();
        params.append("empGuid", empId);
        params.append("prefEmpGuid", contactId);
        dispatch(AddPreferredContact(params) as any);
    }
    const sendCallReq = (type: string, emid: string) => {
        if (openCall) {
            alert("Are you want to end the call?");
            return false;
        }
        console.log('#OPENCALL sendCallReq',user.openCall,user.selectedUser); 
       /// getUserDetails(emid);
        // SaveMyActivity({ Type: "CALL", FromId: empId, ToId: emid, OrgGuid: orgId, Status: "CALLING" }).then((res) => {
        //    tmCallInfo.current = setInterval(GetCallDetails, 5000);
        // });
        dispatch({
            type: T.SELECTED_USER,
            payload: emid
        })

        dispatch({
            type: T.OPEN_CALL,
            payload: true
        })

      //  setOpenCall(true);
        setOpenProfile(false);
        setOpenChat(false);
    }
   
    const cancelCallLocal = () => {
        setOpenCall(false);
        dispatch({
            type: T.SELECTED_USER,
            payload: undefined,
        })

        dispatch({
            type: T.OPEN_CALL,
            payload: false
        })
        console.log('#PRE cancelCallLocal ',openCall);
        if (user && user.Socket && user.Socket.connected){               
              user.Socket.removeListener("userfromCallStatus");
           }
    }
    const toggleInviteTeam = () => {
        console.log('showinviteTeam>', showinviteTeam);
        setShowInviteTeam(!showinviteTeam);
    }

    const deleteEmployee = () => {
        dispatch(DeleteEmployeeByEmployeeGuid(deleteEmployeeGuid) as any);
        handleShowEmployeeDelete(0);
    }
    const staticStatus = [
        { id: "1", color: "green", title: "Available" },
        { id: "2", color: "orange", title: "Away" },
        // { id: "3", color: "#04f1b2", title: "In Office" },
        { id: "4", color: "red", title: "Busy" }
    ]
    const getStatus = (isOnline: any, status: any) => {
        if (isOnline) {
            status = status.length > 0 ? status : 'available';
            const sts = staticStatus.filter((s: any) => s.title.toLowerCase() === status.toLowerCase());

            if (sts.length > 0)
                return sts[0].color;
        }
        return 'gray';

    }

    const getStatusBool = (emId: any) => {
        if (user.aus[emId] && user.aus[emId] === 'ONLINE') {
            var st = user.sus[emId] || 'available';
            const sts = staticStatus.filter((s: any) => s.title === st);
            if (sts.length > 0)
                return true;
        }
        return false;
    }

    const handleSearch = (e: any) => {
         setSearchQuery(e.target.value);       
    };
    
     return (<>
        <div className={props.showContacts ? "SideNav SideNavLeft" : "SideNav SideNavLeft d-none"} id="ParticipantsSideNav">

            <div className="sideNavHeader">
                <div className="CloseNav" onClick={props.handleShowContacts}>
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('title.close')} /> </div>
            </div>
            <div className="sideNavBody" id="ParticipantsList">
                <div className="row mt-4 small">
                    <div title="Invite Team" className="col-12 mb-2 text-light font-weight-bold">{t('contacts.contacts')}
                        {/* <svg onClick={toggleInviteTeam} className="float-right c-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#fff" /></svg> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 small ParticipantsSearch">
                        <input className="SuteInputPrimary"
                            type="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={e => handleSearch(e)}
                        /> </div>
                </div>
                <div className="row mt-3 small" style={{ "overflow": "auto", height: "80%", alignContent: "flex-start" }}>
                     {filteredContacts && filteredContacts.map(
                        function (event: any) {
                            return (
                                <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary" title={(event.IsOnline) ? (event.status.length > 0 ? event.status : 'Available') : 'Offline'} key={event.EmpGuid}>
                                    <div className="float-left">
                                        <svg className={"ParticipantImage c-pointer " + (event.IsOnline ? (event.status.length > 0 ? event.status.toLowerCase() : 'available') : 'Offline')} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={() => getUserProfile(event.EmpGuid)}>
                                            <defs>
                                                <pattern id={event.EmpGuid} patternUnits="userSpaceOnUse" width="100" height="100">
                                                    <image xlinkHref={event.ProfileImageURL ? event.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />
                                                </pattern>
                                            </defs>
                                            <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${event.EmpGuid})`}></polygon>
                                        </svg>
                                    </div>
                                    <div className="float-left">
                                        <p className="ParticipantName"><span className="hexUserStatus mr-1" style={{ backgroundColor: getStatus(event.IsOnline, event.status) }}></span>{event?.FirstName + ' ' + event?.LastName}</p>
                                        {empId === event.EmpGuid ? "" : <p className="ParticipantDesignation">
                                            {/* {getStatus(event.IsOnline, event.status) == 'gray' ? <img className="c-pointer opacity05" title="Offline" alt="Chat" src="/assets/media/svg/profile/message.svg" /> : <img className="c-pointer" title={t('chat.chat')} alt="Chat" onClick={() => startChat(event.EmpGuid)} src="/assets/media/svg/profile/message.svg" />} */}
                                            <img className="c-pointer" title={t('chat.chat')} alt="Chat" onClick={() => startChat(event.EmpGuid)} src="/assets/media/svg/profile/message.svg" />
                                            {getStatus(event.IsOnline, event.status) == 'gray' ? <img className="c-pointer opacity05" title="Offline" alt="Direct Call" src="/assets/media/svg/profile/call.svg" /> : <img className="c-pointer" title={t('chat.direct_call')} alt="Direct Call" onClick={() => sendCallReq('audio', event.EmpGuid)} src="/assets/media/svg/profile/call.svg" />}
                                            {/* getStatusBool(event.EmpGuid) ? <img className="c-pointer" title={t('title.video_call')} alt="Video Call" onClick={() => sendCallReq('video', event.EmpGuid)} src="/assets/media/svg/profile/video-call.svg" /> : <img className="c-pointer opacity05" title="Offline" alt="Video Call" src="/assets/media/svg/profile/video-call.svg" /> */}
                                            {/* <img className="c-pointer" title={t('title.remove_contact')} alt="Remove Contact" onClick={() => handleShowEmployeeDelete(event.EmpGuid)} src="/assets/media/svg/profile/remove-member.svg" /> */}
                                        </p>}
                                        {empId === event.EmpGuid ? "" :
                                            <div className={`favHeart c-pointer ${event.IsPreferredContact ? 'active' : ''}`} title="Move to favorite Contacts" onClick={() => {event.IsPreferredContact ? removePreferedContact(event.EmpGuid) : makeFavorite(event.EmpGuid)}}>
                                                <svg className="nonActive" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 13.5l-.354.354a.5.5 0 00.708 0L7.5 13.5zM1.536 7.536l-.354.353.354-.353zm5-5l-.354.353.354-.353zM7.5 3.5l-.354.354a.5.5 0 00.708 0L7.5 3.5zm.964-.964l-.353-.354.353.354zm-.61 10.61L1.889 7.182l-.707.707 5.964 5.965.708-.708zm5.257-5.964l-5.965 5.964.708.708 5.964-5.965-.707-.707zM6.182 2.889l.964.965.708-.708-.965-.964-.707.707zm1.672.965l.964-.965-.707-.707-.965.964.708.708zM10.964 1c-1.07 0-2.096.425-2.853 1.182l.707.707A3.037 3.037 0 0110.964 2V1zM14 5.036c0 .805-.32 1.577-.89 2.146l.708.707A4.036 4.036 0 0015 5.036h-1zm1 0A4.036 4.036 0 0010.964 1v1A3.036 3.036 0 0114 5.036h1zM4.036 2c.805 0 1.577.32 2.146.89l.707-.708A4.036 4.036 0 004.036 1v1zM1 5.036A3.036 3.036 0 014.036 2V1A4.036 4.036 0 000 5.036h1zm.89 2.146A3.035 3.035 0 011 5.036H0c0 1.07.425 2.096 1.182 2.853l.707-.707z" fill="#fff"></path></svg>
                                                <svg className="Active" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.036 1a4.036 4.036 0 00-2.854 6.89l5.964 5.964a.5.5 0 00.708 0l5.964-5.965a4.036 4.036 0 00-5.707-5.707l-.611.61-.61-.61A4.036 4.036 0 004.035 1z" fill="#fff"></path></svg>
                                            </div>}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
        {openChat ? <UserChatComponent openChat={openChat} closeChat={closeChat}
            selEmpName={selectUser.FirstName + " " + selectUser.LastName}
            selEmpGuid={selectUser.EmpGuid} /> : undefined}
        <ConfirmPopup show={showConfirmEmployeeDelete} close={handleShowEmployeeDelete} okHandle={deleteEmployee} message="Do you want to delete this employee?" />
        {openProfile ? <ProfileView audioCall={() => sendCallReq('audio', selectUser.EmpGuid || "")}
            videoCall={() => sendCallReq('video', selectUser.EmpGuid || "")}
            // userStatus={user.aus[selectUser.EmpGuid] && user.aus[selectUser.EmpGuid] === 'ONLINE' ? user.sus[selectUser.EmpGuid] || 'Online' : 'Offline'}          
            openChat={() => { startChat(selectUser.EmpGuid || "") }}
            openProfile={openProfile} closeProfile={closeProfile} user={selectUser} toggleSchedule={toggleSchedule} /> : ''}
        
        {/* <PreCallPopup cancelCall={cancelCallLocal} {...selectUser} type={callerDtl.type}
            msg={callerDtl.msg} openCall={openCall} selectedUser={selectUser} /> */}
        {showSchedule ? <TimeSlotPopup EmpOfficeEmail={selectUser.OfficeEmail} EmpGuid={selectUser.EmpGuid} show={showSchedule} close={toggleSchedule} /> : ''}
        {showinviteTeam ? <InviteTeam close={toggleInviteTeam} /> : ''}
    </>
    );
}


export default CompanyDirectory;